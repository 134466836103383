.homePageFutures {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 1550px;
    margin: auto;
}

.homePageFutures .leftSideModule {
    width: 100% !important;
}

.homePageFutures .rightSideModule {
    width: 100% !important;
}

/* media queries */

@media screen and (max-width: 950px) {
    .homePageFutures {
        flex-direction: column;
        align-items: center;
        /* padding: 0 20px 30px; */
    }

    .homePageFutures .leftSideModule {
        padding-top: 70px;
    }

    .homePageFutures .rightSideModule {
        padding-top: 50px;
    }
}