.ticker-title {
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.ticker-title span {
    position: absolute !important;
    right: 0;
    top: 0;
}

.socialMedia__block-list {
    flex-direction: row !important;
    align-items: center !important;
}

.socialMedia__block-list span {
    width: 80%;
}

.socialMedia__block img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.socialMedia__block-input {
    width: 80% !important;
    margin-right: 20px;
}

.report__block-on {
    transition: 1s !important;
    opacity: 1 !important;
    height: 100% !important;
    margin-bottom: 10px;
}

.report__block-off {
    opacity: 0;
    height: 0;
    padding: 0 !important;
    margin: 0;
    border: none !important;
    position: absolute;
    top: 0;
    width: 0;
}

.submitReport__button {
    margin-left: 0;
}

.afterSubmit-message {
    color: #00ad7c;
    font-weight: 600;
}

.banned-message {
    margin-bottom: 10px;
}

.warningLinkMessage {
    padding-bottom: 20px;
    color: red;
}

.warning-reportInProgress {
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    position: fixed;
    right: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 200px;
    height: 100px;
    border: 1px solid hsla(0, 0%, 0%, 0.1);
    border-radius: 4px;
    box-shadow: 2px 2px 2px hsla(0, 0%, 0%, 0.1);
}
