.new-slider {
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  margin: 0 auto 35px;
  width: 100%;
  padding: 0 25px;
  max-width: 1150px;
}

.new-slider ul {
  display: inline-block;
  white-space: nowrap;
  padding: 0;
  transition: all 0.6s ease 0s;
  margin: 0;
  min-width: 100%;
}

.new-slider li {
  display: inline-block;
}

.new-slider_icon {
  cursor: pointer;
  font-size: 0;
  position: absolute;
  height: 40px;
  width: 40px;
  z-index: 10;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #e5e5e5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
}

.new-slider_icon:active {
  border: 2px solid #24adf2;
}

.new-slider_title {
  margin: 0 0 10px 10px;
  font-size: 21px;
  color: #7e7e7e;
}

.new-slider_page {
  overflow: hidden;
  width: 100%;
  display: inline-block;
  position: relative;
}

.new-slider_item {
  flex: 1;
  padding: 10px;
  display: inline-block;
  position: relative;
  user-select: none;
  width: 100%;
}

.new-slider_next,
.new-slider_prev {
  position: absolute;
  top: 50%;
}

.new-slider_next {
  right: 20px;
  transform: translate(10px, -50%);
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 492.004 492.004' style='enable-background:new 0 0 492.004 492.004; fill: rgb(126, 126, 126)' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12 c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028 c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265 c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E");
}

.new-slider_prev {
  left: 20px;
  transform: translate(-10px, -50%);
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 492 492' style='enable-background:new 0 0 492 492; fill: rgb(126,126,126)' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12 C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084 c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864 l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E");
}

  .new-slider_picture {
    min-height: 240px;
  }

.new-slider_picture {
  width: 100%;
  border-radius: 2px;
  display: inline-block;
  background-repeat: no-repeat;
  margin-top: -10px;
  max-height: 170px;
}

.new-slider_content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fff;
  font-size: 16px;
  line-height: 30px;
}

.new-slider_content_description span {
  overflow: hidden;
  max-height: 150px;
}

.new-slider_content_creator {
  color: #795C3E;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 13px;
  padding-bottom: 10px;
}

.new-slider_content_title {
  color: #515151;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
}

.new-slider_content_description {
  color: #515151;
  text-decoration: none;
}

.new-slider_progress {
  background-color: #ddd;
  border-radius: 20px;
  margin-top: 25px;
  height: 7px;
}

.new-slider_progress_bar {
  height: 100%;
  background-color: #0162d0;
  border-radius: 20px;
}

.new-slider_status {
  font-size: 14px;
  color: black;
}

.new-slider_status_raised {
  font-weight: 900;
  color: black;
}