.lost-menu_visible {
    height: 70px;
    transition-duration: .2s;
    padding-bottom: 2px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.lost-menu_unvisible {
    height: 0;
    display: flex;
    transition-duration: .5s;
    flex-wrap: wrap;
    flex-direction: row;
}

.lost-title_visible {
    cursor: pointer;
    width: 100%;
    display: block;
    transition-duration: 1.5s;
}

.lost-title_unvisible {
    width: 100%;
    display: none;
    transition-duration: 1.5s;
}

.lost-title_visible:hover {
    background-color: rgba(35, 101, 201, 0.59);
}

.cursor-pointer {
    cursor: pointer;
}

.twostep_faq-top {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
}

.two_step_faq__container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.two_step_faq__block {
    width: 85%;
    background-color: #ffffff;
    margin: 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

}

.two_step_faq__block h3 {
    text-align: center;
}

.two_step_faq__block ol {
    margin-left: -20px;
}

.two_step_faq__block ol :first-child {
    margin-top: 20px;
}

.two_step_faq__block p :nth-child(2) {
    margin-top: 20px;
}

.two_step_faq__table {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    border-radius: 50px
}

.two_step_faq__row {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid lightgray;
    justify-content: center;
}

.two_step_faq__table .two_step_faq__row:nth-child(1) {
    border-top: 1px solid lightgray;
    background-color: #e5e8ea;
    /*border-radius: 50px;*/
}

/*.two_step_faq__table .two_step_faq__row:last-child {*/
/*border-radius: 50px;*/
/*background-color: red;*/
/*}*/


.two_step_faq__cell {
    width: 50%;
    padding: 10px;
    word-wrap: break-word;
    min-width: 260px;
    border-right: 1px solid lightgray;
}

.access_image {
    height: 80px;
    width: 80px;
    margin: 40px auto 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;

}

.two_step_faq__cell-title {
    width: 50%;
    padding: 10px;
    display: flex;
    min-width: 260px;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
}

.two-step_faq-title {
    margin: 15px 0 35px 0;
    color: #0162D0;
    font-size: 23pt;
    align-content: center;
    text-align: center;
}

.faq-table-right_part {
    padding-left: 45px;
}