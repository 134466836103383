.walletsPage .PortfolioPageWrap {
    height: 100%;
    min-height: 840px;
}

.wallets3Page{
    min-height: calc(100% - 201px);
    display: flex;
    flex-direction: column;
    position: relative;
}


.wallets3Page .PortfolioPageWrap {
    padding-left: 0;
}

.walletsPage .PortfolioPageWrap > .content {
    height: 100% !important;
    display: flex;
    flex-direction: column;
}

.walletsPage .PortfolioSummaryWrap {
    padding-bottom: 0;
}

.walletsPage .dashWrapperB {
    margin-bottom: 0;
}

.walletsPage .cBody {
    flex: auto;
}

.walletsPage .cBody {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 815px;
}

.walletsPage .cBody .portfolio-body .paginate {
    position: static;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto 30px 15px 0;
}

.wallets3Page .dashWrapperB > .rightWrapperSide > div,
.wallets3Page .dashWrapperB > div:not(.advertByCollectibles),
.walletsPage .dashWrapperB > div {
    padding-right: 0 !important;
}

.walletsPage .WithdrawalDetailsWrapper .amountToSend .amountInput,
.walletsPage .WithdrawalDetailsWrapper .amountToSend .amountSelect {
    height: 48px;
}

.wallets3Page .auctionTable td {
    word-break: break-word;
}

.walletsPage .confirmDepositBttn {
    margin-top: 0;
}

@media screen and (max-width: 1200px){
    .wallets3Page {
        height: auto;
    }
}
