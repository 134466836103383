.commentsBlock {
    width: 100%;
    max-width: 800px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto auto 20px;
}

.commentsBlockHeader {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.commentsBlockHeader__avatar,
.mainCommentHeader__avatar {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    overflow: hidden;
}

.commentsBlockHeader__avatar img,
.mainCommentHeader__avatar img {
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
}

.commentsBlockHeader__avatar div,
.mainCommentHeader__avatar div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #0162d0;
    color: #fff;
}

.commentsBlockHeader__input {
    margin: 0 15px 0;
    flex-grow: 1;
}

.commented-nonactive-p {
    margin-left: 20px;
}

.commentsBlockHeader__input input {
    border-radius: 5px;
    border: 1px solid #0162d0;
    outline: none;
    padding: 5px 19px;
    font-size: 14px;
    color: #444444;
    width: 100%;
}

.commentsBlockHeader__button button {
    border-radius: 5px;
    border: 1px solid #0162d0;
    font-size: 14px;
    color: #fff;
    background-color: #0162d0;
    padding: 5px 10px;
    cursor: pointer;
    outline: none !important;
    opacity: 1;
    transition: opacity .4s ease;
}

.commentsBlockHeader__button button:disabled {
    cursor: not-allowed;
    opacity: .5;
}

.commentsList {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.mainComment {
    margin: 10px 0;
}

.mainCommentHeader {
    display: flex;
    align-items: center;
}

.mainCommentHeader__avatar {
    margin-right: 15px;
}

.mainCommentHeaderInfo {
    display: flex;
    flex-direction: column;
}

.mainCommentHeaderInfo__name {
    font-size: 14px;
    color: #0162d0;
    line-height: normal;
    font-weight: 600;
    margin-bottom: 3px;
}

.mainCommentHeaderInfo__date {
    font-size: 12px;
    color: #444444;
    line-height: normal;
}

.mainCommentBody {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mainCommentFooter {
    display: flex;
    align-items: center;
}

.mainCommentFooter__reply {
    margin-right: auto;
    min-width: 70px;
}

.mainCommentFooter__downvote {
    margin-left: 5px;
}

.mainCommentFooter__upvote {
    margin-right: 5px;
}

.mainCommentFooter__reply,
.mainCommentFooter__upvote,
.mainCommentFooter__downvote {
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    outline: none !important;
}

.mainCommentFooter__reply img,
.mainCommentFooter__upvote img,
.mainCommentFooter__downvote img {
    max-width: 17px;
    margin-right: 5px;
}

.mainCommentFooter__downvote img {
    transform: rotate(180deg);
}

.mainCommentFooter__reply span,
.mainCommentFooter__upvote span,
.mainCommentFooter__downvote span {
    font-size: 14px;
    color: #444444;
    transition: color .4s ease;
}

.mainCommentFooter__reply:hover span,
.mainCommentFooter__upvote:hover span,
.mainCommentFooter__downvote:hover span {
    color: #0162d0;
}

.mainCommentFooter__voteQuantity,
.mainCommentFooter__downvote--selected span,
.mainCommentFooter__upvote--selected span {
    color: #0162d0;
}

.commentContainer > .childComments {
    position: relative;
    width: calc(100% - 15px);
    margin-left: auto;
}

.childComments__mapElement {
    position: absolute;
    left: -15px;
    top: 0;
    padding-left: 7px;
    border-left: 1px dashed rgba(0, 0, 0, .2);
    height: 100%;
    transition: border-color .3s linear;
}

.childComments__mapElement:hover {
    border-color: #0162d0;
}

.childComments__viewButton {
    position: relative;
    padding: 0 0 0 25px;
    margin: 0;
    background-color: transparent;
    border: none;
    outline: none!important;
    cursor: pointer;
    color: #0162d0;
    font-size: 13px;
    width: auto;
}

.childComments__viewButton::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '+';
    width: 100%;
    height: 100%;
    max-width: 15px;
    max-height: 15px;
    background-color: #0162d0;
    color: #ffffff;
    border-radius: 5px;
    line-height: 12px;
}

.commentContainer > .childComments--replyBtnIsActive {
    margin-left: 0;
}

.childComments--replyBtnIsActive > .childComments__mapElement {
    display: none;
}

#replyOfComment .commentsBlockHeader__avatar {
    display: none;
}

#replyOfComment .commentsBlockHeader__input {
    margin: 0 15px 0 0;
}

.report-comment__button {
    margin-right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
}

.report-comment__button:focus {
    outline: none;
}

.report-comment__button img {
    margin-right: 5px;
    margin-bottom: 4px;
}

.report-comment__button span {
    color: #444444;
    transition: color .4s ease;
    font-size: 14px;
}

.report-comment__button span:hover{
    color: #0162d0
}