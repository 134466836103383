.myTicketsOrderForm {
    display: flex;
    flex-direction: column;
    height: 600px;
    padding-bottom: 25px;
    margin-right: 20px;
}

.myTicketsOrderSettings,
.myTicketsFinalizeOrder {
    padding: 0 25px;
}

.myTicketsFinalizeOrder {
    margin-top: auto;
}

.myTicketsOrderType,
.myTicketsOrderSettings,
.myTicketsFinalizeOrder__total {
    margin-bottom: 20px;
}

.myTicketsOrderType {
    position: relative;
}

.myTicketsOrderType::before,
.myTicketsOrderType::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 10px;
    border-radius: 5px;
    z-index: 1;
    transition: all .4s ease;
}

.myTicketsOrderType::before {
    top: 0;
    left: 0;
    border-top: 1px solid #0162d0;
}

.myTicketsOrderType::after {
    bottom: 0;
    right: 0;
    border-bottom: 1px solid rgb(218, 225, 233);
}

.myTicketsOrderType.myTicketsOrderType--sellActive::after {
    right: 50%;
}

.myTicketsOrderType.myTicketsOrderType--sellActive::before {
    left: 50%;
}

.myTicketsOrderType__btn {
    position: relative;
    width: 50%;
    border: none;
    border-radius: 5px;
    color: rgba(125, 149, 182,0.5);
    text-transform: uppercase;
    cursor: pointer;
    outline: none!important;
    background-color: rgba(6, 103, 208,0.03);
    font-size: 17px;
    text-align: center;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 10px;
    letter-spacing: 1px;
    transition: background-color, color .4s ease;
}

.myTicketsOrderType__btn:first-child::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: calc(100% - 7px);
    border-radius: 50%;
    background-color: rgb(218, 225, 233);
}

.myTicketsOrderType__btn--active {
    color: rgb(6, 103, 208);
    background-color: transparent;
}

.orderSettingsNav {
    position: relative;
    margin-bottom: 25px;
    border-bottom: 1px solid #0000003b;
}

.orderSettingsNav::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 1px;
    width: calc(100% / 3);
    background-color: #0162d0;
    z-index: 9;
    transition: all .4s ease;
}

.orderSettingsNav--secondSelected::before {
    left: 33.33%;
}

.orderSettingsNav--thirdSelected::before {
    left: 66.66%;
}

.orderSettingsNav__btn {
    border: none;
    padding-bottom: 10px;
    background-color: transparent;
    color: #0000003b;
    width: calc(100% / 3);
    cursor: pointer;
    outline: none!important;
    transition: color .4s ease;
}

.orderSettingsNav__btn--active {
    color: #0162d0;
}

.myTicketsOrderSettingsInput {
    display: flex;
    flex-wrap: wrap;
}

.myTicketsOrderSettingsInput:not(:last-child) {
    margin-bottom: 25px;
}

.buy-tickets-inputs {
    padding-left: 150px !important;
    /*background-color: #0a6ffd;*/
}
.buy-tickets-block {
    position: relative;
}
.max-btn {
    position: absolute;
    left: 5px;
    top: 10px;
    /*margin-top: 30px;*/
    outline: none;
    background-color: rgb(6, 103, 208);
    cursor: pointer;
    border: none;
    color: white;
    border-radius: 3px;
    margin-left: 20px;
}

.myTicketsOrderSettingsInput h4 {
    font-size: 17px;
    color: rgb(78, 92, 110);
    letter-spacing: 0.5px;
    width: 100%;
}

.myTicketsOrderSettingsInput > div {
    width: 100%;
    position: relative;
}

.myTicketsOrderSettingsInput input {
    outline: none;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    padding: 10px 55px 10px 10px;
}

.myTicketsOrderSettingsInput input::-webkit-outer-spin-button,
.myTicketsOrderSettingsInput input::-webkit-inner-spin-button,
.myTicketsOrderSettingsInput input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

.myTicketsOrderSettingsInput label {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
}

.myTicketsFinalizeOrder__total > div {
    position: relative;
}

.myTicketsFinalizeOrder__total > div::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    border-top: 1px dashed rgba(0, 0, 0, .4);
}

.myTicketsFinalizeOrder__total > div {
    display: flex;
    justify-content: space-between;
}

.myTicketsFinalizeOrder__total > div > span,
.myTicketsFinalizeOrder__total > div > span:last-child {
    background-color: #ffffff;
    z-index: 2;
}

.myTicketsFinalizeOrder__total > div > span {
    color: rgb(78, 92, 110);
    letter-spacing: 0.5px;
    padding-right: 10px;
}

.myTicketsFinalizeOrder__total > div > span:last-child {
    padding-left: 10px;
}

.myTicketsFinalizeOrder__doOrderBtn button {
    width: 100%;
    background-color: transparent;
    border-radius: 5px;
    padding: 10px 5px;
    cursor: pointer;
    outline: none !important;
    transition: color, background-color .4s ease;
}

.myTicketsFinalizeOrder__doOrderBtn--buy button {
    color: #28cd6a;
    border: 1px solid #28cd6a;
}

.myTicketsFinalizeOrder__doOrderBtn--sell button {
    color: #FF5260;
    border: 1px solid #FF5260;
}

.myTicketsFinalizeOrder__doOrderBtn--buy button:hover {
    color: #fff;
    background-color: #28cd6a;
}

.myTicketsFinalizeOrder__doOrderBtn--sell button:hover {
    color: #fff;
    background-color: #FF5260;
}