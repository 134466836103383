.helpUsFormWrapper {
    padding-top: 40px;
}

.helpUsFormWrapper__title {
    font-size: 20px;
    color: #333;
    letter-spacing: 1px;
    font-weight: 300;
    margin-bottom: 15px;
}

.helpUsFormWrapper__doThisLaterLink {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0;
    display: block;
    color: #0162d0;
    margin-bottom: 15px;
    text-decoration: none;
}

.helpUsFormWrapperDescription,
.uploadPhotoStatusBlock__documentsList li {
    font-weight: 400;
    font-size: 15px;
    width: 40%;
    letter-spacing: 0;
    display: block;
    color: gray;
    margin-bottom: 40px;
}

.uploadPhotoStatusBlock__notice {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0;
    display: block;
    color: red;
}

.helpUsForm__title {
    font-size: 18px;
    color: #333;
    letter-spacing: 1px;
    font-weight: 300;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
}

.helpUsForm__title span {
    display: inline-block;
    color: #0162d0;
    padding: 3px 6px;
    border: 1px solid #0162d0;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 12px;
}

.helpUsFormFields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 950px;
}

.helpUsFormField *,
.identificationDocumentRightSideField > div * {
    display: block;
}

.helpUsFormField {
    margin-bottom: 30px;
    padding-right: 15px;
    width: calc(100% / 3);
}

.helpUsFormField:nth-child(-n + 2) {
    width: 50%;
}

.helpUsFormField label,
.identificationDocumentRightSideField label {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
}

.helpUsFormField input,
.helpUsFormField select,
.identificationDocumentRightSideField select,
.identificationDocumentRightSideField input {
    position: relative;
    border-radius: 4px;
    outline: none!important;
    border: 1px solid rgba(0,0,0,0.1);
    height: 45px;
    padding: 0 10px;
    transition: border-color .3s ease;
    width: 100%;
    background-color: transparent;
}

.helpUsFormField select,
.identificationDocumentRightSideField select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.helpUsFormField input:focus,
.identificationDocumentRightSideField input:focus {
    border-color: #0162d0;
}

.helpUsFormField__selectWrapper,
.identificationDocumentRightSideField div {
    position: relative;
}

.helpUsFormField__selectWrapper::before,
.identificationDocumentRightSideField div::before {
    position: absolute;
    content: '';
    width: 40px;
    height: calc(100% - 2px);
    right: 1px;
    top: 1px;
    border-left: 1px solid rgba(0,0,0,0.1);
    background-color: #ffffff;
    border-radius: 0 4px 4px 0;
    background-image: url('../../../assets/images/down-arrow.svg');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 50%;
}

.helpUsForm__button {
    display: block;
    margin-top: 30px;
    padding: 7px 45px;
    border: none;
    background-color: #0162d0;
    color: #fff;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    outline: none!important;
    text-transform: uppercase;
}

.identificationDocumentRightSide__title {
    font-size: 20px;
    color: #333;
    letter-spacing: -0.4px;
    text-transform: uppercase;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    margin-bottom: 20px;
}

.identificationDocumentRightSide__description {
    margin-bottom: 30px;
    font-size: 15px;
    color: #333;
}

.identificationDocumentRightSideField:not(:last-child) {
    margin-bottom: 30px;
}

.identificationDocument {
    display: flex;
    min-height: 504px;
}

.identificationDocumentLeftSide {
    width: 55%;
    padding-top: 40px;
}

.identificationDocumentRightSide {
    width: 45%;
    padding: 40px;
    border-left: 1px solid rgba(0, 0, 0, .1);
}

/* UploadPhoto */

.uploadPhoto {
    display: flex;
}

.blockForUpload {
    padding-top: 50px;
    width: 60%;
}

.uploadPhotoStatusBlock {
    padding: 50px 50px 0;
    width: 40%;
    border-left: 1px solid rgba(0, 0, 0, .1);
}

.uploadPhotoStatusBlock__title {
    font-size: 20px;
    color: #333;
    letter-spacing: 0.2px;
    font-weight: 300;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
}

.uploadPhotoStatusBlock__documentsList {
    margin: 0;
    list-style: none;
    padding: 0;
}

.uploadPhotoStatusBlock__documentsList li {
    margin-bottom: 15px;
    width: 100%;
    padding-left: 37px;
    position: relative;
}

.uploadPhotoStatusBlock__documentsList span {
    background-color: #0162d0;
    padding: 0 5px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
    position: absolute;
    left: 0;
    top: 0;
}

.uploadPhotoStatusBlock__documentsList img {
    width: 100%;
    max-width: 12px;
}

.inputBlockForUpload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.inputBlockForUpload img {
    width: 100%;
    max-width: 250px;
}

.inputBlockForUpload input {
    display: none;
}

.inputBlockForUpload__description {
    font-size: 18px;
    color: #333;
    letter-spacing: 0.2px;
    font-weight: 300;
    margin: 15px 0 30px;
}

.inputBlockForUpload__button {
    width: 100%;
    max-width: 250px;
    border: 1px solid #0162d0;
    border-radius: 4px;
    background-color: transparent;
    color: #0162d0;
    padding: 10px 10px;
    text-align: center;
}