.error__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    height: calc(100vh - 215px);
}

.errorImg {
    max-width: 600px;
    max-height: 300px;
    margin: 0 auto;
}

.error-message {
    width: 100%;
    margin: 20px auto;
    word-break: break-word;
}

.error__url {
    font-size: 20px;
    color: #0c48c8;
}

@media screen and (max-width: 768px) {
    .errorImg {
        max-width: 400px;
    }
}

@media screen and (max-width: 740px) {
    .error__wrapper {
        height: calc(100vh - 180px);
    }
}

@media screen and (max-width: 680px) {
    .error__wrapper {
        height: calc(100vh - 268px);
    }
}

@media screen and (max-width: 460px) {
    .errorImg {
        max-width: 200px;
    }

    .error-message {
        font-size: 14px;
    }

    .error__url {
        font-size: 16px;
    }
}