/* Reset */
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.artboard {
    display: flex;
    flex-flow: row;
    /*align-items: center;*/
    justify-content: center;
    padding: 1rem;
    height: 100%;
    position: relative;
}

.horisontal-artbook {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /*overflow-x: auto !important;*/
    /*padding-left: 340px;*/
    width: 100%;
}

.horisontal-card {
    border-radius: 10px;
    min-width: 340px;
}


@media (max-width: 37.5em) {
    .artboard {
        padding: 1rem;
    }
}

.card {
    flex: initial;
    position: relative;
    height: 27rem;
    width: 22rem;
    -moz-perspective: 200rem;
    perspective: 200rem;
    margin: .5rem;

}
.card__side {
    height: 27rem;
    transition: all 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-backface-visibility: hidden;
    /* We don't want to see the back part of the element. */
    backface-visibility: hidden;
    /* We don't want to see the back part of the element. */
    border-radius: 10px;
    overflow: hidden;
    /* The image is overflowing the parent. */
    box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.15);
}
.card__side--front {
    /*background-repeat: no-repeat;*/
    /*background-size: contain;*/
    background-size: cover;
    background-position: inherit;
    /*background-image: linear-gradient(to right bottom, rgba(16, 63, 175, 0.93), rgba(57, 165, 251, 0.78)), url(https://cdn.spacetelescope.org/archives/images/screen/heic0406a.jpg);*/
}
.card__side--back {
    background-color: #fff;
    transform: rotateY(180deg);
    position: relative;
    width: 22rem !important;
    margin-left: -10px;
}
.card:hover .card__side--back {
    transform: rotateY(0);
}
.card:hover .card__side--front {
    transform: rotateY(-180deg);
}
.card__theme {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 54%;
    width: 90%;
    text-align: center;
}
.card__theme-box {
    color: #fff;
    margin-bottom: 8rem;
}
.card__subject {
    /*font-family: "Inconsolata", monospace;*/
    letter-spacing: 0.8rem;
    font-size: 1.6rem;
    text-transform: uppercase;
    margin-bottom: -1rem;
    display: flex;
    justify-content: center;
}
.card__title {
    /*font-family: "VT323", monospace;*/
    text-transform: uppercase;
    font-size: 6rem;
    font-weight: 100;
}
.card__cover {
    position: relative;
    /*background-size: cover;*/
    /*height: 10rem;*/
    margin: 30px 0 0 20px

    /*border-top-left-radius: 3px;*/
    /*border-top-right-radius: 3px;*/
}

.card__cover img {
    /*height: 180px;*/
    height: 100px;
    width: 100px;
    max-height: 180px;
    border-radius: 100px;


}
.card__heading {
    text-align: center;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
}
.card__heading-span {
    /*font-family: "VT323", monospace;*/
    font-size: 4.2rem;
    font-weight: 300;
    text-transform: uppercase;
    padding: 1rem 1.5rem;
    color: #fff;
}
.card__details {
    /*font-family: "Inconsolata", monospace;*/
    padding: 20px 0 0 0;
}
.card__details ul {
    list-style: none;
    width: 80%;
    margin: 0 auto;
    color: black
}
.card__details ul li {
    text-align: center;
    font-size: .8rem;
    padding: .3rem;
}
.card__details ul li:not(:last-child) {
    border-bottom: 1px solid #eee;
}
@media only screen and (max-width: 37.5em), only screen and (hover: none) {
    .card {
        height: auto;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.15);
    }
    .card__side {
        height: auto;
        position: relative;
        box-shadow: none;
    }
    .card__side--front {
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
    }
    .card__side--back {
        transform: rotateY(0);
    }
    .card:hover .card__side--front {
        transform: rotateY(0);
    }
    .card__details {
        padding: 3rem 2rem;
    }
    .card__theme {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0);
        width: 100%;
        padding: 5rem 4rem 1.5rem 4rem;
        text-align: right;
    }
    .card__theme-box {
        margin-bottom: 1.5rem;
    }
    .card__title {
        font-size: 4rem;
    }
}

.card__details-btns_block {

    justify-content: space-around;
    /*margin-top: 40px;*/
    display: flex;
}



.flex-column-lot {
    display: flex;
    flex-direction: column !important;
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
}

.lot-list-table {
    background-color:  #f7f7f7;
    overflow: auto;
    max-height: 50px !important;
}

.lot-list-logo {
    width: 50px;
    height: 50px;
    padding: 10px;
    margin-right: 20px;
    border-radius: 100px;
    background-size: cover;
    background-color: #f7f7f7;
}



.lot-list-tr {
    padding: 10px 0 10px 0 !important;
    border: 1px solid #c9c9c93d;
    background-color:  #f7f7f7;
}


.lot-list-tr td {
    margin: 10px 0 !important;
    /*padding: 10px 0 10px 0 !important;*/
}

.lot-auction-card {
    width: 100% !important;
    max-width: 600px !important;
    border: none !important;
}

.lot-showlist-card {
    max-width: 1200px !important;
    width: 100% !important;
    height: 100% !important;
}
