.text-center {
    font-size: 30px !important;
}

.s-input-descr {
    font-size: 15px !important;
    word-wrap: break-word;
}

.description__block {
    margin-bottom: 20px;
}

.ticker-title {
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.ticker-title span {
    position: absolute !important;
    right: 0;
    top: 0;
}

.report__block-on {
    transition: 1s !important;
    opacity: 1 !important;
    height: 100% !important;
    margin-bottom: 10px;
}

.report__block-off {
    opacity: 0;
    height: 0;
    padding: 0 !important;
    margin: 0;
    border: none !important;
    position: absolute;
    top: 0;
    width: 0;
}

.submitReport__button {
    margin-left: 0;
}

.afterSubmit-message {
    color: #00ad7c;
    font-weight: 600;
}

.banned-message {
    margin-bottom: 10px;
}

.warningLinkMessage {
    padding-bottom: 20px;
    color: red;
}

.warning-reportInProgress__auction-page {
    color: red;
    position: fixed;
    text-align: center;
    right: 50%;
    top: 40%;
    width: 200px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    padding: 20px;
    background: white;
    z-index: 10;
    transform: translate(50%, -50%)
    /*top: -37px;*/
}