.message_wrapper {
    /*background-color: red;*/
    margin: 28px auto;
    width: 90%;
    display: flex;
    justify-content: space-around !important;
}

.message-block {
    margin-bottom: 0;
}

.messages-container {
    width: 100%;
    display: flex;
    /*flex-direction: column;*/
    height: calc(100% - 55px);
    /*background-color: yellow;*/
}

.messages-left {
    display: flex;
    width: 45%;
    height: 100%;
    /*background-color: #0a6ffd;*/
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.messages-right {
    display: flex;
    width: 100%;
    height: 90%;
    padding: 10px 20px;
    flex-direction: column;
    /*background-color: #00cc65;*/
}

.messages-left-send {
    /*margin-top: 10px;*/
    width: 100%;
    display: flex;
    height: 50px;
    padding: 0 20px;
    align-items: center;
    /*color: rgba(125, 149, 182,0.5);*/
    /*background-color: rgba(6, 103, 208,0.03);*/
    /*border: 1px solid rgba(0, 0, 0, 0.05);*/
    cursor: pointer;

}

.messages-left-incoming {
    width: 100%;
    display: flex;
    height: 50px;
    padding: 0 20px;
    align-items: center;
    /*color: rgba(125, 149, 182,0.5);*/
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.messages-left-send img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    /*margin: 0 auto;*/
    /*margin-bottom: 10px;*/
}
.messages-left-incoming  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    /*margin: 0 auto;*/
    /*margin-bottom: 10px;*/
}

.messages-active {

    background-color: rgba(6, 103, 208,0.03);
    /*color: rgb(6, 103, 208);*/
}

.messages-select_container {
    position: relative;

}

.messages-select_block {
    width: 300px;
    height: 40px;
    border:  1px solid rgba(0, 0, 0, 0.05);
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
}

.messages-select_ul {
    display: none ;
}
.messages-select_ul li {
    cursor: pointer;
}
.messages-ul_active {
    display: block;
    height: fit-content;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    max-width: 300px;
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 2;
}

.messages-ul_active  li {
    width: 100%;
    height: 40px;
    border-bottom:  1px solid rgba(0, 0, 0, 0.05) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.05) !important;
    border-right:  1px solid rgba(0, 0, 0, 0.05) !important;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0 10px;
}

.ids :hover + .ids {
    /*background-color: rgba(6, 103, 208,0.03);*/
    background-color: green !important;
    cursor: pointer;
}

.selected-proj-block {
    margin-top: 25px;
}

.selected-proj-block__actions {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    min-width: 150px;
}

.selected-proj-block__actions button {
    margin: 5px;
    width: fit-content;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #0162d0;
    font-size: 12px;
    padding: 5px 10px;
    cursor: pointer;
}

.selected-proj-block__send-block {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    max-width: 615px;
}

.selected-proj-block__send-block button {
    width: 100px;
    margin-bottom: 10px;
}

.selected-proj-block__send-block__back-btn {
    width: 60px !important;
    border: none;
    background-color: inherit;
    opacity: 0.6;
    transition: 0.2s;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    text-align: center;
    margin: 0 !important;
}

.selected-proj-block__send-block__back-btn:hover {
    opacity: 1;
}

.selected-proj-block__send-block__mail-textarea {
    margin: 20px 0;
    resize: none;
    border-radius: 4px;
    padding: 10px;
}

.message-pdf-view {
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column !important;
    height: 100%;
    /*background-color: #e1e1e1;*/
    overflow-y: auto;
}

.message-pdf-block {
    display: flex;
    margin: 20px;

    flex-direction: column !important;
}

.message-pdf-block embed {
    height: 600px;
}

@media screen and (max-width: 768px){
    .messages-left-send, .messages-left-incoming {
        font-size: 14px;
    }

    .messages-left-send img, .messages-left-incoming img {
        width: 14px;
        height: 14px;
    }

    .messages-select_block {
        width: 100%;
    }
}