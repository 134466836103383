@import url('https://fonts.googleapis.com/css?family=Kaushan+Script');


.loginScreen {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../../../assets/images/film2.jpg");
    background-size: cover;
    min-height: calc(100% - 62px);
    background-repeat: no-repeat;
}

.thankyouScreen {
    min-height: calc(100% - 201px)
}

.thankyouScreen .screenWrapper {
    padding: 0;
}

.screenWrapper {
    text-align: center;
    height: auto;
    padding-top: 140px;
    padding-bottom: 150px;
}

.register_wrapper-container {
    min-height: calc(100% - 62px);
}

.register_wrapper-container__back-btn {
    position: absolute;
    bottom: -35px;
    right: 0;
    color: white;
    opacity: 0.8;
    transition: 0.3s;
}

.register_wrapper-container__back-btn:hover {
    color: white;
    opacity: 1;
    font-weight: bold;
    text-decoration: none;
}

.register_wrapper-block {
    max-width: 600px;
    margin: 100px auto 0;
    background-color: white;
    height: auto;
    padding: 0 !important;
    text-align: left;
}

.register_wrapper-block__download-pdf {
    margin-bottom: 70px;
    height: 105px;
    position: relative;
}

.register_wrapper-block__download-pdf i,
.register_wrapper-block__addBeneficial__title-block i,
.register_wrapper-block__addBeneficial__form label i,
.requiredCustomer__title i {
    font-size: 10px !important;
    position: absolute;
    top: 4px;
}

.register_wrapper-block__download-pdf__title {
    text-align: start;
    margin-bottom: 5px;
    font-size: 14px;
}

.download-pdf__title_required {
    font-size: 12px;
    color: red;
}

.requiredCustomer__wrapper {
    padding: 15px;
    max-width: 600px;
    margin: 100px auto 0;
    background-color: white;
    border-radius: 4px;
    position: relative;
    flex: 0 !important;
}

.requiredCustomer__title, .legal-status-block__title {
    font-size: 18px;
    font-weight: bold;
}

.requiredCustomer__list {
    font-size: 14px;
    margin-top: 10px;
}

.legal-status-block__title {
    text-align: center;
}

.register_wrapper-block__legal-status-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.register_wrapper-block__legal-status-block button {
    width: 100%;
    margin: 15px 20px 0;
    height: 50px;
}

.register_wrapper-block__addBeneficial__title-block {
    text-align: left;
    font-size: 14px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.register_wrapper-block__addBeneficial__title-block__beneficial-list {
    display: flex;
    flex-wrap: wrap;
}

.register_wrapper-block__addBeneficial__title-block__beneficial-list__item {
    /*width: min-content;*/
    border-radius: 4px;
    padding: 5px;
    font-size: 12px;
    border: 1px solid #0C48C8;
    transition: 0.3s;
    color: black;
    cursor: pointer;
    margin: 10px 10px 10px 0;
}

.register_wrapper-block__addBeneficial__title-block__beneficial-list__item:hover {
    border: 1px solid red;
    background: red;
    color: white;
}

.register_wrapper-block__addBeneficial__form {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    position: relative;
}

.register_wrapper-block__addBeneficial__form label {
    display: flex;
    margin: 0;
}

.register_wrapper-block__addBeneficial__form__date-input {
    width: 100%;
    border-radius: 3px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    margin-bottom: 20px;
    margin-right: 1px;
    outline: none;
    transition: 0.3s border;
}

.register_wrapper-block__addBeneficial__form span {
    color: white !important;
    padding: 5px !important;
    font-size: 14px !important;
    right: 7px;
}

.register_wrapper-block__addBeneficial__form label i {
    color: black;
    left: 13px;
    top: 5px;
}

.register_wrapper-block__addBeneficial__form button {
    width: 100px;
}

.register_wrapper-block__addBeneficial__form__id-item {
    display: flex;
    flex-direction: column;
}

.register_wrapper-block__addBeneficial__form__id-item img {
    height: 78px;
}

.register_wrapper-block__addBeneficial__form__id-item__title {
    display: flex;
}

.register_wrapper-block__addBeneficial__form__id-item__download-block {
    margin-bottom: 20px;
}

.register_wrapper-block__addBeneficial__form__id-item__download-block p {
    font-size: 14px;
    margin-bottom: 5px;
}

.screenWrapper .mainTitle {
    color: rgba(255, 255, 255, 1);
    font-size: 28px;
    letter-spacing: 2px;
    padding-top: 20px;
    padding-bottom: 40px;
    text-align: center;
    font-weight: 300;
    margin: 0 auto;
}

.mainDescription {
    margin: 0 auto;
    text-align: center;
    font-weight: 300;
    padding-bottom: 40px;
    letter-spacing: 1px;
    color: white;
}

.loginWrap {
    margin: 0 auto;
    width: 400px;
    background-color: white;
    height: 30px;
    border-radius: 4px;
    height: 220px;
    box-shadow: 0px 1px 46px -4px rgba(0, 0, 0, 0.28);
    padding: 25px;
    padding-top: 35px;
}
.loginWrap .passwordVerify {
    padding-left: 40px !important;
}

.loginScreen .requiredCustomer__list {
    padding-left: 20px !important;
}
.loginWrap input[type="text"], .loginWrap input[type="password"] {
    width: 100%;
    height: 40px;
    border-radius: 3px;
    padding-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    margin-bottom: 20px;
    margin-right: 1px;
    -webkit-appearance: none;
    outline: none;
    transition: 0.3s border;
}

.loginWrap input:focus {
    border: 1px solid #0667d0;
}

.loginWrap input::placeholder {
    color: rgba(0, 0, 0, 0.4);
}

.loginWrap label {
    padding-top: 10px;
    text-align: left;
    float: left;
}

.loginWrap label input {
    float: left;
}

.loginWrap label span {
    float: left;
    color: black;
    text-align: left;
    font-size: 11.5px;
    padding-left: 10px;
    color: #333;
}

.mustHaveAtLeast {
    text-align: left;
    font-weight: 800;
    color: black;
    font-size: 11px;
    padding-bottom: 4px;
}

.loginWrap .rgTextW.fifty {
    width: 50%;
    margin: 0;
    float: left;
    padding-right: 10px;
}

.loginWrap .rgTextW.fifty:nth-of-type(even) {
    padding-right: 0;
}

.loginWrap .rgTextW.third {
    width: 33.3333%;
    margin: 0;
    float: left;
    padding-right: 10px;
}

.loginWrap .rgTextW.third:nth-of-type(3n) {
    padding-right: 0;
}


.passwordVerify {
    display: block;
    margin-bottom: 7px;
    text-align: left;
    list-style: none;
    margin-left: -40px;
}

.passwordVerify li {
    display: inline-block;
    font-size: 10px;
    padding: 5px 10px;
    background-color: #f3f3f3;
    border-radius: 4px;
    font-weight: 400;
    color: black;
    margin-right: 4px;
}

.passwordVerify li.pass {
    background-color: #00AD7C;
    color: #f3f3f3;
}

.passwordVerify li:last-child {
    margin-right: 0;
}


.stateLabel {
    font-weight: 300;
    font-size: 13px;
    margin-top: 7px;
    color: black;
}

.stateSelect {
    width: 200px;
    height: 30px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    outline: none !important;
    font-size: 13px;
    color: black;

}

.signupWrap {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
    padding-top: 25px;
}


.tabs {
    width: 400px;
    margin: 0 auto;
    color: rgba(125, 149, 182, 0.5);
    background-color: white;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.tabs a {
    width: 50%;
    float: left;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    text-align: center;
    color: rgba(125, 149, 182, 0.5);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
    background-color: rgba(6, 103, 208, 0.03);
    border-bottom: 1px solid rgb(218, 225, 233);
    font-size: 14.5px;
    font-weight: 300;
}

.tabs a.activeNav:hover {
    color: rgb(6, 103, 208);
}

.tabs a.activeNav {
    color: rgb(6, 103, 208) !important;
    border-left: 1px solid rgb(218, 225, 233);
    border-right: 1px solid rgb(218, 225, 233);
    border-bottom: 0 !important;
    height: 62px;
    background-color: white;
}


.numberInput {
    width: 100%;
    height: 60px;
    border-radius: 3px;
    padding-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    margin-bottom: 20px;
    outline: none;
    -webkit-appearance: none;
    text-align: center;
    transition: 0.3s border;
}

.screenWrapper i {
    text-align: center;
    font-size: 150px;
}


.errorMessage {
    color: #FFB2B2;
    font-size: 14px;
    letter-spacing: 1px;
}

.errorWithin {
    color: red;
    font-size: 12px;
    padding-top: 0;
}

.extraSigns {
    width: 390px;
    margin: 0 auto;
    text-align: left;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
}

.extraSigns a {
    color: white;
    font-weight: 300;
    font-size: 13px;
    outline: initial;
    margin-bottom: 15px;
}

.extraSigns a:hover {
    color: white;
    text-decoration: none;
}

.wrapper-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f3f3f3;
    border-radius: 15px;
    max-width: 620px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wrapper-2 {
    padding: 100px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

.wrapper-2 p {
    margin: 0;
    font-size: 16px;
    color: black;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 1px;
}

.thankyouMessage {
    font-family: Kaushan Script, sans-serif;
    display: block;
    font-size: 80px !important;
    transition: 0.2s all;
    color: rgb(6, 103, 208) !important;
    padding-bottom: 20px;
    line-height: normal !important;
}

.footer-like {
    margin-top: auto;
    background: rgb(6, 103, 208);
    transition: 0.5s all;
    padding: 6px;
    text-align: center;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    color: white;
}

.footer-like a {
    cursor: pointer;
}

.footer-like p {
    margin: 0;
    padding: 4px;
    color: #fff;
    letter-spacing: 1px;
    font-size: 12px;
}

.footer-like p a {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
}

.intl-tel-input .numberInput {
    font-size: 25px !important;
    padding-left: 0 !important;
    letter-spacing: 2px;
    -webkit-appearance: none;
    padding-right: 0 !important;
}

.country-list {
    box-shadow: none !important;
    width: 450px !important;
    color: black !important;
}

.selected-flag {
    outline: none !important;
}


.inputsP {
    width: 32.3%;
    margin-right: 1%;
}

.loadingScreen {
    background-color: #152664;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.two-step_first-wrapper {
    width: 100%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 5px;
    height: initial;
    max-width: 400px;
    padding-bottom: 40px;
}

.two-step_first-wrapper a.disabled {
    pointer-events: none;
}

.two-step_first-wrapper a {
    font-size: 16px;
}

.two-step_first-wrapper a:link {
    color: rgba(0, 0, 0, 0.4);
}

.two-step_first-wrapper a:visited {
    color: rgba(0, 0, 0, 0.4);
}

.two-step_first-wrapper .two-step_second-wrapper {
    padding: 25px 0 5px 0;
    display: flex;
    flex-flow: column;
}

.two-step_first-wrapper .two-step_second-wrapper .two-step_title {
    font-weight: bold;
    margin: 0 0 10px 0;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.7);
}

.two-step_first-wrapper .two-step_second-wrapper .two-step_desc {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);
}

.two-step_first-wrapper .enter-code.sms {
    /*background: url("../../images/smartphone.png") no-repeat 323px 28px;*/
    background-size: 59px;
}

.two-step_first-wrapper .enter-code {
    /*padding: 20px 28px;*/
    width: 100%;
    height: 80px;
    /*border-top: 1px solid rgba(0,0,0,0.2);*/
    /*border-bottom: 1px solid rgba(0,0,0,0.2);*/
    /*background: url("../../images/google-auth.png") no-repeat 30px 25px;*/
    /*background-size: 70px;*/
}

.two-step_footer {
    display: flex;
    align-items: flex-start;
    padding: 5px 0 0 0;
    font-size: 13px;
    text-align: left;
}

.two-step_footer input {
    margin: 4px;
}

.two-step_first-wrapper .enter-code .code-input {
    font-size: 19px;
    letter-spacing: 3px;
    color: #555;
    border-radius: 3px;
    border: 1px solid #ccc;
    padding: 9px;
    margin-right: 10px;
    height: 42px;
    box-sizing: border-box;
    width: 115px;
}

.two-step_first-wrapper .enter-code.sms .code-input {
    width: 130px;
    padding-left: 14px;
    font-size: 24px;
}

.two-step_first-wrapper .enter-code .code-input.red {
    border: 2px solid #e9453c;
}

.two-step_first-wrapper .enter-code.sms .controls {
    margin-left: 10px;
}

.two-step_first-wrapper .enter-code .controls {
    text-align: left;
    font-size: 13px;
    /*margin-left: 90px;*/
    display: flex;
    flex-flow: column;
}

.two-step_first-wrapper .enter-code .controls span {
    margin-bottom: 6px;
    text-align: center;
}

.two-step_first-wrapper .enter-code .controls .btn-two-step {
    cursor: pointer;
    color: #fff;
    border-radius: 3px;
    padding: 1em 2em;
    border: 1px solid #0162D0;
    font-size: 11px;
    text-transform: uppercase;
    height: 42px;
    letter-spacing: .25em;
    transition: all .15s ease;
    background: #0162D0;
    margin-right: 10px;
}

.two-step_first-wrapper .enter-code.sms .controls .btn-two-step {
    width: 118px;
}

.two-step_first-wrapper .enter-code .controls .btn-two-step:hover {
    background-color: #2669ea;
    box-shadow: 0 2px 1px rgba(0, 0, 0, .1);
}

.auth-img_block {
    /*background-color: #0a6ffd;*/
}

.auth-img_block img {
    height: 80px;
    margin: 17px;
}

.two-step_first-wrapper .enter-code .controls .unput-two-step {
    display: flex;
    flex-flow: row;
    justify-content: center;
}

@-webkit-keyframes rotate-forever {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes rotate-forever {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-forever {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader,
.loading-spinner {
    -webkit-animation-duration: 0.75s;
    -moz-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: rotate-forever;
    -moz-animation-name: rotate-forever;
    animation-name: rotate-forever;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    animation-timing-function: linear;
    height: 30px;
    width: 30px;
    border: 2px solid #ffffff;
    border-right-color: transparent;
    border-radius: 50%;
    display: inline-block;
}

.loading-spinner {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;

}

.timerCount {
    font-size: 17px;
    color: lightgrey !important;
    font-weight: 400;
    letter-spacing: 1px;
}

.createAccountWrap {
    padding-bottom: 100px;
}

.createAccountWrap .screenWrapper {
    padding-top: 130px;
}

@media screen and (max-height: 800px) {
    .createAccountWrap .screenWrapper {
        padding-top: 100px;
    }

    .screenWrapper .mainTitle {
        padding-bottom: 10px;
    }

    .thankyouScreen .screenWrapper {
        padding-bottom: 100px;
        padding-top: 80px;
    }

    .phoneVerifiedWrapone .screenWrapper {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}


@media screen and (max-width: 660px) {
    .wrapper-1 {
        width: 95% !important;
    }

    .thankyouMessage {
        width: 95% !important;
    }
}

@media screen and (max-width: 640px) {
    .requiredCustomer__wrapper {
        margin: 100px 19px 0;
    }
}


@media screen and (max-width: 560px) {
    .wrapper-2 p {
        padding-left: 100px;
        padding-right: 100px;
    }

    .thankyouMessage {
        width: 95% !important;
        font-size: 50px !important;
    }
}


@media screen and (max-width: 530px) {
    .phoneNumberEnter {
        width: 95% !important;
        padding-left: 10px;
        padding-right: 10px;

    }

    .intl-tel-input {
        width: 100% !important;
    }

    .intl-tel-input .numberInput {
        font-size: 20px !important;
    }

    .verifyPhoneWrap {
        width: 95% !important;
    }

    .verifyPhoneWrap {
        padding-left: 10px;
        padding-right: 10px;
    }

    .verifyPhoneWrap .numberInput {
        font-size: 20px !important;
    }


}


@media screen and (max-width: 460px) {
    .wrapper-2 p {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14px;
    }
}


@media screen and (max-width: 430px) {
    .loginWrap {
        width: 95%;
    }

    .extraSigns {
        width: 95%;
    }
}

.loginBttn {
    /*background-color: #28cd6a;*/
    position: relative;
    padding: 13px 20px;
    height: 40px;
    color: white !important;
    background-image: none;
    outline: none;
    border: none;
    outline: none;
    color: white;
    font-size: 11px;
    text-transform: uppercase;
    border-radius: 3px;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all ease-out 0.5s;
    text-decoration: none !important;

}

.loadingButton {
    background-color: #0162D0;
    position: relative;
    padding: 13px 20px;
    height: 40px;
    color: white !important;
    background-image: none;
    outline: none;
    border: none;
    color: white;
    font-size: 11px;
    text-transform: uppercase;
    float: right;
    border-radius: 3px;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all ease-out 0.5s;
}

.loadingButton:hover {
    background-color: #0159bb;
}

.loadingButton:focus {
    outline: none;
}

.loadingButton .active {
    transition-delay: 1s;
    width: 10px;
}

.loadingState {
    pointer-events: none;
    cursor: not-allowed !important;
}

.spinner {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    margin: 0 auto;
    user-select: none;
    pointer-events: none;
    left: 0;
    right: 0;
    background: transparent;
    box-sizing: border-box;
    border-top: 2px solid white;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-radius: 100%;
    animation-delay: 0.5s;
    animation: spin 0.6s ease-out infinite;
    transition: all ease 0.5s;
}

.loading-creators__wrapper {
    background: white;
    margin: 15px;
}

.loading-creators__wrapper:hover {
    background: white;
}

.loading-creators__spinner {
    border-top: 2px solid #0159bb;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}


















