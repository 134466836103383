select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.addUsersForm {
    overflow: hidden;
    height: 0;
    transition: all .3s;
    max-height: 410px;
}

.addUsersForm--show {
    height: 410px;
}

.addUsersForm--hide {
    height: 0;
}

.addUserInputBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.addUserInputBlock:nth-child(4) {
    margin-bottom: 30px;
}

.addUserInputBlock input,
.addUserInputBlock select {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px;
    outline: none;
}

.addUserInputBlock__label {
    text-transform: uppercase;
    font-size: 11px;
    margin-bottom: 5px;
}

.addUserInputBlock select {
    background-color: transparent;
}

.addUserInputBlock .addUsersInputBlock--input__notValid {
    border: 1px solid #ce0000;
}

.addUserInputBlock__selectedRoles {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 5px;
}

.addUserInputBlock__selectedRoles span {
    position: relative;
    cursor: pointer;
    background-color: #0162d0;
    color: #fff;
    font-size: 12px;
    border-radius: 25px;
    padding: 2px 25px 2px 6px;
    margin-right: 3px;
    margin-bottom: 3px;
    transition: background-color .3s;
}

.addUserInputBlock__selectedRoles span img {
    width: 100%;
    max-width: 16px;
    position: absolute;
    right: 0;
    bottom: 50%;
    transform: translate(-5px, 50%);
}

.addUserInputBlock__selectedRoles span:hover {
    background-color: #ce0000;
}

.addUserList__item {
    display: flex;
    color: #808080;
}

.addUserList__item button {
    padding-left: 5px;
    margin-left: auto;
}

.addUserList__item:not(:last-child) {
    padding-bottom: 7px;
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.auctions_flex-item {
    display: flex;
    justify-content: space-between;
}

.addUserList__item:not(:last-child) {
    margin-bottom: 7px;
}

.addUserList__item.addUserList__item--confirmed {
    color: #1c2b4a;
}

.addUserList {
    max-height: 175px;
    overflow: auto;
}

.addUsersForm__button {
    margin-bottom: 30px;
}
