.imagePreviewWrapper {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    cursor: pointer;
    /*padding-top: 19px;*/
}

.imagePreviewWrapper > div {
    width: 100%;
}

@media screen and (min-width: 750px) {
    .imagePreviewWrapper {
        /*padding-top: 69px;*/
    }
}

.imagePreview {
    align-items: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background: black;
    z-index: 0;
}


.image-spinner-frame {
    height: 600px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    z-index: 2;
    width: 100%;
    position: absolute;
}

.loader__blue,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loader__blue {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(1, 98, 208, 0.2);
    border-right: 1.1em solid rgba(1, 98, 208, 0.3);
    border-bottom: 1.1em solid rgba(1, 98, 208, 0.3);
    border-left: 1.1em solid #0162D0;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.imagePreviewFirstWrapper {
    position: relative;
    width: 67%;
    height: 294px;
}

.imagePreviewFirstWrapper .image-spinner-frame {
    height: 100%;
}

.imagePreviewSecondWrapper .image-spinner-frame,
.imagePreviewThirdWrapper .image-spinner-frame {
    height: 50%;
}

.imagePreviewFirst {
    height: 100%;
    background-color: rgb(0, 0, 0);
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
}

.imagePreviewSecond {
    height: 50%;
}

.imagePreviewSecond {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background-color: rgb(0, 0, 0);
    position: relative;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
}

.imagePreviewThirdWrapper {
    display: none;
}

.imagePreviewThirdWrapper,
.imagePreviewSecondWrapper {
    flex-direction: column;
    position: relative;
    width: 33%;
    overflow: hidden;
    height: 294px;
}

.mode--deletedPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #0162d0;
    cursor: auto;
}

.uploadImgInputBox {
    width: 100%;
    height: 100%;
    max-width: 180px;
    max-height: 180px;
}

.uploadImgInputBox__label {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.uploadImgInputBox__label-internal {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: auto;
    right: auto;
    top: 70px;
    left: 3%;
    background-image: url('../../../assets/images/photo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.uploadImgInputBox__label::before {
    position: absolute;
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    left: 15px;
    top: 15px;
    background-image: url('../../../assets/images/photo.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

/*
image
*/
.imagePreviewFirst img,
.imagePreviewSecond img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

/*
image wrapper 2 and 3
*/

/* drag and drop */

.startDrag > div:not(.showImageContainer),
.startDrag > div > div:not(.controlPanel--draggable) {
    position: static !important;
    background-color: transparent;
}

.startDrag {
    position: relative;
    z-index: 999;
    cursor: move;
}

.startDrag img {
    z-index: 100;
}

.draggableImage {
    transform: scale(0.9555) rotate(0deg);
    animation: draggable .2s infinite linear;
    animation-direction: alternate;
}

.showImageContainer {
    height: 100%;
    width: 100%;
}

@keyframes draggable {
    0% {
        transform: scale(0.9555) rotate(-0.5deg);
    }
    100% {
        transform: scale(0.9555) rotate(0.5deg);
    }
}

/* /drag and drop */

@media (min-height: 700px) {
    .imagePreviewSecondWrapper,
    .imagePreviewThirdWrapper,
    .imagePreviewFirstWrapper {
        height: 294px;
    }
}

@media (min-height: 850px) {
    .imagePreviewSecondWrapper,
    .imagePreviewThirdWrapper,
    .imagePreviewFirstWrapper {
        height: 394px;
    }
}

@media (min-height: 1000px) {
    .imagePreviewSecondWrapper,
    .imagePreviewThirdWrapper,
    .imagePreviewFirstWrapper {
        height: 494px;
    }
}

@media (min-width: 744px) {
    .imagePreview:hover img {
        opacity: .7 !important;
    }

    .imagePreviewFirst:hover img,
    .imagePreviewSecond:hover img {
        opacity: 1 !important;
    }

    @media (min-height: 700px) {
        .imagePreviewSecondWrapper,
        .imagePreviewThirdWrapper,
        .imagePreviewFirstWrapper {
            height: 353px;
        }
    }
    @media (min-height: 850px) {
        .imagePreviewSecondWrapper,
        .imagePreviewThirdWrapper,
        .imagePreviewFirstWrapper {
            height: 473px;
        }
    }
    @media (min-height: 1000px) {
        .imagePreviewSecondWrapper,
        .imagePreviewThirdWrapper,
        .imagePreviewFirstWrapper {
            height: 593px;
        }
    }
}

@media (min-width: 1128px) {
    .imagePreviewSecondWrapper,
    .imagePreviewThirdWrapper {
        width: 25%;
    }

    .imagePreviewThirdWrapper {
        display: block;
    }

    .imagePreviewFirstWrapper {
        width: 50%;
    }

    @media (min-height: 700px) {
        .imagePreviewSecondWrapper,
        .imagePreviewThirdWrapper,
        .imagePreviewFirstWrapper {
            height: 442px;
        }

        .imagePreviewFirst {
            height: 442px;
        }
    }
    @media (min-height: 850px) {
        .imagePreviewSecondWrapper,
        .imagePreviewThirdWrapper,
        .imagePreviewFirstWrapper {
            height: 592px;
        }

        .imagePreviewFirst {
            height: 592px !important;
        }
    }
    @media (min-height: 1000px) {
        .imagePreviewSecondWrapper,
        .imagePreviewThirdWrapper,
        .imagePreviewFirstWrapper {
            height: 742px;
        }

        .imagePreviewFirst {
            height: 742px !important;
        }
    }
}

@media screen and (max-width: 650px) {
    .imagePreviewFirstWrapper {
        width: 100%;
    }

    .imagePreviewSecondWrapper {
        display: none;
    }
}
