.header__menu_open__shadow {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
}

.header__wrapper {
    width: 100%;
    z-index: 1;
    box-shadow: 0 1px 0 hsla(0, 0%, 0%, 0.1);
}