.settings__wrapper {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        url(/static/media/background-grey.2f82a862.png);
    background-size: 300px;
    min-height: calc(100% - 201px);
}

.compliance__wrapper {
    width: 80%;
    background-color: white;
    margin: 20px auto;
    border-radius: 4px;
}

.compliance__bredcrubms {
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 40px;
    background: white;
    border: 2px solid var(--primaryColor);
    text-align: center;
    color: var(--primaryColor);
    display: flex;
    align-items: center;
    justify-content: center;
    top: -4%;
    left: -59px;
}

.compliance__content__wrapper {
    display: flex;
    margin: 40px 0;
    padding: 0 40px;
}

.compliance__warning {
    margin-bottom: 20px;
}

.compliance__warning p:first-child {
    font-weight: bold;
}

.compliance__content__border:after {
    display: block;
    content: "";
    height: 100%;
    border-left: 3px solid var(--primaryColor);
    margin-right: 40px;
}

.compliance__content__item {
    padding-bottom: 20px;
}

.compliance__content__item__header {
    position: relative;
}

.compliance__item__header__title {
    font-size: 20px;
    color: black;
    letter-spacing: 1px;
    font-weight: 300;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.compliance__item__header__title p:last-child {
    color: var(--primaryColor);
    font-size: 14px;
    font-weight: 500;
}

.compliance__item__content {
    margin-top: 20px;
}

.compliance__item__content__select,
.compliance__item__content__form__item input,
.compliance__item__content__form__item select {
    padding: 5px 29px 5px 5px;
    border: 1px solid var(--lightPrimaryColor);
    border-radius: 4px;
    transition: 0.2s;
}

.compliance__item__content__country-list:hover,
.compliance__item__content__form__item input:hover,
.compliance__item__content__form__item select:hover {
    border-color: var(--darkPrimaryColor);
}

.compliance__item__content__forms-block {
    display: flex;
    flex-direction: column;
}

.compliance__item__content__form__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.compliance__item__content__form__wrapper:first-child {
    margin-bottom: 20px;
}

.compliance__item__content__form {
    width: 49%;
}

.compliance__item__content__form__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.compliance__item__content__form__title {
    font-weight: bold;
    margin-bottom: 10px;
}

.compliance__item__content__form__item label {
    width: 30%;
}

.compliance__item__content__form__item input,
.compliance__item__content__form__item select {
    width: 70%;
}

.compliance__item__content__form__item input {
    padding-right: 5px;
}

.compliance__item__content__submit-btn {
    margin: 10px 0 0;
}

.compliance__loader {
    display: inline-block;
    width: 40px;
    height: 40px;
}

.compliance__loader:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 3px solid var(--primaryColor);
    border-color: var(--primaryColor) transparent var(--primaryColor) transparent;
    animation: compliance__loader 1.2s linear infinite;
}
.getId-top_hdlogo {
    position: absolute; 
    width: 250px; 
    height: 50px; 
    background-color: white; 
    top: 0px; 
    z-index: 1
}

.getId-top_line {
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: white;
    top: 0px;
    z-index: 1;
}

.getId-right_line {
    position: absolute;
    width: 3px;
    height: 100%;
    background-color: white;
    right: 0px;
    z-index: 1;
}
.getId-left_line {
    position: absolute;
    width: 3px;
    height: 100%;
    background-color: white;
    left: 0px;
    z-index: 1;
}

.getId-bottom_line {
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: white;
    bottom: 0px;
    z-index: 1;
}

@keyframes compliance__loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 1330px) {
    .settings__wrapper {
        padding: 0 20px;
    }

    .compliance__wrapper {
        margin-top: 40px;
        width: 100%;
    }
}

@media screen and (max-width: 1300px) {
    .settings__wrapper {
        padding: 0 40px;
    }
}

@media screen and (max-width: 1120px) {
    .compliance__item__content__form__wrapper {
        flex-direction: column;
    }

    .compliance__item__content__form__wrapper,
    .compliance__item__content__form {
        width: auto;
    }
}

@media screen and (max-width: 800px) {
    .settings__wrapper {
        padding-top: 20px;
    }

    .compliance__content__wrapper {
        padding: 0 20px;
    }
}

@media screen and (max-width: 750px) {
    .settings__wrapper {
        padding: 20px 30px;
    }
}

@media screen and (max-width: 620px) {
    .settings__wrapper {
        padding: 20px 10px;
    }
}

#getid-main .getid-main-container {
    border: none !important;
}
