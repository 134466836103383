.backupPhrase__wrapper
  //width: 740px
  margin: 0 auto
  color: black
  padding: 0 10%
  overflow-y: hidden
  overflow-x: hidden


.backupPhrase__wrapper header
  margin-bottom: 40px


.backupPhrase__content-wrapper
  display: flex



.backupPhrase__content-wrapper p
  font-size: 14px
  line-height: 1.3


.backupPhrase__content-left
  //flex-shrink: 0.4
  p
    word-break: break-word
    max-width: 100% !important


.backupPhrase__content-right
  flex-shrink: 0.8
  margin-top: 58px
  margin-left: 20px


.backupPhrase__words-wrapper
  color: #4d4d4d
  box-sizing: border-box
  position: relative
  display: flex
  justify-content: center
  border: 1px solid #CDCDCD
  border-radius: 6px
  margin-top: 36px
  max-width: 350px
  padding: 20px
  min-height: 120px
  font-size: 18px
  text-align: center


.backupPhrase__words-lock
  position: absolute
  top: 0
  bottom: 0
  height: 100%
  width: 100%
  background-color: rgba(65, 121, 196, 0.95)
  filter: blur(10%)
  //background-color: red
  display: flex
  flex-flow: column nowrap
  align-items: center
  justify-content: center
  padding: 18px 0 43px 0
  cursor: pointer

  color: white
  border-radius: 6px


.backupPhrase__words-lock p
  font-size: 14px
  //margin-top: 10px
  //letter-spacing: -0.5px


.downloadCodeLink
  color: #2f9ae0bf
  cursor: pointer


.downloadCodeLink:hover
  color: #0563d0


@media screen and  (max-width: 800px)
  .backupPhrase__content-wrapper
    flex-wrap: wrap
  .backupPhrase__content-right
    flex-shrink: 1 !important
    margin-top: 58px

  //margin-left: 20px
  .backupPhrase__wrapper

    overflow-y: auto
    overflow-x: hidden
