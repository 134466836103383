
@font-face {
  font-family: "Alegreya Sans";
  src: url(fonts/Alegreya_Sans.woff2);
}

@font-face {
  font-family: "Amatic SC";
  src: url(fonts/Amatic_SC.woff2);
}

@font-face {
  font-family: "Berkshire Swash";
  src: url(fonts/Berkshire_Swash.woff2);
}

@font-face {
  font-family: "Bubblegum Sans";
  src: url(fonts/Bubblegum_Sans.woff2);
}

@font-face {
  font-family: "Bungee Shade";
  src: url(fonts/Bungee_Shade.woff2);
}

@font-face {
  font-family: "Chivo";
  src: url(fonts/Chivo.woff2);
}

@font-face {
  font-family: "Comfortaa";
  src: url(fonts/Comfortaa.woff2);
}

@font-face {
  font-family: "Cookie";
  src: url(fonts/Cookie.woff2);
}

@font-face {
  font-family: "Courgette";
  src: url(fonts/Courgette.woff2);
}

@font-face {
  font-family: "East Sea Dokdo";
  src: url(fonts/East_Sea_Dokdo.woff2);
}

@font-face {
  font-family: "Eczar";
  src: url(fonts/Eczar.woff2);
}

@font-face {
  font-family: "Fenix";
  src: url(fonts/Fenix.woff2);
}

@font-face {
  font-family: "Inknut Antiqua";
  src: url(fonts/Inknut_Antiqua.woff2);
}

@font-face {
  font-family: "Kaushan Script";
  src: url(fonts/Kaushan_Script.woff2);
}

@font-face {
  font-family: "Monoton";
  src: url(fonts/Monoton.woff2);
}

@font-face {
  font-family: "Montez";
  src: url(fonts/Montez.woff2);
}

@font-face {
  font-family: "Poiret One";
  src: url(fonts/Poiret_One.woff2);
}

@font-face {
  font-family: "Poppins";
  src: url(fonts/Poppins.woff2);
}

@font-face {
  font-family: "Princess Sofia";
  src: url(fonts/Princess_Sofia.woff2);
}

@font-face {
  font-family: "Rambla";
  src: url(fonts/Rambla.woff2);
}

@font-face {
  font-family: "Righteous";
  src: url(fonts/Righteous.woff2);
}

@font-face {
  font-family: "Rock Salt";
  src: url(fonts/Rock_Salt.woff2);
}

@font-face {
  font-family: "Sacramento";
  src: url(fonts/Sacramento.woff2);
}

@font-face {
  font-family: "Satisfy";
  src: url(fonts/Satisfy.woff2);
}

@font-face {
  font-family: "Shadows Into Light";
  src: url(fonts/Shadows_Into_Light.woff2);
}

@font-face {
  font-family: "Spectral";
  src: url(fonts/Spectral.woff2);
}

@font-face {
  font-family: "Trocchi";
  src: url(fonts/Trocchi.woff2);
}

@font-face {
  font-family: "Vast Shadow";
  src: url(fonts/Vast_Shadow.woff2);
}