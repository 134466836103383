.main {
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	-webkit-align-items: center !important;
	align-items: center !important;
	display: -webkit-box !important;
	display: -moz-box !important;
	display: -ms-flexbox !important;
	display: -webkit-flex !important;
	display: flex !important;
	flex_direction: row !important;
	overflow: hidden !important;
}

.large-content {
    width: 50%;
    /* height: 100%; */
	-webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    overflow: hidden !important;
    -webkit-align-items: center !important;
    align-items: center !important;
    -webkit-justify-content: center !important;
    justify-content: center !important;
    vertical-align: middle !important;
    background-color: #484848 !important;
    position: relative !important;
    /* width: 67% !important;     */
    border-width: 0.4px !important;
    border-color: #484848 !important;
    border-style: solid !important;
}

.small-content {
	flex_direction: column !important;
    position: relative !important;
    width: 50% !important;
    overflow: hidden !important;    
}

.small-piece {
	-webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    overflow: hidden !important;
    -webkit-align-items: center !important;    
    -webkit-justify-content: center !important;
    justify-content: center !important;
    vertical-align: middle !important;
    background-color: #484848 !important;
    position: relative !important;
    /* height: 50% !important; */
    border-width: 0.4px !important;
    border-color: #484848 !important;
    border-style: solid !important;
}

.small-piece.top {
    align-items: flex-end !important;
}

.small-piece.bottom {
    align-items: flex-start !important;
}

@media (max-width: 768px) {
    .small-content.last {
        display: none;
    }

    .large-content.first {
        width: 66.7% !important;
    }

    .large-content.last {
        width: 33.3% !important;
    }

    .small-content.first {
        width: 100% !important;
    }
}

@media (max-width: 425px) {
    .small-content.last,
    .small-content.first {
        display: none;
    }

    .large-content.last {
        display: none !important;
    }

    .large-content.first {
        width: 100% !important;
        height: auto !important;
    }
}