/* style for button without border */
.editBtn--withoutBorder {
    outline: none !important;
    width: auto;
    height: auto;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
}

/* styles for red btn */

.btn--greenBtn,
.btn--redBtn {
    width: 100%;
    border: none;
    outline: none;
    border-radius: 5px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    height: auto;
    margin: 0;
    padding: 11px 3px 11px 3px;
    font-weight: 400;
}

.btn--redBtn {
    background-color: #FF5260 !important;
}

.btn--greenBtn {
    background-color: #28cd6a;
}

/* style for btn close with svg */
.btn--close {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    outline: none !important;
    width: 45px;
    height: 28px;
}

.btn--close img {
    max-width: 100%;
    max-height: 100%;
}

.processDataMessage {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: rgb(6, 103, 208);
    letter-spacing: 0.4px;
    font-size: 16px;
    font-weight: 400;
}

.body--blur {
    overflow: hidden;
}

.body--blur div:not(#fanbase) {
    filter: blur(2px);
}

.body--blur #fanbase > div {
    filter: none;
}


.link {
    color: #0a6ffd;
    cursor: pointer;
    transition-duration: .2s;
}

.link:hover {
    transition-duration: .2s;
    color: #0a6ffd;
    cursor: pointer;
    text-decoration: underline #0a6ffd !important;

}
