.showProjectMediaFile {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
}

.mediaFileWrapper {
    display: flex;
    height: 100%;
    width: 100%;
    background: white;
    padding: 20px;
    justify-content: space-between;
}

.mediaFileWrapper span {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0162d0;
    font-size: 29px;
}

.mediaFileWrapper embed {
    background-color: #fff;
}

.mediaFileWrapper > * {
    width: 100%;
    height: 100%;
    object-fit: contain;
    outline: none;
    border: none;
}

.showProjectMediaFile__closeBtn {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 45px;
    height: 45px;
    background-color: transparent;
    background-image: url("../../../assets/images/error.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    border: none;
    outline: none!important;
    cursor: pointer;
    padding: 0;
    display: block;
}

.project_close-btn {
    margin-top: 80px !important;
}

.auctionItem__list {
    padding: 0 30px;
    max-width: 400px;
}

.auctionItem__list li {
    cursor: pointer;
    padding-top: 10px;
}

.contentItem__wrapper *{
    width: 100%;
    max-height: 79.5vh;
}

@media screen and (max-width: 880px){
    .mediaFileWrapper{
        flex-direction: column;
        overflow: auto;
    }
}