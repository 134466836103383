.resetPasswordPage {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 62px);
}

.resetPasswordPage .pageContent {
    flex: 1 0 auto;
}

.resetPasswordPage .pageContent + div {
    flex: 0 0 auto
}

.phoneVerifiedWrapone {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(/static/media/film2.8449e1ea.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}