.myTicketsChart {
    margin-bottom: 20px;
}

.myTicketsChart > .chart {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
}

.myTicketsChart .chart_title {
    height: auto;
    padding: 9px 20px 7px;
}

.myTicketsChart .chart_title > p {
    letter-spacing: 0.4px;
    font-size: 16px;
    font-weight: 400;
    color: rgb(6, 103, 208);
    padding-left: 10px;
}

.myTicketsChart .chart_title > p > span {
    font-size: 13px;
}

.myTicketsChart .chart_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #0162d0;
    border-radius: 50%;
}

.myTicketsChart .chart_logo img {
    max-width: 25px;
    max-height: 25px;
}

.myTicketsChart .chart_footer {
    height: 82px;
}

.chartOrderLabels {
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 550px;
}

.chartOrderLabel {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.chartOrderLabel > span {
    font-size: 15px;
}

.chartOrderLabel__title {
    color: #AEB8C3;
}

.chartOrderLabel__value {
    color: rgb(78, 92, 110);
}

.chartOrderLabel__value--green {
    color: #28cd6a;
}

.chartOrderLabel__value--red {
    color: #ff5260;
}

.chartOpenOrders {
    padding: 20px;
    margin-top: 20px;
}

.chartOpenOrders__titles, .listOfOpenOrders__item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0,0,0,0.08);
    margin-bottom: 10px;
}
.listOfOpenOrders__item button {
    width: 69px;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #0162d0;
    font-size: 12px;
    margin: 3px;
    padding: 0;
    cursor: pointer;
}

.listOfOpenOrders__item > span,
.chartOpenOrders__titles > span {
    width: calc(100% / 6);
    font-size: 15px;
    letter-spacing: 0.5px;
    color: rgb(78, 92, 110);
}

.listOfOpenOrders {
    padding: 0;
    list-style: none;
    margin: 0;
    max-height: 150px;
    overflow-y: scroll;
}

.listOfOpenOrders__item--noItems {
    color: #AEB8C3;
    text-align: center;
    font-size: 20px;
    margin: 30px 0 20px;
}