.myTicketsTradeHistory {
    padding: 20px;
    max-height: 600px;
    width: 60%;
}

.myTicketsHistoryBlock--personalHistory > .myTicketsTradeHistory {
    width: 100%;
}

.myTicketsTradeHistoryHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(218, 225, 233);
    margin-bottom: 10px;
    padding-bottom: 5px;
}

.myTicketsTradeHistoryHeader h3 {
    font-size: 17px;
    letter-spacing: 1px;
    color: rgb(6, 103, 208);
    text-transform: uppercase;
    margin-bottom: 0;
}

.myTicketsTradeHistoryHeader button {
    border: none;
    background-color: rgb(6, 103, 208);
    color: #fff;
    padding: 3px 5px;
    margin: 0;
    border-radius: 5px;
    cursor: pointer;
    outline: none !important;
}

.myTicketsHistoryList {
    overflow-y: scroll;
    max-height: 460px;
    padding: 0;
    list-style: none;
}

.myTicketsHistoryList__title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(218, 225, 233);
    text-transform: uppercase;
    margin-bottom: 20px;
    color: rgb(78, 92, 110);
}

.myTicketsHistoryList__title > h3,
.myTicketsHistoryItem > div {
    width: calc(100% / 3);
    text-align: center;
}

.myTicketsHistoryBlock--personalHistory .myTicketsHistoryList__title > h3,
.myTicketsHistoryBlock--personalHistory .myTicketsHistoryItem > div {
    width: calc(100% / 5);
}

.myTicketsHistoryList__title > h3 {
    font-size: 14px;
    letter-spacing: 1px;
}

.myTicketsHistoryItem {
    display: flex;
    justify-content: space-between;
}

.myTicketsHistoryItem:not(:last-child) {
    margin-bottom: 5px;
}

.myTicketsHistoryItem__price > span {
    position: relative;
    padding-right: 15px;
}

.myTicketsHistoryItem__price > span::before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 13px;
    height: 12px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.myTicketsHistoryItem__price--buy > span::before {
    transform: translateY(-50%) rotate(300deg);
    background-image: url("../../../../assets/images/arrow-green.svg");
}

.myTicketsHistoryItem__price--sell > span::before {
    transform: translateY(-50%) rotate(60deg);
    background-image: url("../../../../assets/images/arrow-red.svg");
}

.myTicketsHistoryItem__price--buy > span {
    color: #28cd6a;
}

.myTicketsHistoryItem__price--sell > span {
    color: #ff5260;
}

.myTicketsHistoryItem__time {
    color: rgb(78, 92, 110);
}