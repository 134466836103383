.withDraw-flex {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
}


.myTickets--title{
    display: flex;
    position: relative;
    padding: 0px !important;

    &.token::before{
        left: 0 !important;
    }

    &.token::after{
        right: 0 !important;
    }


    &.pair::before{
        left: 50% !important;
    }

    &.pair::after{
        right: 50% !important;
    }


    &::before, &::after {
        content: '';
        position: absolute;
        width: 50%;
        height: 10px;
        border-radius: 5px;
        z-index: 1;
        -webkit-transition: all .4s ease;
        transition: all .4s ease;
    }

    &::before {
        top: 0;
        left: 0;
        border-top: 1px solid #0162d0;
    }
    &::after {
        bottom: 0;
        right: 0;
        border-bottom: 1px solid rgb(218, 225, 233);
    }

}
.btn-tab {
    position: relative;
    width: 50%;
    border: none;
    border-radius: 5px;
    color: rgba(125, 149, 182,0.5);
    text-transform: uppercase;
    cursor: pointer;
    outline: none!important;
    background-color: rgba(6, 103, 208,0.03);
    font-size: 17px;
    text-align: center;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 10px;
    letter-spacing: 1px;
    -webkit-transition: background-color, color .4s ease;
    transition: background-color, color .4s ease;
    &.active {
        color: rgb(6, 103, 208);
        background-color: transparent;
    }
}

.myTokens_flex__block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08)
}

.myTokens_pairs__block {
    width: 100%;
    min-height: 80px;
    align-items: center;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    span {
        padding-bottom: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    //background-color: #0a6ffd;
    //img {
    //    width: 55px;
    //    height: 55px;
    //    border-radius: 30px;
    //    margin: 0 10px 0 10px;
    //    //padding: 5px;
    //    border: 2px solid #0064b4;
    //}
}

.myTokens_buttons__block {
    width: 35%;
    //background-color: #00AD7C;
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0;
    align-items: center;
    justify-content: flex-start;

    button {
        cursor: pointer;
        width: 40%;
        height: 30px;
        background-color: white;
        color:  #0064b4;
        min-width: 30px;
        margin: 0 1px;
        outline: none;
        border: 1px solid #0064b4;
        border-radius: 3px;
        font-weight: 400;
        font-size: 12px;
    }


}


.flex-clmn {
    //flex-direction: column !important;
    width: 245px !important;
    //background-color: #0b2c7e !important;
    padding: 0px;
    button {
        min-width: 62px;
    }

}