@import '../../assets/style/main/main.css';
@import '../../assets/style/base.css';
@import '../../assets/style/header.css';
@import '../../app/Components/Footer/Footer.css';

/* pages */
@import './pages/walletsPages.css';
@import './pages/projectInvestPage.css';
@import './pages/exchangePage.css';
@import './pages/homePage.css';
@import './pages/profilePage.css';
@import './pages/fanancers.css';
@import './pages/confirmPrincipalOrCollaborator.css';
@import './pages/ResetPassword.css';
@import './pages/CreatorPage.css';
@import './pages/myTickets.css';

/* components */
@import './Components/AddUserListContainer.css';
@import './Components/ImagePreview.css';
@import './Components/AdvertByCollectibles.css';
@import './Components/Pagination.css';
@import './Components/LoadingCard.css';
@import './Components/PopupWithProgressBar.css';
@import './Components/ControlPanel.css';
@import './Components/SlideshowModal.css';
@import './Components/DropDownList.css';
@import './Components/VerificationForm.css';
@import './Components/ShowProjectMediaFile.css';
@import './Components/ProofOfWorkPoll.css';
@import './Components/CommentsBlock.css';
@import './Components/MyTickets/myTicketsChart.css';
@import './Components/MyTickets/myTicketsListContainer.css';
@import './Components/MyTickets/myTicketsOrderForm.css';
@import './Components/MyTickets/BuyAndSellBar.css';
@import './Components/MyTickets/myTicketsTradeHistory.css';
@import './Components/MyTickets/historyBlock.css';
@import './Components/TradeHistory.css';