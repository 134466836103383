.profilePageWrapper {
    width: 900px;
    margin: 20px auto auto auto;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-radius: 10px;
}

.page {
    /*min-height: calc(100vh - 219px);*/
    /*min-height: 100vh;*/
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../../../assets/images/background-grey.png") !important;
    background-size: 300px;
}

@media screen and (max-width: 3200px) and (min-width: 1921px) {
    .page {
        background-size: 300px;
    }
}

@media screen and (min-width: 3200px) {
    .page {
        background-size: 300px;
    }
}


.backgroundCoverImage {
    width: 100%;
    height: 400px;
    position: relative;
    margin: 0 auto;
    background: white;
    border-radius: 10px;
}

.backgroundCoverImage img {
    width: 100%;
    height: 80%;
    object-fit: cover;
    object-position: center;
    background: #1e478d;
    border-radius: 10px 10px 0 0;
}

.deleteBttn {
    color: #fc3466;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.6px;
    cursor: pointer;
}

.profileImage {
    width: 160px;
    height: 160px;
    background: white;
    padding: 3px;
    border-radius: 3px;
    z-index: 2;
    position: relative;
    margin-top: -100px;
    margin-left: 20px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0 1px 1px rgba(31, 45, 61, 0.16);
    cursor: pointer;
}

.profileImage img {
    width: 100%;
    height: 153px;
    object-position: center;
    object-fit: cover;
    box-shadow: none;
    border-radius: 10px;
}

.profileImage:hover .onHoverProfileSettings {
    visibility: visible;
    opacity: 1;
}

.onHoverProfileSettings {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: 0.4s all;
}

.onHoverProfileSettings a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white !important;
    font-size: 23px;
}

.sideBttn {
    position: absolute;
    right: 20px;
    bottom: 25px;
    cursor: pointer;
    z-index: 2;
    background: white;
}

.sideBttn a {
    background: rgba(255, 255, 255, 0.9);
    font-size: 10px;
    padding: 8px 17px 5px;
    font-weight: 600;
    cursor: pointer;
    letter-spacing: 0.3px;
    box-shadow: 0 0 1px 1px rgba(31, 45, 61, 0.1);
    border-radius: 4px;
    background: white;
}

.profileImageBox .username {
    text-align: center;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 20px;
    padding-top: 30px;
}

.profileImageBox .email {
    text-align: center;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 15px;
    padding-top: 0px;
}

.basicDetails {
    padding-left: 30px;
    padding-top: 40px;
    padding-bottom: 50px;
    width: 50%;
    float: left;
    padding-right: 30px;
}

.bTitle {
    display: block;
    padding-bottom: 20px;
    font-size: 15px;
    color: #3c4858;
    font-weight: 900;
    letter-spacing: .5px;
}

.projectLogo {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.basicDetails label {
    display: block;
    width: 100%;

}

.basicDetails label span {
    display: inline-block;
    width: 20%;
    padding-bottom: 20px;
    font-size: 15px;
    color: #3c4858;
    font-weight: 400;
    vertical-align: middle;
    letter-spacing: .5px;

}

.basicDetails label input, .basicDetails label textarea {
    width: 80%;
    border: 0;
    -webkit-box-shadow: inset 0 0 0 1px #e0e6ed;
    box-shadow: inset 0 0 0 1px #e0e6ed;
    font-size: 14px;
    padding: 12px;
    outline: none;
    font-weight: 400;
    letter-spacing: .6px;
    color: #3c4858;
    border-radius: 4px;
    -webkit-transition: all .2s;
    -o-transition: .2s all;
    transition: all .2s;
    resize: none;
    vertical-align: middle;
}


.basicDetails label textarea {
    width: 100%;
}

.profileDetails {
    width: max-content;
    padding: 5px 20px;
    border-radius: 4px;
    z-index: 123;
    background: transparent;
    position: absolute;
    left: 200px;
    right: 0;
    bottom: -8px;
}

.profileImageCover {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.profileDetails .fullname {
    color: black;
    font-size: 25px;
    letter-spacing: 0.5px;
    font-weight: 600;
}

.profileDetails .userEmail {
    color: black;
    font-size: 15px;
    letter-spacing: 0.8px;
    font-weight: 300;
}

.changeCoverBttn {
    position: absolute;
    right: 145px;
    bottom: 22px;
    background: rgb(6, 103, 208);
    color: white !important;
    font-size: 12px;
    font-family: Trebuchet MS;
    padding: 5px 10px;
    font-weight: 600;
    cursor: pointer;
    z-index: 2;
    box-shadow: 0px 0 1px 1px rgba(31, 45, 61, 0.1);
    transition: 0.3s all;
    letter-spacing: 0.6px !important;
    border-radius: 4px;
    fill: rgb(125, 149, 182);
}

.messageThem {
    background: rgba(255, 255, 255, 0.9);
    font-size: 10px;
    padding: 5px 10px;
    font-weight: 600;
    cursor: pointer;
    letter-spacing: 0.3px;
    cursor: pointer !important;
    z-index: 123123;
    box-shadow: 0px 0 1px 1px rgba(31, 45, 61, 0.1);
    margin-top: 4px;
}

.profileSubHed {
    position: relative;
    z-index: 123;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    background: white;
    border-radius: 0 0 10px 10px;
}

.profileSubHed ul {
    list-style: none;
    margin-left: 150px;
}

.profileSubHed ul li {
    display: inline-block;
    padding: 13px 18px;
    color: gray;
    font-weight: 600;
    font-size: 13px;
    color: rgba(37, 103, 229, 0.3);
    cursor: pointer;
}

.profileSubHed ul a.active li {
    color: rgba(37, 103, 229, 1);
}

.profileContentBox {
    width: 50%;
    box-shadow: 0 0 1px 1px rgba(31, 45, 61, 0.1);
    background: white;
    margin-top: -5px;
    float: left;
    border-radius: 10px;
    margin-bottom: 10px;
    min-height: 400px;
}

.my-profile__wrapper {
    margin-bottom: 20px;
}

.profileHeaderT {
    background: #fff;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 10px 10px 0 0;

}

.profileHeaderT .title {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
}

.profileContent {
    padding: 20px;
}

.friendsBoxWrapper {
    padding-bottom: 0;
}

.friendsBoxWrapper ul {
    list-style: none;
    margin-left: -40px;
}

.friendsBoxWrapper ul li {
    border: 1px solid rgba(0, 0, 0, 0.05);
    width: calc(100%);
    height: 100px;
    margin-right: 10px;
    display: inline-block;
    margin-bottom: 15px;
    vertical-align: top;
}

.friendsBoxWrapper ul li:nth-of-type(2n) {
    margin-right: 0;
}

.friendsBoxWrapper ul li img {
    width: 98px;
    height: 98px;
    float: left;
    object-fit: cover;
    object-position: center;
}

.friendsBoxWrapper ul li .name {
    float: left;
    padding-left: 15px;
    vertical-align: middle;
    height: 98px;
    color: #2567e5;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: 600;
    padding-top: 38px;
}

.actionFriendBttn {
    background: #f3f3f3;
    font-weight: 500;
    font-size: 12px;
    padding: 3px 10px;
    float: right;
    margin-right: 10px;
    margin-top: 40px;
    letter-spacing: 0.3px;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.saveBttn.saved {
    background: #28cd6a;
    color: white;
}

.actionFriendBttn i {
    padding-right: 7px;
    color: gray;
}

.myProfileblow {
    border-radius: 0 0 10px 10px;
}

.prfl-myProfileblow {
    display: flex;
    justify-content: center !important;
}

.aboutmeLeftSide {
    width: 27%;
    float: left;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 20px;
    padding-left: 0;
}

.aboutmeRightSide {
    width: 55%;
    float: left;
    padding: 20px;
}

.aboutmeLeftSide ul {
    list-style: none;
    margin-left: -40px;
}

.aboutmeLeftSide ul li {
    font-weight: 400;
    font-size: 14px;
    color: #90949c;
    margin-bottom: 20px;
    padding: 3px 15px;
    cursor: pointer;
}

.aboutmeLeftSide ul li.active {
    border-left: 3px solid #162862;
    color: black;
    font-weight: 600;
}

.textRowTitle {
    text-transform: uppercase;
    color: gray;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
}


.textRow {
    margin-bottom: 20px;
}

.textRow textarea, .textRow input {
    width: 100%;
    margin-top: 10px;
    border: 0;
    resize: none;
    box-shadow: 0px 0 1px 1px rgba(31, 45, 61, 0.2);
    color: black;
    font-size: 13px;
    padding: 10px;
    outline: none;
}

.textRow textarea::placeholder {
    color: gray;
}

.textRow input {
    margin-bottom: 5px;
}

.saveBttn {
    background: #162862;
    padding: 5px 10px;
    color: white !important;
    border-radius: 2px;
    font-size: 12px;
    cursor: pointer;
    letter-spacing: 1px;
    margin-top: 3px;
}

.contact30, .contact70 {
    color: gray;
    font-size: 14px;
    letter-spacing: 0.3px;
    font-weight: 600;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 30%;

}

.contact70 {
    width: 70%;
    color: black;
}


.leftSideProfile {
    float: left;
    width: 70%;
}

.rightSideProfile {
    width: 30%;
    float: left;
}

.rightSideContainer {
    background: white;
    margin-left: 10px;
    border: 1px solid #dddfe2;
    border-radius: 4px;
}

.postContent {
    border: 1px solid #dddfe2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: block;
    border-radius: 4px;
}

.postContent .postHeader {
    font-size: 13px;
    font-weight: 600;
    background-color: #e8ecf4;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 35px;
    border-bottom: 1px solid #dddfe2;
    color: #0275d8;
}

.upTrianglePost {
    width: 10px;
    border-bottom: solid 8px #fff;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
    margin-left: 55px;
    margin-top: -10px;
}


.postContent .postHeader ul {
    list-style: none;
    margin-left: -40px;
}

.postContent .postHeader ul .active {
    color: black;
}

.postContent .postHeader ul li {
    display: inline-block;
    padding-right: 15px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-right: 1px solid #dddfe2;
}


.postContent .postContainer {
    background-color: #fff;
    margin-top: 0px;
    padding: 20px;
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 5px;
}

.postContent .postContainer a {
    float: left;
    margin-right: 10px;
}

.postContainer .photos {
    display: none;
}

.postContainer .photos .photoCollection {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100px;
    height: 100px;
    background-color: #f3f3f3;
    margin-bottom: 5px;
    margin-left: 5px;
    float: left;

}


.postContainer .photos .photoAdd {
    border: 2px dashed lightgrey;
    width: 100px;
    padding: 40px;
    padding-top: 32px;
    padding-bottom: 20px;
    height: 100px;
    font-size: 25px;
    color: lightgrey;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: -2px;
}


.postTextArea {
    width: 85%;
    border: 0px;
    min-height: 100px;
    margin-bottom: 10px;
    outline: none;
    margin-top: -6px;
    font-size: 15px;
    transition: 0.2s all;
    overflow: hidden;
}

.postTextArea:empty:not(:focus):before {
    content: attr(data-placeholder)
}

.postFooter {
    background-color: white;
    padding: 10px;
    margin-left: -20px;
    margin-top: -15px;
    width: 100%;
}

.postFooter ul {
    list-style: none;
    margin-left: -40px;
}

.postFooter ul li a {
    display: inline-block;
    position: relative;
    background-color: #f6f7f9;
    padding: 20px;
    color: black;
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 30px;
    transition: 0.5s all;
}

.postFooter ul li:hover {
    background-color: lightgrey;
}

.finalPostButtons {
    clear: both;
    background-color: #f6f7f9;
    height: 39px;
    padding-right: 7px;
    color: white;
    cursor: pointer;
}

.finalPostButtons a {
    margin: 7px;
    padding: 3px;
    background-color: #0069b6;
    padding-left: 15px;
    padding-right: 15px;
    border: 0;
    font-size: 13px;
    font-weight: 500;
    border-radius: 2px;
    transition: 0.5s all;
}

.finalPostButtons a:hover {
    text-decoration: none;
    background-color: #183978;
}

.postWrapperContent {
    margin-top: 20px;
}


.postProfileImage, .profileImage1, .socialPost .title img {
    width: 40px;
    height: 40px;
    border-radius: 3px;
    object-position: center;
    object-fit: cover;
    border: 1px solid #dddfe2;
}


.socialPost {
    margin-top: 10px;
    border: 1px solid #dddfe2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
}

.socialPost .title {
    padding: 15px;
}

.socialPost .time {
    font-size: 12px;
    float: right;
}

.socialPost .title img {
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 15px;
    border: none !important;
}

.socialPost .comment {
    font-size: 12px;

}


.socialPostButton {
    float: right;
    font-size: 12px;
    color: grey;
}


.recogPostComment {
    background-color: #f6f7f9;
    padding: 15px;
    padding-top: 10px;
}

.recoPostingComment img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    float: left;
    margin-top: 10px;
}

.recoPostCommentInput, .recoPostingComment input {
    border: 0;
    font-size: 13px;
    margin-left: 10px;
    width: 90%;
    outline: none;
    padding: 5px;
    background-color: #fff;
    float: left;
    border-radius: 3px;
    font-weight: 400;
    margin-top: 10px;
}

.recoPostingComment input {
    width: 85.3%;
}


.fBttnComment {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 600;
    border-radius: 2px;
    margin-left: 4px;
    text-transform: uppercase;
    padding: 5px 15px;
    background: #2278ec;
    color: white !important;
    float: left;
    margin-top: 10px;
    cursor: pointer;
}


.recoPostCommentInput:empty:not(:focus):before {
    content: attr(data-placeholder);
    color: gray;

}

.recoPostingSection .comment {
    padding-top: 15px;
    font-size: 12px;

}

.recoPostingSection img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    float: left;

}

.recoPostingSection .name {
    font-weight: bold;
    font-size: 13px;
}


.postButtons {
    border-top: 1px solid #f3f3f3;
    margin-left: 15px;
    margin-right: 20px;
    padding: 10px;
    font-size: 13px;
}

.postButtons a {
    color: gray;
    text-decoration: none;
    transition: 0.1s all;
    font-weight: 600;
    cursor: pointer;
}

.postButtons a.activeLike {
    color: #0080ff !important;
    text-decoration: none;
    transition: 0.1s all;
    font-weight: bold;
}

.postButtons a.like:hover {
    color: black;
}

.postButtons a.like.activeLike:hover {
    color: #0080ff;
    text-decoration: none;
    transition: 0.1s all;
    font-weight: bold;
}

.postButtons a.activeCheer {
    color: #ff6666;
    text-decoration: none;
    transition: 0.1s all;
    font-weight: bold;
}


.postButtons a.cheers:hover {
    color: black;
}

.postButtons a.activeCheer:hover {
    color: #ff6666;
    text-decoration: none;
    transition: 0.1s all;
    font-weight: bold;
}

.likeCheers {
    float: right;
}

.likeCheers i:nth-of-type(1) {
    background-color: #ff6666;
    color: white;
    padding: 4px;
    border-radius: 30px;
    font-size: 11px;
    margin-top: -1px;
    position: absolute;
}

.likeCheers i:nth-of-type(2) {
    bacогkground-color: #0080ff;
    color: white;
    padding: 4px;
    border-radius: 30px;
    font-size: 11px;
    margin-top: -1px;
    margin-left: -13px;
    position: absolute;
}

.totalCountLikes {
    font-weight: 600;
    font-size: 14px;
}

.followingBttnDropdown {
    position: absolute;
    z-index: 123123123123;
    vertical-align: bottom;
    background: white;
    top: 30px;
    right: 0px;
    width: 150px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .1);
}

.followingBttnDropdown ul {
    list-style: none;
    margin-left: -40px;
    margin-bottom: 0;
}

.followingBttnDropdown ul li {
    padding: 10px 20px;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 11px;
    border-bottom: 1px solid lightgrey;
}

.followingBttnDropdown ul li:last-child {
    border-bottom: 0;
}


.messageComingSoon {
    width: 500px;
    height: 300px;
    text-align: center;
    padding: 30px;
    padding-top: 50px;

}

.messageComingSoon i {
    font-size: 40px;
    color: #28cd6a;

}

.messageComingSoon .title {
    text-align: center;
    font-weight: 300;
    padding-top: 10px;
    font-size: 40px;
    letter-spacing: 1px;
}

.messageComingSoon .description {
    text-align: center;
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 0.5px;
    padding: 20px;
    padding-top: 10px;


}

.user-info__wrapper {
    padding: 0;
    height: auto;
}


/*.messages-block_buttons {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    width: 100%;*/
/*}*/

.getPdf__block {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.getPdf__button {
    max-width: 200px;
    margin-bottom: 10px;
    background: rgb(6, 103, 208);
    color: white;
    font-size: 12px;
    font-family: Trebuchet MS, serif;
    padding: 5px 10px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 0 1px 1px rgba(31, 45, 61, 0.1);
    transition: 0.3s all;
    letter-spacing: 0.6px !important;
    border-radius: 4px;
    border: none;
}

.getPdf__button:focus {
    outline: none;
}


@media screen and (max-width: 900px) {
    .profilePageWrapper {
        width: 100%;
    }

    .leftSideProfile {
        width: 90%;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (max-width: 860px) {
    .aboutmeLeftSide {
        width: 35%;
    }

    .friendsBoxWrapper ul li {
        width: 100%;
    }

    .profileContentBox {
        width: 100%;
    }

    .backgroundCoverImage {
        height: 600px;
    }

    .backgroundCoverImage > img {
        height: 60%;
    }

    .profileImage {
        margin: -90px auto;
    }

    .profileDetails {
        position: relative;
        text-align: center;
        left: 0;
        top: 90px;
        width: 100%;
        padding: 5px 0;
        word-break: break-all;
    }

    .changeCoverBttn {
        right: auto;
        left: 50%;
        transform: translate(-50%, -40px);
    }

    .sideBttn {
        right: 50%;
        transform: translate(50%, -2px);
    }
}

@media screen and (min-width: 840px) {
    .profile-container {
        height: 100vh;
    }
}

@media screen and (max-width: 780px) {
    .leftSideProfile {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .recoPostingComment input {
        width: 75%;
    }
}

@media screen and (max-width: 530px) {
    .profileSubHed {
        padding-top: 45px;
    }

    .profileSubHed ul {
        display: flex;
        margin-left: -40px;
        text-align: center;
        padding-top: 100px;
    }

    .recoPostingComment input {
        width: 75%;
    }

    .aboutmeRightSide {
        padding-right: 0;
    }

    .contact30 {
        float: none !important;
        width: 100%;
    }

    .contact70 {
        width: 100%;
        float: none;
    }

    .contact30, .contact70 {
        font-size: 13px;
    }

    .aboutmeRightSide .inputGroups select {
        width: 100% !important;
        margin-bottom: 5px;
    }


    .friendsBoxWrapper ul li .name {
        font-size: 14px;
    }

    .socialInput {
        width: 80%;
        height: 60px;
        border: 1px solid rgba(0, 0, 0, 0.09);
        outline: none;
        border-radius: 3px;
        padding-left: 14px;
        padding-right: 15px;
        font-size: 20px;
        -webkit-appearance: none;
        letter-spacing: 1px;
        float: left;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }

    .socialVerify {
        border: 1px solid rgba(0, 0, 0, 0.09);
        -webkit-appearance: none;
        width: 20%;
        height: 60px;
        float: left;
        text-align: right;
        border-radius: 2px;
        outline: none;
        padding-left: 10px;
        background-color: white;
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        color: gray;
        font-weight: 900;
        padding-right: 30px;
        font-size: 17px;
        letter-spacing: 1px;
    }

    .porfolioDetails {
        padding-left: 15px;
        float: left;
        padding-top: 15px;
    }

    .aboutmeLeftSide ul li {
        font-size: 12px;
    }
}

@media screen and (max-width: 400px) {
    .backgroundCoverImage-longName {
        height: 700px;
    }
}






