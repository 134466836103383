.thumbnailChart {
  margin: auto;
  width: 100%;
  min-width: 100px;
  height: 95px;
  border-radius: 5px;
  border: solid 1px #e2e2e2;
  font-family: Graphik, "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.thumbnailChart p {
  margin-top: 0;
  margin-bottom: 0;
}

.thumbnailChart_header {
  padding-left: 5px;
  padding-top: 2px;
}

.thumbnailChart_logo {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-position: center;
  vertical-align: middle;
  background-image: url('/src/assets/images/f-in-blue1.png');
}

.thumbnailChart_title {
  padding-left: 5px;
  color: #2A394C;
  font-size: 14px;
}

.thumbnailChart_amount {
  color: #2A394C;
  font-size: 14px;
}

.thumbnailChart_delta {
  font-size: 10px;
  padding-left: 5px;
  vertical-align: middle;
}

.thumbnailChart_chart {
  padding: 0 5px;
  height: 25px;
}

.thumbnailChart_path {
  stroke-width: 15;
  fill: #fff;
}

.thumbnailChart_footer {
  background-color: #D9E1E9;
  text-align: center;
  font-size: 10px;
  color: #fff;
  width: 25px;
  float: right;
  padding: 0 3px;
  border-radius: 5px;
  margin-right: 5px;
}
