.error__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
}

.errorImg {
    max-width: 600px;
    max-height: 300px;
    margin: 0 auto;
}

.error-message__wrapper {
    max-width: max-content;
    margin: 0 auto;
    text-align: left;
    padding: 10px;
}

.error-message__oops {
    font-size: 25px;
}

.error-message__span {
    border-left: 5px solid red;
    margin-right: 5px;
}

@media screen and (max-width: 768px) {
    .errorImg {
        max-width: 400px;
    }
}

@media screen and (max-width: 460px) {
    .errorImg {
        max-width: 200px;
    }
}