.controlPanel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 15px;
    bottom: 10px;
    z-index: 1;
    border-radius: 20px;
    overflow: hidden;
    width: 37px;
    max-width: 111px;
    opacity: .4;
}

.controlPanel-internal {
    left: 3%;
    top: 30px;
    bottom: auto;
    transform: rotate(180deg);
    opacity: 0.99;
}

.controlPanel__delete-icon {
    transform: rotate(180deg);
}

.controlPanel,
.controlPanelActiveSetting,
.controlPanel__element--setting button {
    transition: all .3s ease;
}

.controlPanel {
    opacity: 50%;
}

.controlPanel:hover {
    width: 100%;
    opacity: 1;
}

.controlPanel-mobile:hover {
    width: 70px;
}

.controlPanel:hover .controlPanelActiveSetting {
    transform: translateX(0px);
    opacity: 1;
}

.controlPanel:hover .controlPanel__element--setting button {
    transform: rotate(180deg);
}

.controlPanel:hover .controlPanel__element--setting {
    border-radius: 0;
}

.imagePreviewFirst:hover .controlPanel,
.imagePreviewSecond:hover .controlPanel {
    opacity: 1;
}

.controlPanelActiveSetting {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(calc(100% - 37px));
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    border-radius: 20px;
    opacity: 0;
}

.controlPanel__element {
    padding: 7px 11px;
}

.controlPanel__element--setting {
    background-color: rgba(1, 98, 208, 0.7);
    transform: rotate(0deg);
    border-radius: 20px;
    transition: all .9s .3s ease;
}

.controlPanel__element button,
.controlPanel__element img {
    display: block;
}

.controlPanel__element button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    outline: none !important;
    border: none;
    cursor: pointer;
}

.controlPanel__element img {
    width: 100%;
    max-width: 15px;
}

.controlPanel--draggable div:not(.controlPanel__element--setting) {
    display: none;
}

.controlPanel--draggable:hover {
    width: auto;
}

.controlPanel--draggable:hover .controlPanelActiveSetting {
    transform: translateX(0px);
    opacity: 1;
}

.controlPanel--draggable:hover .controlPanel__element--setting button {
    transform: rotate(0deg);
}

.controlPanel--draggable:hover .controlPanel__element--setting {
    border-radius: 20px;
}