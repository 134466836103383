.header_top__wrapper {
    background-color: var(--primaryColor);
    padding: 12px 0;
}

.header-newMail-icon {
    width: 27px !important;
    height: 27px !important;
    margin-left: 10px;
    margin-top: -5px;
}

.header_top {
    display: flex;
    justify-content: space-between;
    width: 91%;
    margin: 0 auto;
    align-items: center;
}

.header_top__logo {
    width: 55px;
    max-height: 55px;
    cursor: pointer;
}

.header_top__links {
    display: flex;
    width: 350px;
    justify-content: space-between;
}

.header_top__links__item, .header_top__login__item {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 1px;
    transition: 0.3s;
    color: white;
}

.header_top__links__item:hover,
.header_top__login__item:hover,
.header_top__login_btn:hover {
    text-decoration: none;
    color: var(--ghostColor);
}

.header_top__login {
    display: flex;
    align-items: center;
}

.header_top__login_btn {
    padding: 13px 20px 12px;
    border-radius: 44px;
    background-color: var(--lightPrimaryColor);
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 1px;
    color: white;
    box-shadow: 0 1px 0 var(--darkPrimaryColor);
    margin: 0 10px;
    transition: 0.3s;
}

.header_top__menu {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header_top__menu__user-avatar,
.header__menu__dropdown__list__item__user-avatar {
    width: 34px;
    height: 34px;
    border-radius: 30px;
    background-position: center;
    background-size: cover;
    margin-right: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .24706);
    cursor: pointer;
}

.header_top__menu__user-name__wrapper {
    display: flex;
    align-items: center;
    padding: 8px 0;
}

.header_top__menu__user-name {
    color: white;
    font-size: 14px;
}

.header_top__menu__arrow-btn {
    width: 13px;
    height: 13px;
    margin-left: 10px;
    transition: 0.5s;
}

.header__menu__dropdown {
    box-shadow: 0 1px 4px rgba(0, 0, 0, .24706);
    position: absolute;
    width: 250px;
    top: 0;
    display: block;
    background-color: white;
    border-radius: 3px;
    z-index: 10;
    opacity: 1;
    margin-top: 62px;
    right: 1px;
}

.header__menu__dropdown_hide {
    display: none;
}

.header__menu__dropdown__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.header__menu__dropdown__list a:hover {
    text-decoration: none;
}

.header__menu__dropdown__list__header {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: 22px;
}

.header__menu__dropdown__list__item {
    width: 100%;
    color: rgb(78, 92, 110);
    height: 48px;
    padding: 12px 16px;
    font-size: 14px;
    cursor: pointer;
    text-align: left;
    font-weight: 400;
    letter-spacing: 0.3px;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    transition: 0.2s;
    display: flex;
    justify-content: space-between;
}

.header__menu__dropdown__list__item:hover {
    background-color: rgba(6, 103, 208, 0.05);
}

.header__menu__dropdown__list__item__user-avatar {
    margin: 0 auto;
    width: 60px;
    height: 60px;
    transition: 0.5s;
    cursor: pointer;
}

.header__menu__dropdown__list__item__user-avatar:hover {
    transform: scale(1.05);
}

.header__menu__dropdown__list__item__user-name {
    font-size: 17px;
    letter-spacing: 1px;
    padding-top: 10px;
    text-decoration: underline;
    color: var(--primaryColor);
    word-break: break-all;

}

.header__menu__dropdown__list__item__user-id {
    font-size: 13px;
    margin-top: 5px;
}

.header__menu__dropdown__list__item__user-email {
    font-size: 13px;
    color: var(--ghostColor);
    letter-spacing: 1px;
    margin-top: 5px;
    word-break: break-all;
}

@media screen and (max-width: 860px) {
    .header_top__wrapper {
        display: none;
    }

    .header__menu__dropdown {
        margin-top: 59px;
    }
}
