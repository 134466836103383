@keyframes loadingAnimation {
    0% {
        left: -100%;
    }
    50%  {
        left: 50%;
    }
    100% {
        left: 200%;
    }
}

.loadingCardHeader {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
}

.loadingCardHeader__box {
    width: 100%;
    max-width: 70px;
    height: 70px;
    margin-right: 5px;
}

.loadingCardRows {
    width: 100%;
}

.loadingCardRows div {
    width: 75%;
    height: 15px;
}

.loadingCardRows div:first-child {
    width: 100%;
}

.loadingCardRows div:last-child {
    width: 50%;
}

.loadingCardRows div,
.loadingCardHeader__box {
    position: relative;
    overflow: hidden;
    background-color: #e0e0e0;
    border-radius: 4px;
}

.loadingCardRows div::before,
.loadingCardHeader__box::before {
    position: absolute;
    content: '';
    left: -100%;
    top: 0;
    width: 50px;
    height: 100%;
    transform: skewX(-30deg);
    background: -moz-linear-gradient(left,  rgba(224,224,224,0.85) 0%, rgba(224,224,224,0.85) 1%, rgba(245,245,245,.5) 50%, rgba(224,224,224,0.85) 100%);
    background: -webkit-linear-gradient(left,  rgba(224,224,224,0.85) 0%,rgba(224,224,224,0.85) 1%,rgba(245,245,245,.5) 50%,rgba(224,224,224,0.85) 100%);
    background: linear-gradient(to right,  rgba(224,224,224,0.85) 0%,rgba(224,224,224,0.85) 1%,rgba(245,245,245,.5) 50%,rgba(224,224,224,0.85) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9e0e0e0', endColorstr='#d9e0e0e0',GradientType=1 );
    animation: loadingAnimation 1s linear infinite;
}

.loadingCardRows div:not(:last-child) {
    margin-bottom: 5px;
}

.loadingCardWrapper {
    width: 100%;
    padding: 15px;
}

.loadingCard > .loadingCardRows {
    margin-top: 30px;
}

.loadingCard {
    padding: 15px;
    background-color: #e8e8e8;
    border-radius: 4px;
    min-height: 200px;
}