.categoriesForInvestors {
    display: flex;
    flex-wrap: wrap;
}

.categoriesForInvestors > .column {
    display: flex;
    margin-bottom: 50px;
}

/* media queries */

@media screen and (max-width: 1300px) {
    .categoriesForInvestors > .column {
        width: 50%;
    }
}

@media screen and (max-width: 600px) {
    .categoriesForInvestors > .column {
        width: 100%;
    }
}

