.pagination {
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 290px;
    margin-right: auto;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 5px;
    border-radius: 10px !important;
}

.paginationNumbers {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 20px;
}

.paginationNumbers__number {
    padding: 2px 9px;
    color: black;
    cursor: pointer;
    border: 1px solid var(--primaryColor);
    border-radius: 4px;
    font-size: 13px;
    transition: 0.2s;
    background-color: white;
}

.paginationNumbers__number:not(:last-child) {
    margin-right: 5px;
}

.paginationNumbers__number:active, .paginationNumbers__number:focus {
    outline: none;
    outline-offset: 0;
}

.paginationNumbers__number:hover {
    background-color: var(--lightPrimaryColor) !important;
    color: white !important;
}

.paginationNumbers__number_selected {
    background-color: var(--primaryColor);
    color: white;
}

.first-loading {
    background: rgba(1, 98, 208, 1);
    color: white;
}

.pagination__navigation-btn_arrow {
    font-size: 16px;
    font-weight: bold;
    padding: 0 10px;
    line-height: 23px;
}

.paginationNumbers_number--active {
    background-color: #488bfe;
    color: #fff;
}

.pagination__arrow {
    max-width: 10px;
}

.pagination__arrow--left img {
    transform: rotate(180deg);
}

.pagination__arrow img {
    max-width: 100%;
    max-height: 100%;
}

.pagination__arrow--hidden {
    display: none;
}
