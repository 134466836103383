@import "../Collectibles/ScheduleAnAuction/index.css";
@import "../Calendar/index.css";
@import "../ModalWindow/index.css";

.dashWrapperB {
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    margin-bottom: -22px;
}

.dashModal:first-child {
    padding-left: 0;
}

.dashModal {
    width: 100%;
    padding: 0 20px;
    float: left;
    margin-top: 20px;
}

.collectibleTrades__block .tokens-content {
    min-height: 373px !important;
}

.create-wallet__wrapper {
    padding-left: 20px !important;
}

.right-dashModal {
    padding-right: 0 !important;
}

.dashModal.fifty {
    width: 50%;
}

.dashModal.third {
    width: 33.3333%;
}

.dashModal.twothird {
    width: 66.66666%;
}

.dashModal .content {
    box-shadow: rgba(0, 0, 0, 0.05) 0 1px 2px 0;
    border: 1px solid rgb(218, 225, 233);
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
}

.tokens-content {
    padding-bottom: 20px;
}

@-moz-document url-prefix() {
    .tokens-content {
        padding-bottom: -20px !important;
    }
    .ff-content {
        padding-bottom: 34px !important;
    }
}

.cHeader .title {
    color: rgb(6, 103, 208);
    letter-spacing: 0.4px;
    background-color: white;
    padding: 20px;
    font-size: 16px;
    border-bottom: 1px solid rgb(218, 225, 233);
    padding-bottom: 18px;
    font-weight: 400;
}


.tSWrap {
    padding: 15px;
}

.upperTSDash {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.upperTSDash ul {
    list-style: none;
    margin-left: -40px;
    margin-bottom: 0;
}

.upperTSDash ul li {
    display: inline-block;
    padding-right: 30px;
}

.upperTSDash ul li .title {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    color: rgb(6, 103, 208);
}

.upperTSDash ul li .subtitle {
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.5px;
    padding-top: 2px;
    color: black;
}

.upperTSDash ul .pull-left {
    text-align: left;
    padding-right: 0;
}

.upperTSDash ul .pull-right {
    text-align: right;
    padding-right: 0;
}

.milestoneBarWrap {
    padding: 60px 10px 0 10px;
}

.addressTD {
    font-size: 10px !important;
}

.milestonebar {
    background-color: rgba(125, 149, 182, 0.1);
    width: 100%;
    border-radius: 4px;
    height: 50px;
    overflow: hidden;
}

.activeBar {
    width: 0%;
    background-color: rgb(6, 103, 208);
    color: white;
    height: 50px;
    text-align: center;
    color: #fff;
    font-family: Trebuchet MS;
    font-size: 18px;
    padding-top: 12px;
    font-weight: bold;
    transition: 0.3s all;

}

.transTable {
    overflow: hidden;
}

.transTable table {
    border-collapse: collapse;
    width: 100%;
}

.transTable table thead tr {
    border-bottom: 1px solid rgb(218, 225, 233);
}

.transTable table thead tr th:first-child {
    padding-left: 20px;
    width: 110px;
}

.transTable table thead tr th {
    font-weight: 800;
    color: #333;
    font-size: 13px;
    padding: 10px;
    padding-left: 0;
    border-bottom: 1px solid #e5ecf4;
}

.transTable table thead tr th:nth-of-type(2) {
    text-align: left;
    padding-right: 0;
    width: 50px;
}

.transTable table thead tr th:nth-of-type(3) {
    width: 10px;
}

.transTable table thead tr th:nth-of-type(4) {
    text-align: left;
}


.transTable table tbody tr td:nth-of-type(1) {
    padding-left: 20px;
}


.transTable table tbody tr td:nth-of-type(2) {
    text-align: left;

}

.transTable table tbody tr td:nth-of-type(3) {
    width: 20px;
    text-align: center;
}

.transTable table tbody tr td:nth-of-type(4) {
    text-align: left;
    padding-left: 0;
    color: black;
}

.transTable table thead tr th:last-child {
    text-align: right;
    padding-right: 20px;
}

.transTable table tbody tr td:last-child {
    text-align: center;
    padding-right: 0;
    width: 55px;
    cursor: pointer;
}


.transTable table thead tr th:nth-of-type(6) {
    text-align: right;
    padding-right: 0;
}

.transTable table tbody tr td:nth-of-type(6) {
    text-align: right;
    padding-right: 0;
}


.transTable table tbody tr td {
    padding-top: 14px;
    padding-bottom: 13px;
    font-size: 12px;
    color: gray;
    letter-spacing: 0.3px;
}

.transTable table tbody tr {
    border-bottom: 1px solid #e5ecf4;
}

.transTable table tbody tr:last-child {
    border-bottom: 0;
}

.arrowTransTable {
    fill: #6100ff;
}

.transTableActions a {
    cursor: pointer;
}

.transTableActions a span {
    font-size: 10px;
}

.transTableActions a:hover {
    text-decoration: none;
}

.transTableActions .cancelBtn {
    padding-left: 10px;
}

.transTableActions .cancelBtn img {
    width: 13px;
}


.timerTD {
    letter-spacing: 1px !important;
}


.portfolioSummaryWrap {
    width: 200px;
    margin: 0 auto;
    padding-top: 50px;
    position: relative;
}

.pShoriz .protfoilioWrapper {
    float: left;
    width: 40%;
}


.pShoriz .portfolioSummaryWrap {
    padding-top: 50px;
    width: 200px;
    margin: 0 auto;
    position: relative;
}

.pieID {
    display: inline-block;
    width: 100%;
}

.pie {
    height: 200px;
    width: 200px;
    position: relative;
}

.pie::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    width: 150px;
    height: 150px;
    background: #fff;
    border-radius: 50%;
    top: 26px;
    left: 26px;
}

.pie::after {
    content: "";
    display: block;
    width: 120px;
    height: 2px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    box-shadow: 0 0 3px 4px rgba(0, 0, 0, 0.1);
    margin: 220px auto;
}

.slice {
    position: absolute;
    width: 200px;
    height: 200px;
    clip: rect(0, 200px, 200px, 100px);
    animation: bake-pie 1s;
}

.slice span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    clip: rect(0, 200px, 200px, 100px);
}

.collectible-portfolio .collectible-portfolio-body {
    min-height: 334px;
    background-color: #FFFFFF;
}

.collectible-portfolio .tickets-portfolio-body {
    min-height: 315px;
    background-color: #FFFFFF;
}

.collectible-portfolio .pShoriz .legend {
    width: 100%;
    padding-top: 0;
}

.pShoriz .legend {
    float: left;
    width: 60%;
    padding-top: 0;
    display: flex;
    flex-flow: row wrap;
}

.collectible-portfolio .pShoriz .legend li {
    padding: 42px 5px 41px 5px;
    max-height: 164px;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.pShoriz .legend li {
    padding: 42px 5px 41px 5px;
    max-height: 176px;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.collectible-portfolio .legend li:nth-of-type(1),
.legend li:nth-of-type(1) {
    border-top: 0;
}

.collectible-portfolio .legend li:nth-of-type(2),
.legend li:nth-of-type(2) {
    border-top: 0;
}

.collectible-portfolio .legend li:nth-of-type(3),
.legend li:nth-of-type(3) {
    border-top: 0;
}

.collectible-portfolio .pShoriz .legend,
.legend {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background: #FFF;
    font-size: 13px;
    text-align: center;
}

.dayTimerLeft {
    background-color: #1992fa;
    position: absolute;
    bottom: 25%;
    left: 0;
    right: 0;

}

.dayTimerLeft ul {
    margin-bottom: 0;
}

.dayTimerLeft ul li {
    color: white;
    font-size: 20px;
    font-weight: 400;
    width: 25%;
    padding-bottom: 0;
    padding-top: 20px;
    margin-bottom: 0 !important;
    border-bottom: 0 !important;
    float: left;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-right: 1px solid white;
}

.dayTimerLeft ul li span {
}

.dayTimerLeft ul li:last-child {
    width: 75% !important;
}

.react-cntdwn-hour, .react-cntdwn-minute, .react-cntdwn-second {
    width: 33.333%;
    float: left;
    font-size: 18px !important;
}

.react-cntdwn-second {
    border-right: 0;
}


.collectible-portfolio .legend li,
.legend li {
    padding: 42px 5px 41px 5px;
    max-height: 176px;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.legend li {
    width: 33.3333%;
}

.collectible-portfolio .legend li {
    width: 20%;
}

.collectible-portfolio .legend li:nth-of-type(3) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.collectible-portfolio .legend li:nth-of-type(6) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.legend li:nth-of-type(3) {
    border-right: 0;
}

.legend li:nth-of-type(4) {
    border-bottom: 0;
}

.legend li:nth-of-type(5) {
    border-bottom: 0;
}

.legend li:nth-of-type(6) {
    border-bottom: 0;
    border-right: 0;
}

.circleL {
    width: 25px;
    height: 25px;
    border-radius: 30px;
    display: block;
    margin: 0 auto 15px;
    background-color: #0162d0;
    color: white;
    padding: 2px 0 0;
}

.logo-small {
    width: 25px;
    height: 25px;
    border-radius: 30px;
    display: block;
    margin: 0 auto 15px;
    padding: 2px 0 0;
}

.circleL img {
    width: 16px;
    margin-top: -2px;
}

.ethereumLogo {
    width: 23px !important;
}

.bitcoincash {
    transform: rotate(-30deg);
}


.legend li .title {
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    color: gray;
    letter-spacing: 1px;
    padding-top: 5px;
}


.dataPointH {
    display: none;
}

.legend li .dataPoint {
    font-size: 13px;
    font-weight: 800;
    letter-spacing: 1px;
    padding-top: 10px;
}


.activeBarSend b {

    color: rgb(128, 0, 255);
}


.sentAmount b {
    color: rgb(254, 84, 97);
}

.errorTrans {
    font-weight: bold;
    color: rgb(254, 84, 97);
}

.portfolioItem,
.collectibleItem {
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    height: 80px;
    align-items: center;
    padding: 10px 25px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08)
}

.collectibleItem {
    word-break: break-word;
    cursor: pointer;
}

.highlight {
    background-color: rgba(0, 0, 0, 0.05);
}

.portfolioItem:nth-child(10),
.collectibleItem:nth-child(10) {
    border: none;
}

.portfolioItem .buyNowBttn {
    margin: 0;
}

.portfolioItem .porfolioDetails {
    padding-top: 0;
}

.portfolioItem .logo,
.collectibleItem .logo img,
.collectibleItem .logo {
    width: 40px;
    border-radius: 50%;
    height: 40px;
    object-fit: cover;
}

.portfolioItem .logo {
    display: flex;
    justify-content: center;
}

.portfolioItem .logo img {
}

.collectibleItem .porfolioDetails {
    padding: 0 15px;
}

.collectibleItem .buyNowBttn {
    margin: 0;
}


.collectibleItem .buyNowBtn-section {
    display: flex;
    width: 100%;
    max-width: 145px;
    flex-flow: row;

}

.btn_section-right {
    width: 50%;

}

.btn_section-right input {
    width: 80%;
    margin: 3px !important;

}

.porfolioTable {
    -webkit-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    /*	height: 340px;
        overflow-y: scroll;*/
    position: relative;
}

.porfolioTable ul {
    list-style: none;
    margin-left: -40px;
}

.porfolioTable ul li {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-left: 25px;
    padding-right: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    display: block;
    height: 70px;
}

.porfolioTable ul li .new-logoP,
.porfolioTable ul li .logoP {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    margin-top: 17px;
    text-align: center;
    z-index: -1;
}

.porfolioTable ul li .logoP {
    padding-top: 7px;
}

.new-logoP img,
.logoP img {
    width: 40px;
}


.logoP.bitcoin img {
    width: 23px;
}


/* .logoP.fnb {
	background-color: rgb(6, 103, 208);
} */
.logoP.bitcoin {
    background-color: #FFA500;
}

.logoP.bitcoincash {
    background-color: rgb(141, 196, 81);
}

.logoP.bitcoincash img {
    width: 23px;
    transform: rotate(-20deg);
}


.logoP.ethereum {
    background-color: rgb(111, 124, 186);
    padding-top: 3px !important;
}

.logoP.ethereum img {
    width: 35px;
}

.logoP.litecoin {
    background-color: rgb(181, 181, 181);
}

.logoP.litecoin img {
    width: 21px;
    padding-top: 0 !important;
}

.logoP.ripple {
    background-color: #35a0e0;
}

.logoP.ripple img {
    width: 21px;
    padding-top: 0 !important;
    text-align: left !important;
    margin-left: -3px;

}


.porfolioDetails {
    padding-left: 15px;
    float: left;
    padding-top: 15px;
}

.porfolioDetails span {
    display: block;
    font-size: 17px;
    color: rgb(78, 92, 110);
    letter-spacing: 0.5px;
}

.porfolioDetails span:last-child {
    font-size: 12px;
}

.porfolioTable ul li:last-child {
    border-bottom: 0;
}

/* .fanbaseColor {
	background-color: rgb(6, 103, 208);
} */


.bgCoverDiv {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    text-align: center;

}

.bgCoverDiv p {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 15px;
    width: 250px;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    color: rgb(6, 103, 208);
}


.openTrans {
    color: rgb(77, 138, 255) !important;
}

.errorAmount {
    color: rgb(254, 84, 97) !important;
}

.errorTrans {
    color: rgb(254, 84, 97) !important;
}

.completeTrans {
    color: rgb(20, 191, 6) !important;
}

.totalAmountPie {
    position: absolute;
    top: 53%;
    left: 0;
    text-align: center;
    right: 0;
    z-index: 123123123;
    bottom: 0;
    font-size: 16px;
    font-weight: bold;
    margin: 0 auto;
}

.buyNowBttn {
    background-color: rgb(6, 103, 208);
    display: inline-block;
    color: white !important;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 5px 10px;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
    margin-top: 23px;
}

.buyNowBttn.deposit {
    border: 1px solid #00C57F;
    color: #00C57F !important;
    background-color: white;
    margin-right: 5px;
}

.buyNowBttn.withdraw {
    border: 1px solid red;
    color: red !important;
    background-color: white;
    margin-right: 5px;
}

.buyNowBttn.tradeBttn {
    border: 1px solid rgb(6, 103, 208);
    color: rgb(6, 103, 208) !important;
    background-color: white;
    text-decoration: none !important;
}


.emptyTransactions {
    background-color: #FFFFFF;
    min-height: 314px;
}

.rightWrapperSide .emptyTransactions {
    min-height: 298px;
}

.emptyTransactions .title {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding-top: 140px;
}

.emptyTransactions .descr {
    text-align: center;
    font-size: 13px;
    font-weight: 300;
    color: gray;
    letter-spacing: 0.5px;
    padding-top: 5px;
    padding-bottom: 40px;
}

.emptyTransactions a {
    background-color: rgba(6, 103, 208, 1);
    color: white !important;
    padding-top: 11px;
    padding-bottom: 11px;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 12px;
    cursor: pointer;
    margin: 0 auto;
    width: 100px;
    display: block;
    text-align: center;
    border-radius: 3px;
}

.emptyTransactions a:hover {
    text-decoration: none;
}


.allTransactions {
    height: auto;
}

.OpenTransactionsTablea {
    background-color: #FFFFFF;
    min-height: 314px;
    overflow: hidden;
    position: relative;
}

.OpenTransactionsTablea.collectible {
    min-height: 298px;
}

.allTransactions .currency {
    width: 100px !important;
    text-align: left !important;
}

.completeTransactionWrap {
    padding-top: 30px;
}

.smallUppercaseTitle {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;

    text-transform: uppercase;
    font-size: 12px;
    padding-top: 30px;
}

.bigTitle {
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.3px;
    color: rgba(6, 103, 208, 1);
    font-size: 35px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.bigTitle.red {
    color: rgb(254, 84, 97);
}

.bigTitle.gray {
    color: gray;
}

.smallUppercaseTitle.gray {
    color: gray;
}

.bigTitle.small {
    font-size: 15px;
}

.bigTitle.smalldescription {
    font-size: 11px;
    margin-top: -10px;
    padding-bottom: 25px;
    color: black;
}

.svgQRCODE {
    padding-top: 10px;
    text-align: center;
    padding-bottom: 10px;
}

.totalTimer {
    text-align: center;
    padding-top: 10px;
    font-size: 25px;
    font-weight: 200;
    padding-bottom: 10px;

}

.totalTimerWRap {
    padding-top: 8px;
}

.status {
    text-align: right !important;
    padding-right: 20px !important;
}

.openTrans {
    text-align: right !important;
    padding-right: 20px !important;
}

.errorTrans {
    text-align: right !important;
    padding-right: 20px !important;
}

.completeTrans {
    text-align: right !important;
    padding-right: 20px !important;
}


.typeTransaction.Deposit {
    color: rgb(20, 191, 6);
    width: 100px !important;
}

.typeTransaction.Withdraw {
    color: rgb(254, 84, 97);
    width: 100px !important;
}

.amount {
    width: 150px !important;
    text-align: left !important;
}

.viewallA {
    background-color: #0162d0;
    display: block;
    color: white !important;
    font-weight: 400;
    font-size: 11px;
    padding: 5px 10px;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
}

.overlayTimerCointDown {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
}


.loadingScreenSummary {
    padding-top: 50%;
    height: 540px;


}


.pShoriz .loadingScreenSummary {
    height: 334px;
    padding-top: 33%;
}


.areyousureModalBgCover {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12312312312123123;
}


.areyousureModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: auto;
    background-color: white;
    border-radius: 10px;
    z-index: 123123123121231231;
    text-align: center;
    padding: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.areyousureModal .closeScreenI {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid rgba(255, 0, 0, 0.5);
    margin: 0 auto;
    font-size: 30px;
    color: rgba(255, 0, 0, 0.5);
}

.areyoursureTitle {
    font-weight: 200;
    padding-top: 10px;
    color: red;
    letter-spacing: -0.5px;
    font-size: 40px;
    padding-bottom: 10px;
    padding-top: 40px
}

.areyoursureSubtitle {
    font-size: 16px;
    font-weight: 300;
    color: black;
    width: 300px;
    margin: 0 auto;
    padding-top: 5px;
    letter-spacing: 0.5px;
    padding-bottom: 80px;
}

.areyoursureSubtitle b {
    font-weight: 900;
}

.nevermindBttn {
    padding: 10px 15px;
    background-color: #f3f3f3;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 15px;
}


.nevermindBttn.confirmBttn {
    color: white !important;
    background-color: rgba(255, 0, 0, 0.5);
    margin-right: 0;
}

.mobileVersionShow {
    display: none;
}

.mobileWidthhundred {
    float: right;
}

.mobileWidthhundred span {
    float: left !important;
}

/*.PortfolioPageWrap .cBody {*/
/*min-height: 700px;*/
/*}*/

.PortfolioPageWrap {
    min-height: 778px;
}

.rightWrapperSide {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 0 20px;
    width: 60%;
    height: 819px;
}


.rightWrapperSide .historySummaryWRap {
    padding: 0 20px 20px 0 !important;
}

.schedule-show-btn {
    width: fit-content;
    /*max-width: 45%;*/
    padding: 0 10px;
    border: 1px solid #0162d0;
    border-radius: 3px;
    cursor: pointer;
    color: #FFFFFF;
    background-color: #0162d0;
    font-size: 11px;
    margin: 0 10px;
}


@media screen and (max-width: 1220px) {
    .OpenTransactionsTablea {
        width: 100% !important;
        overflow: hidden;
    }

    .transTable table {
        width: 100%;
    }

    .mobileVerisonHide {
        display: block;
    }
}


@media screen and (max-width: 1150px) {

    .rightWrapperSide {
        width: 65%;
    }

    .rightWrapperSide .PortfolioSummaryWrap, .rightWrapperSide .historySummaryWRap {
        width: 100% !important;
    }

    .PortfolioPageWrap {
        width: 35% !important;
    }

    .PortfolioSummaryWrap, .historySummaryWRap {
        width: 65% !important;
    }


    .mobileHide {
        display: none;
    }

    .porfolioDetails span {
        font-size: 14px;
    }

    .mywalletsWidget.porfolioTable ul li:first-child {
        /*padding-left: 10px !important;*/
        /*padding-right: 10px  !important;*/
        /*height: 130px !important;*/
        padding-top: 0;
    }

    .mywalletsWidget.porfolioTable ul li:first-child .logoP {
        float: left;
        /*margin-right: 10px;*/

    }

    .mywalletsWidget.porfolioTable ul li:first-child .porfolioDetails {
        /*float: none;*/
    }

    .mywalletsWidget.porfolioTable ul li:first-child .mobileWidthhundred {
        text-align: center;
        padding-top: 20px;
    }

    .mywalletsWidget.porfolioTable ul li:first-child .mobileWidthhundred span {
        float: none;
        margin: 0 auto;
        text-align: center;
    }

    .mywalletsWidget .porfolioDetails {
        padding-left: 8px;
    }

    .bgCoverDiv {
        top: 130px;
    }

    .mywalletsWidget.porfolioTable ul li:nth-of-type(2) .mobileWidthhundred span, .mywalletsWidget.porfolioTable ul li:nth-of-type(1) .mobileWidthhundred span {
        /*margin-right:5px;*/
    }

    .mobileVerisonHide {
        display: block;
    }

    .mobileWidthhundred {
        float: none;
    }

    .mobileWidthhundred span {
        float: none !important;
    }

    .collectibleItem .buyNowBttn {
        padding: 5px 0;
        margin: 2px;
        width: 100%;
        /*height: 500px;*/
        font-size: 11px;
    }

    .collectibleItem .buyNowBtn-section {
        /*display: flex;*/
        /*flex-flow: column!important;*/
        flex-direction: row;
        max-width: 76px;
    }
}

@media screen and (max-width: 1020px)
and (min-width: 850px) {
    .porfolioDetails span {
        font-size: 1.2vw;
    }
}

@media screen and (max-width: 1020px) {

    .pS {
        height: auto;
    }

    .pS .legend li {
        width: 50%;
        border: 0;
    }

    .rightWrapperSide .PortfolioSummaryWrap,
    .rightWrapperSide .historySummaryWRap,
    .rightWrapperSide .historySummaryWRap .transTable table {
        width: 100%;
        padding-left: 0;
    }

    .PortfolioSummaryWrap {
        margin-top: 20px !important;
        width: 65%
    }

    .historySummaryWRap, .historySummaryWRap .transTable table {
        width: 65%
        /*padding-left: 0;*/
    }

    .legend li .title {
        font-size: 10px;
    }

    .PortfolioSummaryWrap .pShoriz {
        width: 100%;
    }

    .mobileVerisonHide {
        display: block;
    }
}

@media screen and (max-width: 980px) {

    /*.TokenSummaryWrapper {*/
    /*padding-left: 5px;*/
    /*padding-right: 5px;*/
    /*}*/
    /*.TokenSummaryWrapper .upperTSDash {*/
    /*padding-left: 5px;*/
    /*padding-right: 5px;*/
    /*}*/
    /*.TokenSummaryWrapper .milestoneBarWrap {*/
    /*padding-left: 5px;*/
    /*padding-right: 5px;*/
    /*}*/
    /*.TokenSummaryWrapper .upperTSDash ul li {*/
    /*width: 33.3333% !important;*/
    /*text-align: left;*/
    /*}*/
    .tobeIssued {
        display: none !important;
    }

    /*.TokenSummaryWrapper .lowerTSDash ul li {*/
    /*width: 40% !important;*/
    /*text-align: left;*/
    /*}*/
    /*.TokenSummaryWrapper .lowerTSDash ul li:nth-of-type(2) {*/
    /*width: 20% !important;*/
    /*text-align: left;*/
    /*}*/
    /*.TokenSummaryWrapper .lowerTSDash ul li:nth-of-type(3) {*/
    /*text-align: right;*/
    /*}*/
    .mobileVerisonHide {
        display: block;
    }

    .mobileVersionShow {
        display: none;
    }
}

@media screen and (max-width: 1020px) {

    .PortfolioPageWrap {
        width: 100% !important;
        min-height: auto;
    }

    .historySummaryWRap,
    .PortfolioSummaryWrap {
        width: 100% !important;
        padding-left: 0;
    }

    .rightWrapperSide {
        width: 100%;
        padding: 0;
        height: auto;
    }

    .rightWrapperSide .historySummaryWRap {
        padding-top: 20px;
    }

    .PortfolioSummaryWrap .content {
        height: auto !important;
    }

    .mywalletsWidget.porfolioTable ul li:first-child, .mywalletsWidget.porfolioTable ul li:nth-of-type(2) {
        padding-left: 25px !important;
        padding-right: 25px !important;
        /* height: 75px !important; */
        padding-top: 0;
    }

    .mywalletsWidget.porfolioTable ul li:first-child .logoP, .mywalletsWidget.porfolioTable ul li:nth-of-type(2) .logoP {
        float: left;
        margin-top: 15px;
    }

    .mywalletsWidget.porfolioTable ul li:first-child .porfolioDetails, .mywalletsWidget.porfolioTable ul li:nth-of-type(2) .porfolioDetails {
        float: left;

    }

    .mywalletsWidget.porfolioTable ul li:first-child .mobileWidthhundred {
        text-align: right;
        padding-top: 20px;
    }

    .mywalletsWidget.porfolioTable ul li:first-child .mobileWidthhundred span {
        float: none;
        text-align: center;
        margin: 0 auto 0 5px;
    }

    .mywalletsWidget .porfolioDetails {
        padding-left: 8px;
    }

    .bgCoverDiv {
        top: 75px;
    }

    .mobileVerisonHide {
        display: none;
    }

    .mobileVersionShow {
        display: block;
        padding-left: 0;
    }

    .mobileWidthhundred {

    }

    .mobileHide {
        display: block !important;
    }

    .myWallets__block, .tokenOrders__block {
        margin-top: 0;
    }
}

@media screen and (max-width: 750px) {

    .indexPagePortofilo {
        width: 100% !important;
        padding-left: 0;
    }

    .TokenSummaryPorfolio .content {
        height: auto !important;
    }

    .indexPagePortofilo .pS .legend li {
        width: 25%;
        border: 0;
        padding-top: 0;
    }

    .TokenSummaryPorfolio {
        width: 100% !important;
        padding-left: 0;
    }

    .TokenSummaryWrapper {
        padding-left: 20px;
        padding-right: 20px;
    }

    .TokenSummaryWrapper .upperTSDash ul li {
        width: 20% !important;
        text-align: left;
        padding-right: 0 !important;
    }

    .TokenSummaryWrapper .upperTSDash ul li.pull-right {
        width: 35% !important;
        float: right;
        margin-top: 0;
        text-align: right;
    }

    .TokenSummaryWrapper .lowerTSDash ul li {
        width: 33.3333% !important;
        text-align: left;
        padding-right: 0 !important;
    }

    .upperTSDash ul li .subtitle {
        font-size: 14px;
    }

    .TokenSummaryWrapper .lowerTSDash ul li.pull-right {
        width: 30% !important;
        float: right;
        margin-top: 0;
        text-align: right;

    }

    .historySummaryWRap .transTable {
        overflow: scroll;
    }

    .historySummaryWRap .transTable table {
        width: 800px !important;
        padding-left: 0;
    }

    .mywalletsWidget.porfolioTable ul li {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .mywalletsWidget.porfolioTable ul li:first-child, .mywalletsWidget.porfolioTable ul li:nth-of-type(2) {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

}


@media screen and (max-width: 650px) {
    .TokenSummaryPorfolio .content {
        height: auto !important;
    }

    .TokenSummaryWrapper .upperTSDash {
        padding-left: 15px;
        padding-right: 15px;
    }

    .TokenSummaryWrapper .milestoneBarWrap {
        padding-left: 5px;
        padding-right: 5px;
    }

    .TokenSummaryWrapper .upperTSDash ul li {
        width: 33.3333% !important;
        text-align: left;
    }

    .TokenSummaryWrapper .upperTSDash ul li.pull-right {
    }

    .TokenSummaryWrapper .lowerTSDash ul li:nth-of-type(1) {
        width: 40% !important;
    }

    .indexPagePortofilo .pS .legend li {
        width: 33.3333%;
    }

    .indexPageWrap .dashWrapperB {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0;
    }

    .indexPagePortofilo {
        padding-top: 10px;
    }

    .portfolioSummaryWrap {
        display: block;
        width: 200px !important;
        margin: 0 auto;
        float: none !important;
        padding-left: 0 !important;
    }

    .pShoriz .legend {
        width: 100% !important;
        float: none !important;
    }

    .PortfolioSummaryWrap .pShoriz .legend li {
        width: 33.333% !important;
    }

    .pShoriz .protfoilioWrapper {
        float: none !important;
        width: 100%;
    }

}


@media screen and (max-width: 420px) {
    .mywalletsWidget.porfolioTable ul li:first-child .mobileWidthhundred {
        padding-top: 2px;
    }

    .mywalletsWidget.porfolioTable ul li:first-child .mobileWidthhundred span {
        margin-top: 3px;
    }

    .porfolioDetails span:last-child {
        font-size: 10px;
    }

}

@media screen and (max-width: 500px) {
    .indexPagePortofilo .pS .legend li {
        width: 50%;
    }

    .PortfolioSummaryWrap .pShoriz .legend li {
        width: 50% !important;
    }
}

.paginate {
    font-size: 13px;
    margin: -20px 30px 15px 0;
    position: absolute;
    bottom: 1px;
    right: 1px;
}

.paginate .paginate-space {
    padding-bottom: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /*width: 100%;*/
    list-style: none;
    margin: 5px 0 0 0;
    float: right;
}

.paginate .paginate-space .paginate-unit {

    padding-bottom: 20px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 15px !important;
    /*display: inline-block;*/
    width: 30px;
    text-align: center;
    cursor: pointer;
}

.paginate .paginate-space .active {
    background-color: #0162d0;
    color: white;
}

.paginate .paginate-space .disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.paginate .paginate-space .paginate-first {
    padding-right: 0;
}

.paginate .paginate-space .paginate-prev {
    margin-right: 40px;
    padding-right: 25px;
    text-align: right;
}

.paginate-space .paginate-unit {
    height: auto;
}

.portfolio-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 734px;
    background-color: #FFFFFF;
}

.my-wallets__block {
    margin-bottom: 20px;
}

.my-collectibles-body {
    min-height: 738px;
    position: relative;
}


.auctionTable.collectible {
    overflow: auto;
    height: 298px;
}

.auctionTable table thead tr th {
    background-color: #FFFFFF;
    font-weight: 800;
    color: #333;
    font-size: 13px;
    padding: 10px 20px;
    border-bottom: 1px solid #e5ecf4;
}

.auctionTable table thead tr {
    /*display: flex;*/
    /*width: 100%;*/
}

.auctionTable table thead tr,
.auctionTable table tbody tr,
.auctionTable table tbody,
.auctionTable table thead,
.auctionTable table {
    width: 100%;
}

.auctionTable table tbody tr.cursor {
    cursor: pointer;
}

.auctionTable table tbody tr td {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 12px 20px;
    font-size: 12px;
    color: gray;
    letter-spacing: 0.3px;
    border-bottom: 1px solid #e5ecf4;
}

.auctionTable .back-btn {
    min-width: 90px;
    cursor: pointer;
}

.auction_td-link {
    cursor: pointer;
    color: #0C48C8 !important;
    border-bottom: 1px solid #0C48C8;
}

.auction_td-link:hover {
    color: #0b2c7e !important;
    /*background-color: gray !important;*/
}

.auction_td-delete p {
    background-color: red;
    color: white;
    padding: 3px;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    min-width: 38px;
}

.auction_td-view p {
    background-color: #0C48C8;
    color: white;
    padding: 3px;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    min-width: 38px;
}



/* media queries */
@media screen and (max-width: 1500px) {
    .auctionTable table thead tr th,
    .auctionTable table tbody tr td {
        padding: 5px;
    }
}

@media screen and (max-width: 1300px) {
    .activeAuctionInfo {
        padding: 10px 0;
    }

    .propertyOfActiveAuctionItem__title {
        color: rgb(6, 103, 208);
    }

    .headerOfActiveAuctionInfo {
        display: block;
        margin-bottom: 10px;
    }

    .headerOfActiveAuctionInfo th:not(.back-btn) {
        display: none;
    }

    .headerOfActiveAuctionInfo .back-btn {
        width: 100%;
        display: block;
        max-width: 30px;
        min-width: 0;
        text-align: center;
        border-radius: 5px;
        margin: 0 10px 0 auto;
        border: none;
    }

    .activeAuctionInfo,
    .activeAuctionInfo tbody,
    .activeAuctionInfo tr,
    .activeAuctionInfo td {
        display: block;
    }

    td.propertyOfActiveAuctionItem {
        display: flex;
        justify-content: space-between;
    }

    .auction_td-delete.propertyOfActiveAuctionItem p {
        width: 100%;
        max-width: 110px;
        margin-left: auto;
    }

    .activeAuctionInfo .itemOfActiveAuction {
        position: relative;
        width: calc(100% - 20px);
        margin: 0 auto 20px;
        border-radius: 5px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
}

@media screen and (min-width: 1301px) {
    .propertyOfActiveAuctionItem__title {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    td.propertyOfActiveAuctionItem {
        flex-direction: column;
        align-items: flex-start;
    }
}


.portfolio-tab-header {
    display: flex !important;
    border-bottom: 1px solid rgb(218, 225, 233);
    cursor: pointer;


}

.portfolio-tab-header p {
    width: 20% ;
    border-right: 1px solid rgb(218, 225, 233);
    border-bottom: none !important;
    border-radius: 0 10px 0 0 !important;

}

.portfolio-tab-header-n-active {
    background-color: rgba(0, 0, 0, 0.05) !important;
    border-radius: 0 10px 0 0;
    transition-duration: .2s;
}
.portfolio-tab-header-active  {
    background-color: white;
    /*color: rgba(125, 149, 182, 0.5) !important;*/
    z-index: 3;
    transition-duration: .2s;
    box-shadow: rgba(0, 0, 0, 0.2) 2px -0.5px 5px 0px !important;
}

.portfolio-tab-header-n-active  {

    z-index: 1;
    color: rgba(125, 149, 182, 0.5) !important;
}

.portfolio-tab-header-right {

    margin-left: -10px;
    padding-left: 30px !important;
    width: calc(20% + 10px) !important;
}
