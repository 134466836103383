.projectsPage {
    min-height: calc(100% - 201px);
}

.global-projects-wrapper {
    padding: 50px;
}

.projects-wrapper .image-spinner-frame {
    height: auto;
    min-height: 600px;
    position: static;
    background-color: transparent;
}

.projects-wrapper .project-box {
    width: calc(25% - 11.5px);
    height: 300px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 20px;
    vertical-align: top;
    position: relative;
    overflow: hidden;
}

.projects-wrapper a:nth-of-type(4n) .project-box {
    margin-right: 0;
}

.project-box .box-body {
    height: calc(100% - 55px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.box-body img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.project-box .box-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.foot-title {
    font-weight: 600;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black !important;
}

.foot-project-type {
    background: #488bfe;
    text-transform: uppercase;
    border-radius: 30px;
    font-size: 10px;
    color: white;
    padding: 7px 10px 6px;
    font-weight: 600;
    border: 1px solid #488bfe;
}

.foot-project-type-auction {
    background-color: transparent;
    color: #488bfe;
    margin-right: 5px;
    margin-left: auto;
}

.section-title__projects-page {
    font-size: 30px;
    font-weight: 800;
    color: rgb(0, 0, 0);
}

.section-subtitle__wrapper {
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    width: 240px;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin: 0 auto;
    justify-content: space-between;
    box-shadow: 0 5px 10px 0 hsla(0, 0%, 0%, 0.3);
}

.section-subtitle__wrapper:first-child {
    margin-bottom: 40px;
}

.section-subtitle__wrapper button {
    margin: 0;
    padding: 5px 10px;
}

@media screen and (max-width: 1179px) {
    .projects-wrapper .project-box {
        width: calc(33.3333% - 11.5px);
    }

    .projects-wrapper a:nth-of-type(4n) .project-box {
        margin-right: 15px;
    }

    .projects-wrapper a:nth-of-type(3n) .project-box {
        margin-right: 0;
    }
}

@media screen and (max-width: 950px) {
    .global-projects-wrapper {
        padding-left: 20px;
        padding-right: 20px;
    }

}

@media screen and (max-width: 750px) {
    .projects-wrapper .project-box {
        width: calc(50% - 11.5px);
    }

    .projects-wrapper a:nth-of-type(3n) .project-box {
        margin-right: 15px;
    }

    .projects-wrapper a:nth-of-type(2n) .project-box {
        margin-right: 0;
    }
}


@media screen and (max-width: 550px) {
    .projects-wrapper .project-box {
        width: calc(100%);
        margin-right: 0;
    }
}








