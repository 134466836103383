@keyframes loadingAnimation {
    0% {
        left: -50%;
    }
    50%  {
        left: 50%;
    }
    100% {
        left: 150%;
    }
}

.progressPopupWrapper,
.progressPopup,
.popupProgressBar,
.popupProgressBar div {
    filter: none !important;
}

.progressPopupWrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 99;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    transition: opacity .2s linear;
}

.progressPopup {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 50%;
    top: 50%;
    opacity: 0;
    transform: translate(-50%, calc(-50% + 50px));
    z-index: 100;
    width: calc(100% - 30px);
    max-width: 550px;
    background-color: #fff;
    padding: 25px 50px;
    height: 240px;
    max-height: 100vh;
    border-radius: 3px;
    overflow: hidden;
    transition: opacity .2s linear, transform .2s linear;
}

.progressPopupWrapper--show {
    opacity: 1;
}

.progressPopupWrapper--show .progressPopup {
    opacity: 1;
    transform: translate(-50%, -50%);
}

.progressPopup__title {
    color: rgb(6, 103, 208);
    letter-spacing: 0.4px;
    background-color: white;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
}

.popupProgressBar {
    width: 100%;
    height: 50px;
    background-color: transparent;
    border-radius: 3px;
    overflow: hidden;
}

.popupProgressBar div {
    position: relative;
    background-color: #0162d0;
    height: 100%;
    border-radius: 3px;
    width: 0;
    transition: all .2s linear;
    overflow: hidden;
}

.popupProgressBar div:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 20%;
    height: 100%;
    max-width: 100px;
    background: -moz-linear-gradient(left,  rgba(1,98,208,0) 0%, rgba(255,255,255,0.5) 28%, rgba(255,255,255,0.5) 71%, rgba(1,98,208,0) 100%);
    background: -webkit-linear-gradient(left,  rgba(1,98,208,0) 0%,rgba(255,255,255,0.5) 28%,rgba(255,255,255,0.5) 71%,rgba(1,98,208,0) 100%);
    background: linear-gradient(to right,  rgba(1,98,208,0) 0%,rgba(255,255,255,0.5) 28%,rgba(255,255,255,0.5) 71%,rgba(1,98,208,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000162d0', endColorstr='#000162d0',GradientType=1 );
    animation: loadingAnimation 1s linear infinite;
}

.progressPopup__percent {
    font-size: 40px;
    text-align: center;
    color: rgba(0, 0, 0, .6);
}