.mobile-header__wrapper {
    background-color: var(--primaryColor);
    padding: 10px 0;
}

.mobile-header {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-header__menu-icon {
    width: 21px;
    max-height: 21px;
    cursor: pointer;
}

.mobile-header__menu {
    background-color: var(--deepDarkPrimaryColor);
    padding-left: 65px;
    position: absolute;
    top: 58px;
    left: 0;
    right: 0;
    z-index: 10;
    flex-direction: column;
    width: 100%;
}

.mobile-header__menu__item {
    color: white;
    display: flex;
    align-items: center;
    opacity: 0.8;
    padding: 15px 0;
    letter-spacing: 0.6px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    transition: 0.2s;
}

.mobile-header__menu__item:hover {
    opacity: 1;
    color: white;
    text-decoration: none;
}

.mobile-header__menu__item span {
    fill: white;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: left;
}

@media screen and (min-width: 860px) {
    .mobile-header__wrapper {
        display: none;
    }
}