.popup_royalty-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px auto;
    height: 100%;
}


.popup_royalty-container__line {
     display: flex;
     justify-content: space-between;
     border-left: 1px solid #0162d0;
     border-right: 1px solid #0162d0;

 }


.popup_royalty-container__line-title {
    display: flex;
    justify-content: space-between;
    border-left: 1px solid #0162d0;
    border-right: 1px solid #0162d0;

}

.blue-button-royalty {
    width: 150px;
    background-color: #0162d0 ;
    outline: none;
    border-radius: 20px;
    color: white;
    border: none;
    margin-bottom: 20px;
    padding: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.popup_royalty-container__cell {
    width: 230px;
    border-top: 0.5px solid #0162d0;
    border-bottom: 0.5px solid #0162d0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.popup_royalty-container__cell-title {
    background-color: #0162d0;
    color: white;
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.popup_royalty-container__cell-h2 {
    margin: 0 auto 20px auto;
    letter-spacing: 0.4px;
    font-size: 20px;
    font-weight: 400;
    /*margin: 0;*/
    color: #0667d0;
}