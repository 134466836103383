.DepositDetailsWrapper {
	padding:20px;
	padding-bottom: 0;
}


.DepositDetailsWrapper .title {
	text-transform: uppercase;
	font-weight: 900;
	font-size: 13px;
	padding-top: 5px;
}
.amountSendwrapper {
	width: 75%;
	padding-top: 20px;
	float: left;
}

.DepositDetailsWrapper .amountToSend {
	padding-left: 0;
	padding-right: 0px;
	margin-top: 10px;
	margin-bottom: 0;
	padding-bottom: 0;
}

.DepositDetailsWrapper .amountToSend .amountSelect {
	padding-right: 15px;
	height: 50px;
	font-size: 14px;
}
.timerCointdown {
	position: absolute;
	bottom: 25%;
	left: 0;
	right: 0;
	margin: 0 auto
}
.DepositDetailsWrapper .amountToSend input {
	height: 50px;
	font-size: 17px
}
.cancelAreyousure {
	color: black !important;
}
.amountWrapperDetails {
	margin-top: 0px;
}

.referenceNumberWrap {
	width: 100%;
	padding-left: 20px; 
}
.referenceNumber {
	font-size: 35px;
	font-weight: 200;
	margin-top: 10px;
}


.DepositDetailsWrapper .minimumAmount {
	padding-top: 0;
}
.DepositDetailsWrapper .USD {
	color: #00C57F;
}
.wiretransferDetails {
	list-style: none;
	margin-left: -40px;
	margin-bottom: 0;
	
}
.wiretransferDetailsWrap {
	
	margin-top: 5px;
	border:1px solid rgba(0,0,0,0.1);
}
.wiretransferDetailsWrap1 {
	border:1px solid rgba(0,0,0,0.1);
	padding: 10px;
}

.DepositDetailsWrapper .porfolioTable ul li {
	padding-left: 0;
	height: 40px;
}


.DepositDetailsWrapper .porfolioTable ul li .porfolioDetails {
	padding-top: 0;
}
.DepositDetailsWrapper .porfolioTable ul li .logoP {
	margin-top: 0;
}
.wiretransferDetailsWrap {
	padding: 20px;
	padding-right: 10px;
	padding-left: 10px;
	padding-top: 10px;
}
.wiretransferDetailsWrap .wiretransferDetails li{
	padding-top: 0;
	padding-bottom: 0;
}	
.wiretransferDetails li {
	display: inline-block;
	width:50%;
	padding-top: 10px;
	padding-bottom: 10px;

	vertical-align: top;
	letter-spacing: 0.5px;
	font-size: 13px;
	font-weight: 400;
	line-height: 26px;
}
.wiretransferDetails li p span {
	padding-left:5px;
}

.smallDetailsSwitft {
	font-size: 10px;
	margin-top: -5px;
}
.wiretransferDetailsWrap .title {
	padding-top: 10px;
}


.confirmDepositBttn {
    background-color: #00C57F;
    color: white !important;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 8px 15px;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
    margin-top: 54px;
    float: right;
    padding-top: 15px;
    font-weight: bold;
    padding-bottom: 15px;
}



.warningMessage {
	color: red;
	font-size: 12px;
}
.confirmDepositBttn.red {
	background-color: rgb(254, 84, 97);
}




.depositSuccessScreen {
	text-align: center;
	padding: 40px;
}
.wireTransferTitle {
	text-transform: uppercase;
	font-weight: 900;
	font-size: 13px;
	padding-top: 53px;	
}

.wireTransferConfirm {
	font-weight: 200;
	font-size: 35px;
}

.transferSuccess {
	color: #00C57F;
	font-size: 25px;
	padding-top:40px;
	font-weight: 800;
}

.transferSubtitle {
	width: 350px;
	font-size: 14px;
	margin: 0 auto;
	color: gray;
}

.emailWarning {
	font-size: 13px;
	color: gray;
	line-height: 25px;
}




