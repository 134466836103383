//main colors
$primary_blue: #0162d0
$primary_blue_dark: rgb(0, 100, 205)
$primary_blue_dark_btn: #0129b5
$primary_blue_dark_text: rgba(65, 131, 197, 1)
$primary_blue_light_text: rgba(92, 149, 215, 1)
$primary_light: #f0faff
$primary_dark: #121417


//main fonts
@mixin primary_font-p
  font-family: Ubuntu, sans-serif
  font-size: 14px
  font-weight: 600

@mixin primary_font-h1
  font-family: Ubuntu, sans-serif
  font-size: 25px
  font-weight: 800

@mixin primary_font-menu
  font-family: Ubuntu, sans-serif
  font-size: 16px
  font-weight: 600

//flex
@mixin flex-all-center
  display: flex
  justify-content: center
  align-items: center

@mixin flex-center-sb
  display: flex
  justify-content: space-between
  align-items: center

@mixin flex-center-sa
  display: flex
  justify-content: space-around
  align-items: center

@mixin flex-columns
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: center


@mixin block-shadow
  box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.33)
  -webkit-box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.33)
  -moz-box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.33)

.buttons_block
  width: 70%
  @include flex-center-sb
  a
    width: 45%
    min-height: 40px
    text-decoration: none
    outline: none
    cursor: pointer
    button
      width: 100%



.buttons_block-bottom
  width: 40%
  @include flex-center-sb
  a
    width: 45%
    min-height: 40px
    text-decoration: none
    outline: none
    cursor: pointer
    button
      width: 100%

.white-btn
  width: 45%
  min-height: 40px
  padding: 5px
  color: $primary_blue
  background-color: $primary_light
  border: none
  border-radius: 20px
  outline: none
  cursor: pointer
  font-size: 14px
  &:hover
    background-color: white
    text-decoration: underline $primary_blue

.white-btn-border
  width: calc(45% - 2px)
  min-height: calc(40px - 2px)
  padding: 5px
  color: $primary_blue
  background-color: $primary_light
  border-radius: 20px
  outline: none
  cursor: pointer
  font-size: 14px
  border: 1px solid $primary_blue
  &:hover
    background-color: white
    text-decoration: underline $primary_blue

.home-top-btn
  background-color: $primary_blue_dark_btn !important
.blue-btn
  width: 45%
  min-height: 40px
  padding: 5px
  color:  $primary_light
  background-color: $primary_blue_dark
  border: none
  border-radius: 20px
  outline: none
  cursor: pointer
  font-size: 14px
  &:hover
    background-color:  rgb(64, 123, 209)
    text-decoration: underline $primary_light




@media screen and (max-width: 500px)
  .homePage__top-block, .homePage__bottom-block
    flex-wrap: wrap

  .homePage__top-left h3
    max-width: 539px

  .homePage__top-left
    width: 90%

  .homePage__top-left
    .pgsql
      padding: 20px !important
      width: 300px
      height: 200px
      overflow: auto
      word-break: break-word

  .products-dropdown
    display: none
  .homePage__top-left
    h1
      font-size: 30px !important
    h3
      font-size: 20px !important

  .homePage__noActiveTab
    padding: 12.6px
  .homePage__activeTab
    padding: 12.6px

  div.homePage__top-block:nth-child(2) > div:nth-child(1)homePage__codeTabs-container
    margin: 0 auto !important
    display: flex
    justify-content: center
    align-items: center


  div.homePage__top-block:nth-child(2) > div:nth-child(1)
    display: flex
    justify-content: center
    align-content: center
    align-items: center
  .homePage__codeTabs-container
    width: 100% !important

.dark-color
  color: black
