.proofOfWorkPollContainer {
    margin-top: 35px;
}

.proofOfWorkPollContainer > div {
    text-align: left;
    width: 100%;
    max-width: 417px;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: .25rem;
}

h4.proofOfWorkPoll__title {
    font-size: 18px;
}

h4.proofOfWorkPoll__title .tooltip-inner-content.top{
    position: absolute;
    font-size: 10px;
}

.optionsOfProofOfWorkPoll__option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.optionsOfProofOfWorkPoll__option label {
    display: flex;
    align-items: center;
    color: gray;
    font-size: 15px;
    margin: 0;
    cursor: pointer;
}

.optionsOfProofOfWorkPoll__option div {
    display: inline-block;
    position: relative;
    margin-right: 5px;
    width: 15px;
    height: 15px;
    border: 1px solid #0162d0;
    border-radius: 50%;
    transition: all .4s ease;
}

.optionsOfProofOfWorkPoll__option div::before {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    background-color: #0162d0;
}

.optionsOfProofOfWorkPoll__option input:checked + div::before {
    display: block;
}

.progressBlockOfPoll__barContainer {
    position: relative;
}

.progressBlockOfPoll__barContainer.progressBlockOfPoll__barContainer--positive {
    margin-bottom: 10px;
}

.progressBlockOfPoll__barContainer > span {
    display: inline-block;
}

.progressBlockOfPoll__barContainer.progressBlockOfPoll__barContainer--positive > span {
    color: #0162d0;
}

.progressBlockOfPoll__barContainer.progressBlockOfPoll__barContainer--negative > span {
    color: #FF5260;
}

.progressBlockOfPoll__barContainer.progressBlockOfPoll__barContainer--positive > span,
.progressBlockOfPoll__barContainer.progressBlockOfPoll__barContainer--negative > span,
.progressBlockBar span {
    margin-left: 15px;
}

.progressBlockOfPoll__barContainer div {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 5px;
}

.progressBlockOfPoll__barContainer.progressBlockOfPoll__barContainer--positive div {
    background-color: #0162d0;
}

.progressBlockOfPoll__barContainer.progressBlockOfPoll__barContainer--negative div {
    background-color: #FF5260;
}

.proofOfWorkPoll__changeMindBtn {
    display: block;
    background-color: transparent;
    border: none;
    color: gray;
    font-size: 13px;
    padding: 0;
    margin: 20px auto 0;
    cursor: pointer;
    outline: none!important;
}

.progressBlockBar span {
    color: #fff;
    white-space: nowrap;
}

.progressBlockBar {
    display: flex;
    overflow: hidden;
}