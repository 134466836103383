.advertByCollectibles {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
    margin-top: 20px;
    transform: translateY(-35px) scale(.8);
    opacity: 0;
    transition: all .5s ease;
    height: 250px;
    /*overflow-x: auto;*/
    overflow: hidden;
}

.advertByCollectibles--show {
    transform: translateY(0) scale(1);
    opacity: 1;
}

.advertInfo {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 135px;
    overflow: auto;
}

.adv_containter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 180px;
    border: 1px solid #DAE1E9;
    /*background-color: rgba(251, 251, 251, 0.82);*/
    background-color: rgba(0,0,0,0.05);
    margin: 10px;
    min-width: 180px;
    border-radius: 20px;
    letter-spacing: .3px;
    font-size: 13px;
    transition-duration: .7s;
    padding: 0 5px;
}
/*.adv_containter  :hover {*/
    /*background-color: rgba(0,0,0,0.05);*/
/*}*/



.adv_line {
    text-align: center;
}

.adv_line img {
    width: 35px;
    height: 35px;
    border-radius: 20px;
    margin-top: 10px;
}

.adv_title-project {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 149px;

    text-align: center;

    padding-left: 13px;
}
.adv_line-button {
    display: flex;
    border: 2px solid #0162D0;
    background-color: #0162D0;
    color: white;
    padding: 2px 16px;
    border-radius: 20px;
    margin: 10px 0;
    cursor: pointer;
}

.adv_sub-title {
    color: rgb(6, 103, 208);
    letter-spacing: 0.4px;
    background-color: white;
    font-size: 16px;
    font-weight: 400;
}

.advertInfoList {
    margin: 0 55px 0 15px;
}

.advertInfoItem:not(:last-child) {
    margin-bottom: 5px;
}

.advertInfoList__link a {
    color: #212529;
}

.advertInfoItem span,
.advertInfo__description span {
    color: #0162d0;
}

.advertInfo__description {
    width: 100%;
    max-width: 1130px;
}

.advertInfo__img {
    width: 100%;
    max-height: 70px;
    max-width: 70px;
}

.advertInfo__img img {
    max-height: 100%;
    max-width: 100%;
}

.advertInfo__button {
    width: 100%;
    max-width: 110px;
    margin-left: auto;
}

.advertInfo__button a:hover {
    text-decoration: none;
}

.advertInfo__button {
    position: absolute;
    right: 0;
    bottom: 0;
}

.advertInfo__closeBtn {
    position: absolute;
    top: 14px;
    right: 7px;
}

/* media queries */

@media screen and (max-width: 800px) {
    .advertInfo {
        padding: 0;
        flex-wrap: wrap;
    }

    .advertInfoList {
        margin-right: 0;
    }

    .advertInfo__button {
        position: static;
    }
}

@media screen and (max-width: 550px) {
    .advertInfo {
        flex-direction: column;
    }

    .advertInfo__img {
        margin: auto;
    }

    .advertInfoList {
        margin: 0;
    }
}
