.slideshowModal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 999;
    width: 100vw;
    height: 100vh;
    padding: 20px 15px;
}

.slideshowModal,
.slideshowModalWrapper,
.slideshowModalImgBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slideshowModalWrapper {
    position: relative;
    width: 100%;
    max-width: 1200px;
    height: 100%;
}

.slideshowModalWrapper__closeButton {
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 1;
}

.slideshowModalWrapper__rightArrow,
.slideshowModalWrapper__leftArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 85px;
}

.slideshowModalWrapper__rightArrow {
    right: 0;
}

.slideshowModalWrapper__leftArrow {
    left: 0;
}

.slideshowModalImgBox {
    width: 100%;
    max-width: 900px;
    height: 100%;
}

.slideshowModalImgBox img {
    min-width: 0;
    min-height: 0;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    transition: opacity .4s ease;
}

.slideshowModalButton {
    border: none;
    background-color: transparent;
    outline: none !important;
    padding: 0;
    cursor: pointer;
}

.slideshowModalWrapper__closeButton {
    width: 45px;
    height: 45px;
}

.slideshowModalButton img {
    width: 100%;
}

.hide_test {
    opacity: 0;
}



.slide-progress-bar {
    width: 100%;
    margin: 0 auto;
    background-color: rgb(222, 227, 227);
    transition: width 10s linear;
    display: inline-block;
    border: 1px solid #a09f9f;
    vertical-align: middle;
    border-radius: 4px;
    margin-top: 10px;
}

.progress-bar {
    height: 25px;
    background-color: #007bff;
    width: 1%;
    position: relative;
    transition: linear;
}

