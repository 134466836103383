.mainHeaderTerms {
  /*background-color: #1F407A;*/

}

.mainHeaderTerms p:nth-of-type(1){
  color: white;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  padding-top: 180px;
  padding-bottom: 110px;
  letter-spacing: 1px;
}

.firstTitle {
	text-align: left;
	line-height: 30px;
	font-weight: bold;
	font-size: 17px;
	padding: 50px;
	/*padding-top: 70px;*/
	padding-bottom: 30px;
}

.firstTitle2 {
	text-align: left;
	line-height: 30px;
	font-weight: bold;
	font-size: 17px;
	padding: 0px 50px;
	marginTop: -20px;
	marginBottom: 30px;
	/*padding-top: 70px;*/
}
.main_sliders-title {
	color: black;
	font-size: 20px;
	text-align: center;
	margin-bottom: 30px;
}
.paraTitle {
	text-align: justify;
	line-height: 30px;
	font-weight: 600;
	font-size: 15px;
	padding: 50px;
	padding-top:0px;
	padding-bottom: 0px;

}

.paraDescr {
	padding: 50px;
	padding-top:0px;
	padding-bottom:30px;
	font-size: 14px;
	text-align: left;
}

.paraDescr2 {
	display: flex;
	/*flex-wrap: wrap;*/
	padding: 50px;
	padding-top:0px;
	padding-bottom:5px;
	font-size: 14px;
	text-align: left;
}

.paraDescrli {
	font-size: 14px;
	text-align: left;
	padding-right: 50px;
}

@media screen and (min-width: 1000px) {

	.row2 {
		padding-left: 10%;
		padding-right: 10%;
	}
}
@media screen and (min-width: 1200px) {

	.row2 {
		padding-left: 20%;
		padding-right: 20%;
	}
}

.mob-empl {
	margin-right: 12px;
}

@media screen and (max-width: 600px) {
	.mob-empl {

		display: none !important;
	}
	.mob-socials {
		max-width: 33%;
		width: 33% !important;
	}
	.firstTitle2 {
		padding: 0 31px !important;
	}

	.tokenomic-img {
		margin-left: 0 !important;
		width: 100% !important;
	}
.firstTitle {
	padding: 30px;
}
.paraTitle {

	padding: 30px;
	padding-top:0px;
	padding-bottom: 0px;

}

.paraDescr {
	padding: 30px;
	padding-top:0px;
	padding-bottom:30px;

}
.paraDescrli {
	padding-right: 40px;
}

}

.privacyPolicy__page {
	max-width: 98%;
	margin: 20px auto;
	background-color: white;
	border-radius: 10px;
	padding: 0px 40px 20px 40px;
	overflow: auto;
	max-height: 81%;
	position: relative;
}

.privacyPolicy__page p {
	/*text-indent: 40px;*/
	margin: 35px 0;
}

.lst-up p {
	margin: 10px 0 !important;
}

.privacyPolicy__page ol li{

	margin-left: 80px;
}

.privacyPolicy__page_title{
	/*margin-left: 40px;*/
	font-size: 20px;
	display: flex;
	justify-content: center;
}
.privacyPolicy__page_mainTitle{
	text-align: center;
	margin-bottom: 0px;
	font-size: 20px;
}
.privacyPolicy__page_italic{
	font-style: italic;
}

.privecyPage__page_footer_title {
	margin: 40px;
	text-align: center;
}

