.chart {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
  margin: auto;
  margin-top: 20px;
  max-width: 90%;
  font-family: Graphik, "Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.chart_title {
  display: flex;
  align-items: center;
  height: 120px;
  padding-left: 20px;
}

.chart_logo {
  width: 50px;
  height: 50px;
  background-size: 50px;
  background-image: url('/src/assets/images/f-in-blue1.png');
}

.chart_title > p {
  padding-left: 25px;
  font-size: 30px;
  color: #4C5B6F;
}

.chart_chart {
  position: relative;
  border: solid 1px #e2e2e2;
  border-radius: 5px;
}

.chart_header {
  position: relative;
  display: inline-flex;
  height: 110px;
  width: 40%;
  align-items: center;
}

.chart_amount {
  padding-left: 20px;
  color: #4E5C6E;
  font-size: 48px;
}

.chart_delta {
  align-self: flex-start;
  padding-left: 20px;
  padding-top: 5px;
  font-size: 24px;
}

.chart_viewToggle {
  background-color: #9BA6B2;
  border-radius: 5px;
  border: solid 1px;
  position: absolute;
  top: 30px;
  height: 30px;
  right: 30px;
  width: 55px;
  color: #fff;
  font-size: 13px;
  text-align: left;
  padding-left: 8px;
}

.chart_viewToggle::after {
  content: '';
  position: absolute;
  right: 5px;
  top: 10px;
  border: 6px solid transparent;
  border-top: 7px solid #fff;
}

.chart_viewBarWrap {
  display: inline-block;
  float: right;
  padding-top: 20px;
  width: 40%;
}

.chart_viewBar {
  text-align: right;
  flex-grow: 1;
  padding-right: 25px;
}


.chart_viewBar:hover,
.chart_viewToggle:hover {
  cursor: pointer;
}

.chart_viewBar > li {
  padding-right: 15px;
  display: inline-block;
  user-select: none;
}

.chart_view:hover {
  color: #5e5e5e !important;
}

.chart_activeView:hover {
  color: #015BD3 !important;
}

.chart_path {
  stroke-width: 1;
  fill: #F3F7FA;
}

.chart_labels {
  padding-left: 0;
  border-top: solid 1px #e2e2e2;
  border-bottom: solid 1px #e2e2e2;
  display: flex;
  list-style: none;
  justify-content: space-around;
  align-items: center;
  background-color: #F3F7FA;
  margin-top: -6px;
  margin-bottom: 0;
}

.chart_label {
  color: #7D95B6;
}

.chart_footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0;
}

.chart_footer_indicator {
  color: #A5ADB7;
  font-size: 16px;
}

.chart_footer_value {
  font-color: #4E5C6E;
  font-weight: 500;
}

.chart_tooltip {
  padding-top: 5px;
  color: #fff;
  text-align: center;
  position: absolute;
}

.chart_tooltip::after {
  content: '';
  position: absolute;
  left: 63px;
  border: 15px solid transparent;
  border-top: 15px solid #4C5B6F;
}

.chart_tooltip_date {
  display: block;
  color: #C9CDD4;
  font-size: 14px;
}

@media (max-width: 1300px) {
  .chart {
    max-width: calc(100% - 40px);
  }
}
@media (max-width: 1000px) {
  .chart_path {
    stroke-width: 2;
  }
}
@media (max-width: 850px) {
  .chart_header {
    flex-wrap: wrap;
  }
  .chart_viewBar {
    position: absolute;
    top: 65px;
    right: 28px;
    height: 145px;
    width: 60px;
    padding-left: 20px;
    border-radius: 5px;
    background-color: #d4d7db
  }
  .chart_viewBar > li {
    display: block;
  }
  .chart_amount {
    margin-top: 0;
    margin-bottom: 0;
  }
  .chart_delta {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 750px) {
  .chart {
    max-width: calc(100% - 20px);
  }
}
@media (max-width: 550px) {
  .chart_labels {
    font-size: 14px;
  }
  .chart_amount {
    font-size: 26px;
  }
  .chart_delta {
    font-size: 18px;
  }
  .chart_footer_indicator,
  .chart_footer_value {
    font-size: 12px;
  }
}
