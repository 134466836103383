.listOfTradeHistory {
    padding: 0;
    border-top: 1px solid rgb(218, 225, 233);
    border-bottom: 1px solid rgb(218, 225, 233);
    margin: 0;
    list-style: none;
}

.tradeHistory {
    height: 298px;
    position: relative;
}

.tradeHistory--isLoading {
    overflow: hidden;
}

.tradeHistory--isLoading > .image-spinner-frame {
    height: 100%;
}

.tradeHistoryListItem__license {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tradeHistoryListItem__license img {
    max-width: 30px;
}

.tradeHistoryListItem {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    padding-left: 10px;
}

.tradeHistoryListItem:not(:last-child) {
    /*border-bottom: 1px solid rgb(218, 225, 233);*/
}

.headerOfTradeHistory {
    display: flex;
    justify-content: space-between;
}

.headerOfTradeHistory > span,
.tradeHistoryListItem > span {
    font-size: 12px;
    color: rgb(78, 92, 110);
    letter-spacing: 0.5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 5px;
    width: 100%;
    /*background-color: red*/
}

.headerOfTradeHistory > span {
    color: rgb(6, 103, 208);
}

.headerOfTradeHistory > span:not(:last-child),
.tradeHistoryListItem > span:not(:last-child) {
    border-right: 1px solid rgb(218, 225, 233);
}

.tradeHistoryListItem > .tradeHistoryListItem__price--buy {
    color: #28cd6a;
}

.tradeHistoryListItem > .tradeHistoryListItem__price--sell {
    color: #ff5260;
}
