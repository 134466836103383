.header_bottom__wrapper {
    background-color: white;
    display: flex;
    justify-content: space-between;
    width: 91%;
    margin: 0 auto;
    align-items: center;
    height: 44px;
}

.header_bottom__menu {
    width: 520px;
    display: flex;
    justify-content: space-between;
}

.header_bottom__menu__item,
.header_bottom__menu__item_select {
    padding: 12px 0;
    color: black;
    font-weight: 700;
    letter-spacing: 0.6px;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s;
    height: 43px;
}

.header_bottom__menu__item:hover,
.header_bottom__menu__item_select:hover {
    text-decoration: none;
    color: var(--lightPrimaryColor);
    border-bottom: 2px solid var(--lightPrimaryColor);
}

.header_bottom__menu__item_select {
    border-bottom: 2px solid var(--lightPrimaryColor);
    color: var(--lightPrimaryColor);
}

.header_bottom__verify-btn {

}

.header_bottom__verify-btn_complete,
.header_bottom__verify-btn_not-complete {
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.6px;
    padding: 5px 20px;
    border-radius: 4px;
    transition: 0.2s;
}

.header_bottom__verify-btn_complete:hover,
.header_bottom__verify-btn_not-complete:hover {
    text-decoration: none;
}

.header_bottom__verify-btn_complete {
    background-color: var(--darkPrimaryColor);
    color: white;
}

.header_bottom__verify-btn_complete:hover {
    background-color: var(--primaryColor);
    color: white;
}

.header_bottom__verify-btn_not-complete {
    background-color: var(--ghostLightColor)
}

@media screen and (max-width: 860px) {
    .header_bottom__wrapper {
        display: none;
    }
}