.myTicketsPageBody {
    width: 100%;
    padding: 20px 15px;
}

.myTicketsPageBody > .contentBodyContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1720px;
    margin: auto;
}

.myTicketsLeftSide {
    width: 30%;
    margin-right: 20px;
}

.myTicketsRightSide {
    width: 70%;
}

.tokens-container {
    display: flex;
    width: 100%;
}

/*.popup_token-block {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

.popup_tokens-texts {
    margin-top: 10px;


}

.popup_tokens-text {
    width: 48%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.popup_tokens-btn {
    margin: 20px auto 0 auto;
    width: 150px;
    height: 45px !important;
    min-height: 45px !important;
    cursor: pointer;
    color: white;
    background-color: #0c48c8;
    outline: none;

    border: none;
}
.popup_tokens-line {
    margin-top: 8px;
    width: 48%;
    height: 1px;
    background-color: #343a40;
    opacity: .4;
}

.wallets-block {
    margin-top: 20px;
}
.token_popup-inputs {
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
}
.popup-max-inpt-full {
    width: 100%;
}
.popup-max {
    margin-top: 18px;
    margin-left: 5px !important;
}

.popup-max-inpt {
    /*margin-left: 100px !important;*/
    padding-left: 100px !important;
    /*background-color: #0a6ffd !important;*/
}
.token_popup-inputs .token_popup-amounts {
    position: relative;
    margin-top: 40px !important;
    width: 48%;
    display: flex;
    flex-direction: column;
}
.popup_tickets-flex {
    display: flex;
    /*flex-direction: column;*/
}

.token_popup_leftflex button {
    margin: 20px 0 !important;
    cursor: pointer;
}

.token_popup_leftflex {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.popup_tickets-flex button {
    width: 210px;
    cursor: pointer;
}

/*.popup_ticket-block {*/
/*    padding: 20px !important;*/
/*}*/
.popup_tokens {
    width: 60%;
    height: 600px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 50px;
    z-index: 999;
    /*margin: 0 auto;*/
}

.popup_deposit {
    width: 60%;
    height: 750px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 50px;
}

.portfolio-popup {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    height: 450px;

    overflow: auto;

}

.portfolio-tab-list {
    height: 300px;
    overflow: auto;

}

.portfolio-tab-block {
    display: flex;
    justify-content: center;
}

.portfolio-tab-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 120px;
    height: 35px;
    outline: none;
    color: white;
    border: 1px solid #0a6ffd;
    cursor: pointer;
    background-color: #0a6ffd;
}
.portfolio-btn-active {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 120px;
    height: 35px;
    outline: none;
    color: #0a6ffd;
    border: 1px solid #0a6ffd;
    /*cursor: pointer;*/
    background-color: white;
}

.portfolio-btn-download {
    font-size: 14px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #0a6ffd;
    transition: 0.2s;
}

.portfolio-btn-download:focus {
    outline: none;
}

.portfolio-btn-download:hover {
    color: #0b2c7e;
}

.portfolio-table-head {
    display: flex;
    width: 100%;
}

.portfolio-table {
    /*display: flex;*/
    /*flex-direction: column;*/
    width: 100%;
    margin-top: 20px;
    /*margin: 0;*/
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    /*height: 300px !important;*/
}

.portfolio-table td {
    text-align: center;
    border: 1px solid gray;
}

.portfolio-table-title {
    background-color: #0a6ffd;
    color: white;
}

.popup-withdraw {
    padding: 0;
}
.token_popup {
    position: absolute;
    top: 0px;
    width: 100%;

    background-color: black;
    /*overflow: hidden;*/
    opacity: .6;
    height: 100vh;
    z-index: 111;
}

.tokens-tabs {
    margin: 20px 0;
    width: 100%;
    height: 80px;
    padding: 0 40px;
    background-color: red;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.tokens-tab__name {
    width: 45%;
    display: flex;
    min-width: 90px;
    justify-content: center;
    align-items: center;
    border: 1px solid #0064b4;
    color: #0064b4;
    margin: 3px;

    border-radius: 4px;
    cursor: pointer;
    /*background-color: #0b2c7e;*/
}


.myTicketsOrderForm,
.myTicketsListContainer,
.myTicketsHistoryBlock,
.chartOpenOrders {
    background-color: #fff;
    border-radius: 5px;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 8px 0;
}

.myTickets--title h2 {
    letter-spacing: 0.4px;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.myTicketsRightSide__wrapperOfBottomBlocks {
    display: flex;
    justify-content: space-between;
    max-height: 1400px;
}

.myTicketsRightSide__wrapperOfBottomBlocks .myTicketsOrderForm {
    width: 40%;
}

/* Media queries */

@media screen and (max-width: 1500px) {
    .myTicketsTradeHistoryHeader {
        flex-direction: column;
    }

    .myTicketsTradeHistoryHeader > h3,
    .myTicketsTradeHistoryHeader > button {
        margin-bottom: 5px;
        font-size: 15px;
    }

    div.myTicketsHistoryList__title {
        margin-bottom: 5px;
    }

    .myTicketsHistoryList__title > h3,
    .myTicketsHistoryItem span,
    div.myTicketsHistoryList__title > h3 {
        font-size: 12px;
    }

    div.myTicketsBuyAndSellBar__title {
        margin-bottom: 10px;
    }

    div.myTicketsBuyAndSellBar__title h3 {
        font-size: 15px;
    }

    .myTicketsHistoryBlock--personalHistory .myTicketsTradeHistoryHeader {
        flex-direction: row;
    }
}

@media screen and (max-width: 1150px) {
    .contentBodyContainer {
        flex-direction: column;
    }

    .myTicketsLeftSide,
    .myTicketsRightSide {
        width: 100%;
        margin-right: 0;
    }

    .myTicketsListContainer > div.pagination {
        margin: 20px auto;
    }
}

@media screen and (max-width: 850px) {
    .myTicketsRightSide__wrapperOfBottomBlocks {
        flex-direction: column;
    }

    .myTicketsRightSide__wrapperOfBottomBlocks .myTicketsOrderForm,
    div.myTicketsHistoryBlock {
        width: 100%;
    }

    .myTicketsOrderForm {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 800px) {
    .myTicketsChart .chart_title {
        flex-wrap: wrap;
    }

    .myTicketsChart .chartOrderLabels {
        flex-wrap: wrap;
        max-width: 100%;
        order: -1;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    .myTicketsChart .chartOrderLabel:not(:last-child) {
        margin-right: 5px;
    }

    .myTicketsRightSide__wrapperOfBottomBlocks {
        flex-direction: column;
    }

    .myTicketsOrderForm {
        margin: 0 auto 20px;
    }

    .myTicketsRightSide__wrapperOfBottomBlocks > .myTicketsOrderForm,
    .myTicketsRightSide__wrapperOfBottomBlocks > .myTicketsBars,
    .myTicketsRightSide__wrapperOfBottomBlocks > .myTicketsTradeHistory,
    div.myTicketsHistoryBlock  {
        width: 100%;
    }
}

@media screen and (max-width: 550px) {
    .myTicketsHistoryBlock {
        background-color: transparent;
        box-shadow: none;
        flex-direction: column;
    }

    .myTicketsHistoryBlock > div {
        width: 100%;
    }

    .myTicketsTradeHistory {
        order: -1;
        margin-bottom: 20px;
    }

    .myTicketsBars,
    .myTicketsTradeHistory {
        background-color: #fff;
        border-radius: 5px;
        padding: 0;
        box-shadow: rgba(0, 0, 0, 0.2) 0 4px 8px 0;
    }
}

@media screen and (max-width: 450px) {
    .myTicketsFinalizeOrder__total > div > span,
    .myTicketsOrderSettingsInput label,
    .orderSettingsNav__btn {
        font-size: 12px;
    }

    button.myTicketsOrderType__btn,
    div.myTicketsOrderSettingsInput h4 {
        font-size: 15px;
    }

    div.myTicketsOrderForm {
        margin-right: 0;
        height: 580px;
    }

    .myTickets_flex {
        margin-bottom: 10px;
    }

    li.myTicketsListItem {
        flex-direction: column;
        align-items: flex-start;
    }

    .myTicketsListItem > .myTickets_flex {
        width: 100%;
        justify-content: flex-start;
    }

    .myTicketsListItem > .control-buttons__block {
        width: 100%;
        min-width: 100%;
    }
}
