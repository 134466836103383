.auction-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 417px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .25rem;
  padding: 20px;
}

.auction-card .auction-card__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  /*border-bottom: 1px solid rgba(0, 0, 0, 0.2);*/
  padding: 20px 0 10px 0;
  /*margin: 0 20px;*/
}

.inviteFriendWrap-block_right.auction-card {
  overflow: scroll;
  flex-direction: row;
}

.auction-card .auction-card__header {
  display: flex;
  flex-direction: column;
  float: left;
  padding-bottom: 20px;
}


.card-header__title {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.card-header__description {
  text-transform: uppercase;
  font-size: 11px;
}

.tickersymbol-title {
  width: 100%;
  text-align: center;
}
.auction-card .auction-card__header__title {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 800;
  float: left;
}

.auction-card .auction-card__body .bid-header {
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 5px;
}

.auction-card .auction-card__body .bid-value {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
}

.auction-card .auction-card__body .bid-value .your-bid {
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.7);
  width: 100%;
}

.auction-card .auction-card__body .body-sector {
  margin: 10px 0;
}

.auction-card .auction-card__btn-section .do-bid__btn {
  height: 45px;
  width: 100%;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #ffffff;
  /*padding: 10px;*/
  text-align: center;
  background-color: #FF5260;
  margin: 30px 0 10px 0;
  cursor: pointer;
}

.auction-card .auction-card__btn-section .do-bid__btn--buyNow {
  border: 1px solid #FF5260;
  background-color: transparent;
  color: #FF5260;
  margin-top: 10px;
}

.auctionCardStatusMessage {
  text-align: center;
  color: #0162d0;
  font-weight: 600;
  padding-top: 10px;
  height: 0;
  overflow: hidden;
  transition: height 0.4s ease;
}

.auctionCardStatusMessage__show {
  height: 34px;
}

.auction-card__btn-section {
  text-align: center;
}

.disable-bid-btn {
   color: #b5b0b0 !important;
   cursor: default !important;
   background-color: #dadada !important;

}

.auction-card__btn-section .image-spinner-frame {
  height: auto;
  position: static;
}

.auction-card__btn-section .loader {
  margin: 20px;
}

.auction-card__updateBtn--loading {
  transform: rotate(-4000deg);
}

.auction-card__updateBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 100%;
  max-width: 40px;
  display: block;
  border: none;
  background-color: transparent;
  height: 40px;
  cursor: pointer;
  outline: none !important;
  transition: all 5s linear;
}

.auction-card__updateBtn img {
  max-width: 100%;
}

.auction-card .discount-description {
  padding-top: 10px;
  padding-bottom: 10px;
  clear: both;
  position: relative;
}

.auction-card .discount-description span:first-child {
  position: relative;
  z-index: 1;
  padding-right: 10px;
  background-color: white;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  height: 24px;
}

.auction-card .discount-description span:nth-of-type(2) {
  padding-left: 4px;
  padding-right: 0px;
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  background-color: white;
  z-index: 1;
  position: absolute;
  right: 0px;
}

.auction-card .discount-description .dashedVerticalLine {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 20px;
  left: 40px;
  right: 0;
  z-index: 0;
}

.flex {
  display: flex;
}

.justify-content__space-between {
  justify-content: space-between;
}

.align-items__center {
  align-items: center;
}

.border-green {
  border: 1px solid #52ba00 !important;
}

.card_right-arrow {
  cursor: pointer;
  border: 2px solid #0a6ffd;
  border-radius: 80px;
  background-color: white;
  width: 40px;
  height: 40px;
  position: absolute;
  right: -28px;
  top: 370px;
}


.card_right-left {
  cursor: pointer;
  border: 2px solid #0a6ffd;
  border-radius: 80px;
  background-color: white;
  width: 40px;
  height: 40px;
  position: absolute;
  left: -28px;
  top: 370px;
  transform:rotate(-180deg);
}


.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}





