.toTopArrow__wrapper {
    position: fixed;
    width: 40px;
    height: 40px;
    z-index: 10;
    bottom: 20px;
    right: 30px;
    transform: translate(100px);
    opacity: 0;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: 0.3s;
}

.toTopArrow__wrapper:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.toTopArrow__wrapper img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}