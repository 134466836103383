.aboutMePage {
    height: auto;
    min-height: calc(100% - 201px);
}

/* media queries */

@media screen and (max-width: 940px) {
    .aboutMePage .profilePageWrapper {
        padding-left: 20px;
        padding-right: 20px;
    }
}