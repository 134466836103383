.lm-flex {
  display: flex;
}

.logo-maker .section-sub-title {
  margin-top: 32px;
}

.lm-column {
  flex-direction: column;
}

.lm-row {
  flex-direction: row;
}

.jc-sb {
  justify-content: space-between;
}

#logo-maker {
  border-radius: 12px;
}

#logo-maker * {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.logo-maker {
  margin: 0 auto;
  background: white;
  border-radius: 12px;
  padding: 12px 12px 32px 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: max-content;
}

#logo-maker-loading {
  overflow: hidden;
  position: absolute;
  z-index: -200;
  width: 0px;
  height: 0px;
  top: 0;
  left: 0;
  opacity: 0;
}

.logo-maker-settings {
  width: 400px;
  margin-right: 50px;
}

.logo-maker select {
  font-size: 18px;
}

.logo-maker label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-maker label * {
  margin: 0.25em 0;
}

.logo-maker label input[type='text'] {
  width: 100px;
}

.logo-maker-h1-loader {
  text-align: center;
}

.logo-maker input:not([type="radio"]) {
  width: auto;
}


.logo-maker input[type=range] {
  -webkit-appearance: none;
  width: auto;
  background: transparent;
  border: none;
  margin-top: 16px;
}

.logo-maker input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.logo-maker input[type=range]:focus {
  outline: none; 
  border: none;
}

.logo-maker input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

.logo-maker input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #9098a1;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  margin-top: -6px; 
  box-shadow: 1px 1px 1px #444; 
}

.logo-maker input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #444;
  border: 1px solid #9098a1;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

.logo-maker input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #444;
  border: 1px solid #9098a1;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

.logo-maker input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #5a6168, 0px 0px 1px #5a6168;
  background: #78818a;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

.logo-maker input[type=range]:focus::-webkit-slider-runnable-track {
  background: #367ebd;
}

.logo-maker input[type=range]::-moz-range-track {
  width: 100%;
  height: 4.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #5a6168, 0px 0px 1px #5a6168;
  background: #78818a;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

.logo-maker input[type=range]::-ms-track {
  width: 100%;
  height: 4.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
.logo-maker input[type=range]::-ms-fill-lower {
  background: #78818a;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #5a6168, 0px 0px 1px #5a6168;
}
.logo-maker input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;
}
.logo-maker input[type=range]::-ms-fill-upper {
  background: #78818a;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #5a6168, 0px 0px 1px #5a6168;
}
.logo-maker input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
}

.logo-maker select {
  width: 170px;
  padding: 0px 12px;
  border: 1px solid #e0e6ed;
 font-size: 15px;
 height: 47px;
 outline: none;
 font-weight: 400;
 color: #000000;
 border-radius: 4px;
 transition: 0.2s all;
 display: block;
 background-color: #FFFFFF;
 resize: none;
 -webkit-appearance: none;
}

.logo-maker select:active {
  border-radius: 4px;
}

.logo-maker .cp-input-body {
  padding: 2px 6px;
  border: 1px solid #d0d6dd;
  border-radius: 3px;
}

.logo-maker .cp-input-color {
  height: 21px;
  width: 46px;
  border: 1px solid #d0d6dd;
}

.logo-maker #cp-hex-input {
  padding: 2px;
}

.logo-maker #cp-color-label {
  height: 19px;
  border: 1px solid #e0e6ed;
  border-radius: 4px;
}

.logo-maker #cp-close-btn {
  border: 1px solid #d0d6dd;
  border-radius: 4px;
  background: white;
  box-shadow: 1px 1px 1px lightgrey;
}

#logo-maker-submit-btn {
  border: 1px solid #d0d6dd;
  border-radius: 4px;
  background: linear-gradient(to bottom, #fff, #ddd);
  padding: 12px;
  box-shadow: 1px 1px 1px lightgrey;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  color: #718297;
  cursor: pointer;
  margin-top: 0.25em;
}

#lm-canvas-block {
  margin: 20px;
}

.logoMakerNavPanel button {
  width: 50%;
  background-color: #fff;
  border: 0;
  outline: none!important;
  color: rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 20px;
  padding-bottom: 10px;
  cursor: pointer;
}

.logoMakerNavPanel button.logoMakerNavPanel--active {
  color: #0162d0;
  border-bottom: 1px solid #0162d0;
}

.logoMakerUploadImg {
  margin-bottom: 100px;
  margin-top: 30px;
}

#logo-maker-submit-btn:disabled,
#logo-maker-submit-btn[disabled] {
  cursor: not-allowed;
}

.logoMakerUploadImg label {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.logoMakerUploadImg label img {
  width: 100%;
  max-width: 150px;
}

.logoMakerUploadImg label span {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 980px) and (max-width: 1024px) {
  #lm-main-canvas {
    width: 400px;
    height: 400px;
  }

  .logo-maker-settings {
    width: 320px;
    margin-right: 30px;
  }
}

@media screen and (min-width: 800px) and (max-width: 979px) {
  #lm-main-canvas {
    width: 350px;
    height: 350px;
  }

  .logo-maker-settings {
    width: 320px;
    margin-right: 30px;
  }
}

@media screen and (min-width: 720px) and (max-width: 799px) {
  #lm-canvas-block {
    margin: 10px 20px 10px 10px;
  }

  #lm-main-canvas {
    width: 300px;
    height: 300px;
  }

  .logo-maker-settings {
    width: 320px;
    margin-right: 20px;
  }

  .logo-maker select {
    font-size: 13px;
    height: 37px;
  }

  .logo-maker label {
    align-items: flex-end !important;
  }

  .logo-maker input:not([type="radio"]), textarea {
    font-size: 13px;
    padding: 10px;
  }

  .logo-maker .section-sub-title {
    margin-top: 12px;
  }

  #logo-maker-submit-btn {
    padding: 9px;
    margin-top: 0.5em;
  }
}

@media screen and (min-width: 650px) and (max-width: 719px) {
  #lm-canvas-block {
    margin: 10px 20px 10px 10px;
  }

  #lm-main-canvas {
    width: 270px;
    height: 270px;
  }

  .logo-maker-settings {
    width: 270px;
    margin-right: 10px;
  }

  .logo-maker select {
    font-size: 11px;
    height: 34px;
    width: 143px;
  }

  .logo-maker label {
    align-items: flex-end !important;
  }

  .logo-maker input:not([type="radio"]), textarea {
    font-size: 11px;
    padding: 10px;
  }

  .logo-maker .section-sub-title {
    margin-top: 12px;
  }

  #logo-maker-submit-btn {
    padding: 9px;
    margin-top: 0.5em;
  }

  .logo-maker label input[type="text"] {
    width: 41px;
    height: 12px;
  }
}

@media screen and (min-width: 420px) and (max-width: 649px) {
  .logo-maker {
    width: min-content;
  }

  #lm-canvas-block {
    margin: 10px 20px 10px 10px;
  }

  #lm-main-canvas {
    width: 270px;
    height: 270px;
  }

  .logo-maker-settings {
    width: 270px;
    margin-right: 10px;
  }

  .logo-maker select {
    font-size: 11px;
    height: 34px;
    width: 143px;
  }

  .logo-maker label {
    align-items: flex-end !important;
  }

  .logo-maker input:not([type="radio"]), textarea {
    font-size: 11px;
    padding: 10px;
  }

  .logo-maker .section-sub-title {
    margin-top: 12px;
  }

  #logo-maker-submit-btn {
    padding: 9px;
    margin-top: 0.5em;
  }

  .logo-maker label input[type="text"] {
    width: 41px;
    height: 12px;
  }
}

@media screen and (max-width: 419px) {
  .logo-maker {
    width: min-content;
  }

  #lm-canvas-block {
    margin: 10px 20px 10px 10px;
  }

  #lm-main-canvas {
    width: 240px;
    height: 240px;
  }

  .logo-maker-settings {
    width: 240px;
    margin-right: 10px;
  }

  .logo-maker select {
    font-size: 11px;
    height: 34px;
    width: 143px;
  }

  .logo-maker label {
    align-items: flex-end !important;
  }

  .logo-maker input:not([type="radio"]), textarea {
    font-size: 11px;
    padding: 10px;
  }

  .logo-maker .section-sub-title {
    margin-top: 12px;
  }

  #logo-maker-submit-btn {
    padding: 9px;
    margin-top: 0.5em;
  }

  .logo-maker label input[type="text"] {
    width: 41px;
    height: 12px;
  }
}
