.moduleInvestForm {
    padding-top: 180px;
    width: 75%;
    margin: 0 auto;
    float: none;
}

.projectPage__contentBody .project-editLinks li a {
    width: 100%;
    max-width: 350px;
}

.editBlock--alignedCenter {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0 33px;
}

.editBlock__input {
    padding: 0 20px;
}

.editBlock__input input {
    margin: 0;
}

.editBlock__button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

/*.changeLogoBlock {*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    left: 0;*/
/*    top: 0;*/
/*    transition: opacity .4s ease;*/
/*    opacity: 0;*/
/*    cursor: pointer;*/
/*}*/

.changeLogoBlock {
    position: absolute;
    left: 50%;
    bottom: 0;
    font-size: 12px;
    color: #fff;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0162d0b3;
    width: 100%;
    border-radius: 50%;
    height: 100%;
    letter-spacing: .8px;
    transition: opacity .4s ease;
    opacity: 0;
    cursor: pointer;
}

.changeLogoBlock:hover {
    opacity: 1;
}

/* media queries */

@media screen and (max-width: 1300px) {
    .projectPage__contentBody {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .projectPage__contentBody > .project-page {
        margin: 0 !important;
        padding: 70px 15px 0;
        width: 100% !important;
        max-width: 800px;
    }

    .projectPage__contentBody > .project-page label {
        width: 100% !important;
    }

    .moduleInvestForm > div,
    .moduleInvestForm .principalsAndCollaboratorsDetails {
        width: 100%;
        max-width: 100%;
    }

    .moduleInvestForm.leftSideModule {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding-top: 0;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        max-width: 800px;
    }
}

@media screen and (max-width: 500px) {
    .projectPage__contentBody .project-editLinks li {
        flex-direction: column;
        align-items: flex-start;
    }
}