.siteFooterWrap {
    background-color: white;
    text-align: center;
    color: black;
    width: 100%;
    display: flex;
    flex-direction: row !important;
    /*border-top: 1px solid hsla(0, 0%, 0%, 0.1);*/
}

.siteFooterWrap ul {
    list-style: none;
    margin: 40px 0 30px 2%;
    padding: 0;
}

.siteFooterWrap ul a {
    color: black;
}

.siteFooterWrap ul li {
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    margin-right: 30px;
    font-size: 13px;
    letter-spacing: 1px;
}

.siteFooterWrap ul a:last-child li {
    margin-right: 0;
}

.siteFooterWrap a:hover {
    text-decoration: none;
}

.footer-list {
    display: flex;
    flex-direction: row;
    margin: 40px 0;
    padding: 0;
    justify-content: center;
}

.footer-list a {
   margin-left: 20px;
   text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    font-size: 13px;
    letter-spacing: 1px;
    color: #000;
}

@media screen and (max-width: 680px) {
    .footer-list {
        display: flex;
        flex-direction: row;
        margin: 20px 0;
        padding: 0;
    }

    .footer-list a {
        font-size: 0.5rem;
    }

    .footer-list a:last-child li {
        padding-bottom: 0;
    }
}

@media screen and (max-width: 400px) {

    .footer-list a {
        font-size: 0.3rem;
    }

}
