.myTicketsBars,
.columnContainerOfBuyAndSellBar {
    display: flex;
    justify-content: space-between;
}

.columnContainerOfBuyAndSellBar {
    max-height: 510px;
    overflow-y: scroll;
    margin-right: 5px;
}

/*.columnContainerOfBuyAndSellBar::-webkit-scrollbar {*/
/*    width: 8px !important;*/
/*    background: rgb(1, 98, 208) !important;*/
/*    -ms-overflow-style: none !important;*/
/*    overflow: -moz-scrollbars-none !important;*/
/*    display: block !important;*/
/*    height: auto !important;*/
/*}*/

/*.columnContainerOfBuyAndSellBar::-webkit-scrollbar-track {*/
/*    background-color: #fff;*/
/*}*/

/*.columnContainerOfBuyAndSellBar::-webkit-scrollbar-thumb {*/
/*    background: linear-gradient(to bottom, rgba(35, 64, 166, 1) 0%, rgba(35, 64, 166, 1) 9%, rgba(1, 98, 208, 1) 100%);*/
/*    cursor: pointer !important;*/
/*}*/

/*.columnContainerOfBuyAndSellBar::-webkit-scrollbar {*/
/*    width: 0 !important;*/
/*}*/

/*.columnContainerOfBuyAndSellBar:hover::-webkit-scrollbar {*/
/*    width: 4px !important;*/
/*}*/

.myTicketsBars {
    max-height: 600px;
    padding: 20px;
    width: 40%;
    border-right: 1px solid rgb(218, 225, 233);
}

.myTicketsBuyAndSellBar {
    width: 50%;
}

.listOfBuyAndSellBar {
    list-style: none;
    margin: 0;
    padding: 0;
}

.myTicketsBuyAndSellBar__title {
    color: rgb(6, 103, 208);
    border-bottom: 1px solid rgb(218, 225, 233);
    text-transform: uppercase;
    margin-bottom: 15px;
}

.myTicketsBuyAndSellBar__title h3 {
    font-size: 17px;
    letter-spacing: 1px;
}

.listOfBuyAndSellBar__item {
    font-size: 12px;
    color: #0162d0;
    margin-bottom: 20px;
}

.listOfBuyAndSellBar__item--green {
    color: #28cd6a;
}

.listOfBuyAndSellBar__item--red {
    color: #ff5260;
}

