.downloadToIpfsOrAws__wrapper, .downloadToIpfsOrAws__content {
    height: 100%;
}

.downloadToIpfsOrAws__content label {
    width: 100%;
    height: 80px;
    cursor: pointer;
    display: flex;
    padding: 0;
    margin: 0;
    border: 1px solid #e0e6ed;
    border-radius: 4px;
}

.downloadToIpfsOrAws__content p {
    height: min-content;
    align-self: center;
    margin: 0 auto;
}

.downloadToIpfsOrAws__content img {
    width: 100px;
    margin: 0 auto;
}

.downloadToIpfsOrAws__content__spinner-wrapper {
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.downloadToIpfsOrAws__content__spinner-wrapper {
    align-self: center;
}