.limits-page, .limits-page .page {
    min-height: 100%;
}

.limits-page {
    display: flex;
    flex-flow: column;
}

.settingsHeader nav {
    width: 100%;
    padding-left: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.settingsHeader nav a {
    font-size: 15px;
    display: inline-block;
    padding: 20px;
    font-weight: 400;
    transition: 0.3s all;
    letter-spacing: 0.3px;
    cursor: pointer;
    color: #C0C0C0 !important;
}

.settingsHeader nav a.active {
    color: rgb(6, 103, 208) !important;
    border-bottom: 1px solid rgb(6, 103, 208);
}

.settingsHeader nav a:hover {
    color: black !important;
    text-decoration: none;
}

.settingsWrapper {
    padding: 30px 50px 50px;
}

.settings__email-block {
    margin: 20px 0;
}

.settings__email-block__send-link {
    margin-right: 5px;
    color: var(--darkPrimaryColor);
    transition: 0.2s;
    cursor: pointer;
}

.settings__email-block__send-link:hover {
    color: var(--lightPrimaryColor);
    text-decoration: underline;
}

.agreementCheckbox {
    font-size: 12px;
    width: 90%;
}

.requiredCheckbox input {
    margin-right: 15px;
    margin-top: 5px;
}

.nonAccreditedUL {
    list-style: none;
    margin-left: -40px;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.4px;
    width: 600px;
    margin: 0 auto;
}

.nonAccreditedUL li {
    margin-bottom: 30px;
    font-size: 17px;
    letter-spacing: 0.7px;
    line-height: 30px;
}

.nonAccreditedUL li a {
    color: blue !important;
    cursor: pointer;
}

.settingsWrapper .title {
    font-size: 20px;
    color: #333;
    letter-spacing: 1px;
    font-weight: 300;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.settingsWrapper .subtitle {
    font-weight: 400;
    font-size: 12px;
    width: 40%;
    letter-spacing: 0;
    display: block;
    color: gray;

}

.requiredField {
    color: red !important;
    display: inline-block !important;
    font-size: 12px !important;
    float: right !important;
    padding-top: 3px;
}

.iamaccrediationchoice {
    margin-top: 15px;
    text-align: center;
}

.iamaccrediationchoice a {
    text-align: center;
    padding: 20px;
    width: calc(50% - 10px);
    display: inline-block;
    margin-right: 10px;
    background: #1480e2;
    border-radius: 3px;
    color: white !important;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 14px;

}

.iamaccrediationchoice a.disabled {
    background: white;
    border: 1px solid #1480e2;
    color: #1480e2 !important;
}

input:disabled {
    background-color: #f3f3f3;
    cursor: not-allowed;
}

.uploadButton a {
    cursor: pointer;
    border: 1px solid lightgrey;
    background-color: rgba(125, 149, 182, 0.05);
    padding: 10px 15px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0.5px;
}

.instantChangesCo {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.instantChangesCo ul {
    list-style: none;
    margin-left: -40px;
    padding-top: 10px;
}

.instantChangesCo ul li {
    padding: 20px;
    padding-left: 0 !important;
    padding-right: 0px !important;
    display: inline-block;
    width: 50%;
    vertical-align: top;
}

.changePictureCon {

}

.changePictureCon img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
}

.changePictureText {
    padding-left: 20px;
    margin-top: 0px;
}

.changePictureText span {
    display: block;
    letter-spacing: 0.3px;

    font-weight: 300;
}

.signatureCanvas {
    font-size: 70px !important;
    margin-top: -160px;
    user-select: none;
    color: lightgrey !important;
    width: 500px !important;
    text-align: center;
    margin-bottom: 60px;

}

.clearSignature {
    background: #f3f3f3;
    border-radius: 3px;
    padding: 5px 10px;
    margin-top: -5px;
    font-size: 10px;
    margin-bottom: 1px;
    cursor: pointer;
}


.enable-authenticator .enable-text-group .enable_title,
.changePictureText span:nth-of-type(1) {
    font-weight: 500;
    font-size: 13px;
    color: #333;
}

.enable-authenticator .enable-text-group .enable_desc,
.changePictureText span:nth-of-type(2) {
    font-weight: 300;
    font-size: 13px;
    color: #5a5f6d;
}

.changePictureText span:nth-of-type(3) {
    font-weight: 300;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.uploadButton input {
    outline: none;
    cursor: pointer;
    border: 0px solid lightgrey;
    color: white !important;
    background-color: rgb(6, 103, 208);
    padding: 10px 15px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0.5px;
}

.inputGroups {
    width: 100%;
    padding-top: 30px;
}

.inputGroups p span {
    display: block;
    font-size: 15px;
    color: #5a5f6d;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.inputGroups p span:nth-of-type(2) {
    font-weight: 300;
    font-size: 12px;
}

.inputGroups input {
    width: 400px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    margin-left: 5px;
    letter-spacing: 0px;
    letter-spacing: 0.3px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 300;
    border-radius: 3px;
}

.inputGroups select {
    width: 400px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;
    border-radius: 3px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    letter-spacing: 1px;
    letter-spacing: 0px;
    background-color: white;
    border-radius: 1px;
    letter-spacing: 0.3px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 300;
}


.dashModelWrapper {

}

.settingsWrap nav {
    width: 100%;
    padding-left: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.settingsWrap nav a {
    font-size: 15px;
    display: inline-block;
    padding: 20px;
    font-weight: 400;
    transition: 0.3s all;
    letter-spacing: 0.3px;
    cursor: pointer;
}

.settingsTab {
    color: #C0C0C0 !important;
}

.settingsTabdisabled {
    color: lightgrey !important;
}

.settingsWrap nav .settingsTab:hover {
    color: black !important;
    text-decoration: none;
}

.settingsWrap nav a.activeScreen {
    color: rgb(6, 103, 208) !important;
    border-bottom: 1px solid rgb(6, 103, 208);
}

.settingsWrap nav a.activeScreen:hover {
    color: rgb(6, 103, 208) !important;
    border-bottom: 1px solid rgb(6, 103, 208);
}

.tabSettings {
    padding: 25px;
    padding-top: 30px;
}

.tabSettings .title {
    font-size: 20px;
    color: #333;
    letter-spacing: 1px;
    font-weight: 300;
    padding-bottom: 15px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}


.nextDetailsInput {
    padding: 30px;
    padding-left: 0px;
    padding-bottom: 80px;
    padding-right: 0;
}

.inputGroups {
    width: 100%;
    padding-top: 30px;
}

.mask_choose-btn {
    margin: 20px 10px;
    border: 1px solid #2875c7;
    border-radius: 3px;
    cursor: pointer;
    color: #FFFFFF;
    background-color: #2875c7;
    font-size: 11px;

    max-width: 100px;
}

.mask_textarea {
    width: 200px;
}

.mask_choose-block {
    display: flex;
    flex-direction: column;
}
.inputGroups p span {
    display: block;
    font-size: 15px;
    color: #5a5f6d;
    padding-bottom: 5px;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.inputGroups p span:nth-of-type(2) {
    font-weight: 300;
    font-size: 12px;
}


.inputGroups input:disabled {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: not-allowed;
}

.inputGroups input::placeholder {
    color: lightgrey;
}

.personalDetails {
    padding-bottom: 40px;
}

.personalDetails .leftSide {
    width: 40%;
    float: left;
}

.personalDetails .rightSide {
    width: 60%;
    float: left;
    padding-left: 50px;
}

.clickHere {
    font-size: 12px;
    letter-spacing: 0.3px;
    margin-top: 5px;
    font-weight: 400;

}

.phoneNumber {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 300;
}

.phoneNumber .uploadButton input {
    margin-right: 5px;
}

.phoneNumber .uploadButton,
.phoneNumber__uploadBtn--white {
    display: inline-block;
}

.phoneNumber .uploadButton input,
.phoneNumber__uploadBtn--white input {
    border: 1px solid rgb(6, 103, 208);
}

.phoneNumber__uploadBtn--white input {
    background-color: transparent;
    color: #0667d0 !important;
}

.verified {
    background-color: #50bf82;
    color: white;
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 9px;
    font-weight: 500;
    text-transform: uppercase;
}

.primaryPhone {
    background-color: #3a87ad;
    color: white;
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 9px;
    font-weight: 500;
    text-transform: uppercase;

}

.activeSessions .title {
    font-size: 15px;
    font-weight: 600;
    padding-top: 20px;
    border: 0;
    color: rgb(6, 103, 208);
}

.activeSessions .description {
    font-size: 12.5px;
    margin-top: -10px;
    letter-spacing: 0.5px;
    font-weight: 300;
    border: 0;
    color: grey;
}


.dashTable tr td, .dashTable th {
    padding-left: 0 !important;
    text-align: left !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;

}

.dashTable tr td {
    color: rgba(0, 0, 0, 0.6) !important;
    letter-spacing: 0.3px;
}


.descriptionModel {
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.4px;
    padding-top: 15px;
    padding-bottom: 25px;
    font-size: 13px;
}

.descriptionModel b {
    color: red;
}


.tabtitle {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 1px;
    padding-bottom: 10px;
}

.idActive {
    padding-top: 40px;
    padding-bottom: 40px;
}

.idActive .title {
    font-size: 15px;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.idActive ul {
    margin-left: -20px;
}

.idActive ul li {
    letter-spacing: 0.5px;
    font-weight: 300;
    padding-bottom: 5px;
    font-size: 14px;
}

.subtitleWarning {
    font-size: 14px;
    padding-top: 10px;
}

.leftSideScreen {
    width: 50%;
    padding: 40px;
    padding-top: 0;
    float: left;
    padding-left: 0;

}

.documentRightSide {
    width: 50%;
    float: left;
}

.proofofIDScreen {
    background-color: rgba(6, 103, 208, 0.05);
    height: 200px;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    cursor: pointer;
}

.proofofIDScreen img {
    width: 70px;
    opacity: 0.3;
}

.documentRightSide label {
    font-weight: 600;
    margin-top: 7px;
}

.documentRightSide select {
    width: 300px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;
    border-radius: 3px;
    padding-left: 14px;
    padding-right: 80px;
    font-size: 13px;
    letter-spacing: 1px;
    letter-spacing: 0px;
    background-color: white;
    border-radius: 1px;
    letter-spacing: 0.3px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 300;
}


.documentRightSide input {
    width: 300px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;
    border-radius: 3px;
    padding-left: 14px;
    padding-right: 80px;
    font-size: 20px;
    letter-spacing: 1px;

}


.titles {
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    padding-bottom: 10px;
    font-size: 15px;

}

.descriptions {
    font-size: 12px;
    letter-spacing: 0.5px;
}

.verificatonSuccessWrapper {
    padding-top: 100px;
}

.titleV {
    text-align: center;
    font-weight: 900;
    font-size: 28px;
    padding-top: 50px;
    padding-bottom: 100px;
}

.cls-1 {
    fill: none;
    stroke: #231f20;
    stroke-miterlimit: 10;
    stroke-width: 5px;
}

#checkmark {
    margin: auto;
    width: 150px;
    height: 150px;
    display: block;

}

#checkmark .cls-1 {
    stroke: green;
}

.circle {
    stroke-dasharray: 700;
    stroke-dashoffset: 700;
}

.checkmark {
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
}

.run-animation .circle {
    -webkit-animation: 2.5s circleDraw forwards;
    animation: 2.5s circleDraw forwards;
}

.run-animation .checkmark {
    -webkit-animation: 0.75s checkmarkDraw forwards;
    animation: 0.75s checkmarkDraw forwards;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.stateSelect {
    margin-left: 4px;
    border-radius: 3px !important;
}

@-webkit-keyframes circleDraw {
    from {
        stroke-dashoffset: 700;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes circleDraw {
    from {
        stroke-dashoffset: 700;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes checkmarkDraw {
    from {
        stroke-dashoffset: 150;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes checkmarkDraw {
    from {
        stroke-dashoffset: 150;
    }
    to {
        stroke-dashoffset: 0;
    }
}


.phoneNumb {
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 15px;
}

.settingsWrapper .description {
    font-size: 12.5px;
    margin-top: -10px;
    letter-spacing: 0.5px;
    font-weight: 300;
    border: 0;
    color: grey;
}

.dashTable {
    width: 100%;
}

.dashTable tr th {
    font-weight: 800;
    color: #333;
    font-size: 13px;
    padding: 10px;
    padding-left: 0;
    border-bottom: 1px solid #e5ecf4;
}

.dashTable tbody tr td:first-child {
    padding-left: 15px;
}

.dashTable tbody tr td:last-child {
    padding-right: 15px;


}

.dashTable tbody tr td:last-child span {
    display: block;
}

.dashTable tbody tr td {
    font-size: 13px;
    color: black;
    padding: 9px;
    padding-left: 0;
    border-bottom: 1px solid #e5ecf4;
    padding-bottom: 10px;
}

.dashTable td:last-child, .dashTable th:last-child {
    text-align: right;
}


.validingStatusWrap {
    padding-top: 50px;
    padding-bottom: 100px;
    text-align: center;
}

.validingStatusWrap .title {
    text-align: center;
    font-weight: 800;
    font-size: 30px;
    letter-spacing: 1px;
    padding-top: 50px;
    padding-bottom: 10px;
}

.validingStatusWrap .title.green {
    color: rgb(20, 191, 6);
    letter-spacing: 1px;
}

.validingStatusWrap .description {
    text-align: center;
    font-weight: 300;
    font-size: 15px;
    color: #333;
    letter-spacing: 1px;
}


.validingStatusWrap .image img {
    width: 200px;
}

.validingStatusWrap .uploadButton a {
    text-decoration: none;
}

.bttmFooterPaginate ul {
    list-style: none;
    margin-left: -40px;
    margin-bottom: 0;
    float: right;
}

.bttmFooterPaginate ul li {
    display: inline-block;
    width: 30px;
    text-align: center;
    cursor: pointer;
}

.bttmFooterPaginate ul li.active {
    background-color: #2567e5;
    color: white;
}

.bttmFooterPaginate ul li.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.bttmFooterPaginate ul li:first-child {
    width: 80px;
    padding-right: 25px;
    text-align: right;
}

.bttmFooterPaginate ul li:last-child {
    width: 40px;
    text-align: right;
}


.sigCanvas {
    border: 1px solid gray;
}

.steps23c {
    color: rgb(0, 106, 208);
    font-size: 14px;
    margin-top: -24px;
    font-weight: 500;
}

.billingDetailsWrap {
    border-left: 3px solid rgb(0, 106, 208);
    height: 100%;
    padding-left: 35px;
    position: relative;
    margin-left: -15px;
    margin-top: 20px;
}

.verifcationWrapper {
    border-left: 0 solid #152664;
    height: 100%;
    padding-left: 25px;
    position: relative;
    margin-left: -25px;
    padding-top: 20px;
}

.info_about-block {
    margin-bottom: 20px;
}
.navigationTop {
    position: absolute;
    top: 0px;
    left: -18px;
    width: 35px;
    height: 35px;
    border-radius: 40px;
    background: white;
    border: 2px solid rgb(0, 106, 208);
    text-align: center;
    color: rgb(0, 106, 208);
    padding-top: 2px;
}

.navigationTop span {
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    margin-left: -2px;
}

.verifcationWrapper .navigationTop {
    top: 20px;
    left: -28px;
}

.rectanglewhitehide {
    position: absolute;
    top: 60px;
    left: -15px;
    width: 10px;
    bottom: 0;
    background: white;
    z-index: 12312312;
}

.addNewPhoneWrapper {
    width: 100%;
    padding: 30px;
    text-align: center;
}

.addNewPhoneWrapper .country-list {
    max-width: 100%;
}

.addNewPhoneWrapper .numberInput {
    background-color: transparent;
    letter-spacing: 3px;
    color: #555 !important;
    padding: 9px !important;
    height: 42px;
    text-align: left;
    cursor: auto;
    margin-bottom: 0;
}

.addNewPhoneWrapper__descr {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);
}

.addNewPhoneWrapper__img {
    width: 100%;
    max-width: 80px;
    margin: 10px auto;
}

.addNewPhoneWrapper__img img {
    width: 100%;
}

.addNewPhoneWrapper .uploadButton > a {
    display: block;
    cursor: pointer;
    color: #fff !important;
    border-radius: 3px;
    padding: 11px;
    font-size: 11px;
    text-transform: uppercase;
    height: 42px;
    letter-spacing: .25em;
    background: #0162D0;
    border: none;
}

.addNewPhoneWrapper .flag-container {
    position: static;
    width: auto !important;
    background-color: transparent;
}

.addNewPhoneWrapper .intl-tel-input.allow-dropdown {
    width: 100%;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 3px;
    position: relative;
    margin-bottom: 10px;
}

.addNewPhoneWrapper .uploadButton {
    margin-top: 10px;
}

.addNewPhoneWrapper .title {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 1px;
    line-height: normal;
}

.lockedWrapper {

    text-align: center;
    padding: 30px;
    padding-top: 70px;
    padding-bottom: 70px;

}

.lockedWrapper i {
    font-size: 30px;
}

.lockedWrapper .lockedTitle {
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 34px;
    padding-top: 30px;
}

.lockedWrapper .locekdDescr {
    font-weight: 500;
    letter-spacing: 0.3px;
    font-size: 15px;
}

.auth-modal {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    margin: auto;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.auth-modal .two-step_first-wrapper {
    z-index: 2;
    text-align: center;
    margin-top: 250px;
    padding: 10px;
}

.auth-modal .show-code {
    display: flex;
    align-items: center;
    flex-flow: column;
    padding: 20px 20px 0px 20px;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);

    font-size: 11px;
    letter-spacing: .05em;
    text-align: left;
    line-height: 1.5;
    color: rgb(5, 15, 25);
    word-break: break-word;
}

.auth-modal .show-code ul {
    margin: 10px 0 10px;
}

.auth-modal .code-input {
    margin-bottom: 20px;
    margin-right: 10px;
    border-radius: 3px;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.3);;
    /*width: 100%;*/
}

.auth-modal .qr_desc {
    font-size: 13px;
    margin-top: 20px;
}

.auth-modal .two-step_header {
    padding: 10px 0 10px 10px;
    text-align: left;
}

.auth-modal .two-step_title {
    font-weight: normal;
    margin: 0 0 20px 0;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

.auth-modal .two-step_first-wrapper .two-step_footer {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    padding: 20px;
}

.auth-modal .two-step_first-wrapper .two-step_footer.footer-manager {
    padding: 0 10px;
}

.auth-modal .two-step_first-wrapper .two-step_footer.footer-manager .enable-text-group {
    display: flex;
    flex-flow: column;
}

.show-pop-up-authenticator,
.auth-modal .two-step_first-wrapper .two-step_footer .btn-two-step {
    cursor: pointer;
    color: #000000;
    border-radius: 3px;
    border: 1px solid #b3b3b3;
    height: 39px;
    padding: 10px;
    text-transform: uppercase;
    font-size: 11px;
    background: rgba(125, 149, 182, 0.05);
}

.auth-modal .enter-code {
    padding: 20px 20px 0 20px;
    background: #FAFAFA;
    height: auto;
}

.auth-modal .enter-code .controls {
    text-align: center;
    margin-left: 0;
}

.auth-modal .enter-code .controls .unput-two-step {
    margin-left: auto;
    margin-right: auto;
}

.auth-modal .enter-code .controls .unput-two-step input {
    width: 105px;
}

input.red {
    border: 1px solid #ff0000;
}

.auth-modal .enter-code .controls .unput-two-step img {
    height: 42px;
    width: 42px;
    margin-right: 10px;
}

.auth-modal .two-step_first-wrapper .two-step_footer .btn-two-step.blue {
    background: #006ad0;
    border: 1px solid #006ad0;
    color: #FFFFFF;
}

.auth-modal .enable-authenticator {
    width: 100%;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    margin: 0;
}

.enable-authenticator {
    /*background: #d3d3d3;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    border-radius: 3px;
}

.enable-authenticator:not(:last-child) {
    margin-bottom: 30px;
}

.enable-authenticator .enable-text-group {
    display: flex;
    flex-flow: column;
}

.enable-authenticator .enable-text-group .enable_title {
    margin-right: 15px;
}

.enable-authenticator .enable-text-group .enable_desc.green {
    color: rgb(20, 191, 6);
}

.twoFactorAuth,
.twoFactorAuth .twoFactor2 {
    display: flex;
}

.twoFactorAuth .twoFactor1 {
    margin-right: 20px;
}

.twoFactorAuth .twoFactor1,
.twoFactorAuth .twoFactor1 {
    width: 50%;
}

.twoFactorAuth .twoFactor1 .enable-authenticator {
    width: 100%;
    max-width: 400px;
}

.twoFactorAuth .twoFactor2 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

input.numberInput::-webkit-outer-spin-button,
input.numberInput::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input.numberInput {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
}

.not-verify-completed__button {
    width: 100%;
    padding-top: 0;
    background-color: inherit;
}

.verify-completed__button {
    width: 100%;
    padding-top: 0;
    background-color: rgb(6, 103, 208);
}

.step-two {
    margin-top: 5px;
}

.state__block {
    width: 100%;
    display: flex;
}

.documentRightSide__inputWrapper {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.documentRightSide__inputWrapper > label {
    margin-right: 10px;
}

.documentRightSide__inputWrapper select {
    width: 100%;
    max-width: 400px;
}

.accountActivityTableSettings__pagination {
    display: flex;
    justify-content: space-between;
    padding: 9px 0;
    border-bottom: 1px solid #e5ecf4;
}

.accountActivityTableSettings__pagination > span {
    color: rgba(0, 0, 0, 0.6) !important;
    letter-spacing: 0.3px;
    font-size: 13px;
}

.accountActivityTableSettings__pagination > div ul {
    letter-spacing: 0.3px;
    font-size: 13px;
}

.accountActivityTableSettings__table {
    min-height: 375px;
}

.accountActivityTableSettings__table::-webkit-scrollbar:horizontal {
    width: 5px !important;
}

.accountActivityTableSettings__filters {
    margin: 10px 0;
}

.accountActivityTableSettings__filters > input {
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    padding: 0 7px 0 7px;
    outline: none;
    margin-right: 5px;
    font-size: 13px;
}

.accountActivityTableSettings__filters > label {
    margin: 0 5px 0 0;
    color: #0162d0;
}

.settingsPage {
    min-height: calc(100% - 125px - 94px)
}

.changePasswordContainer p a {
    margin-top: 10px;
    margin-left: 20px;
    display: block;
    width: max-content;
}

@media screen and (max-width: 1330px) {

    .settingsPage .seperator {
        width: 0 !important;
    }

    .settingsPage .contentPage {
        width: 100% !important;
    }

}

@media screen and (max-width: 1090px) {
    .settingsPage .contentPage .personalDetails .leftSide {
        width: 20%;
    }

    .settingsPage .contentPage .personalDetails .rightSide {
        width: 80%;
    }


    .verifyAccountWrap {
        padding-left: 10px;
        padding-right: 10px;
    }

    .verifyAccountWrap .activeSessions div {
        padding-left: 40px;
        padding-right: 50px !important;
    }

    .subtitleWarning {
        text-align: center;
    }


}

@media screen and (max-width: 1150px) {
    .accrediationWrapper {
        width: 100% !important;
        float: none !important;
        padding: 0 !important;
        margin: 0 auto !important;
        padding-right: 10px !important;
    }

    .accrediationWrapper2 {
        width: 500px !important;
        margin: 0 auto !important;
        float: none !important;
    }
}


@media screen and (max-width: 1000px) {
    .verifyAccountWrap .leftSideScreen {
        width: 70% !important;
        float: none !important;
        padding: 0;
        margin: 0 auto;
    }

    .verifyAccountWrap .documentRightSide {
        width: 70% !important;
        float: none !important;
        padding: 0;
        margin: 0 auto;
    }
}


@media screen and (max-width: 990px) {
    .settingsPage .contentPage .settingsWrapper {
        /*padding-left: 20px;*/
        padding-right: 20px;
        /*margin-left: -25px;*/
    }

}


@media screen and (max-width: 940px) {
    .verifyAccountWrap {
        padding-left: 10px;
        padding-right: 10px;
    }

    .verifyAccountWrap .activeSessions div {
        padding-left: 20px;
        padding-right: 20px !important;
    }


}

@media screen and (max-width: 960px) {
    .settingsPage .contentPage .settingsWrapper .instantChangesCo ul li {
        width: 100% !important;
        display: block;
        margin-bottom: 35px;
        padding-left: 0 !important;
    }

    .changePasswordContainer p {
        padding-left: 0;
        margin-left: 0 !important;
    }

    .changePasswordContainer p a {
        margin-left: 0
    }
}


@media screen and (max-width: 920px) {
    .settingsPage .contentPage .settingsWrapper .instantChangesCo ul li:last-child {
        margin-bottom: 0;
    }

    .settingsPage .contentPage .settingsWrapper .changePictureText {
        padding-left: 0;
    }

    .accountActivityTableSettings {
        overflow-x: scroll;
    }

    .accountActivityTableSettings table {
        width: 1000px;
    }

    .verify-completed__button > span{
        font-size: 10px !important;
    }
}

@media screen and (max-width: 900px) {
    .settingsPage .contentPage .personalDetails .leftSide {
        display: none;
    }

    .settingsPage .contentPage .personalDetails .rightSide {
        width: 100%;
        padding-left: 0;
    }

    .verifyAccountWrap .leftSideScreen {
        width: 90% !important;
        float: none !important;
        padding: 0;
        margin: 0 auto !important;
    }

    .verifyAccountWrap .documentRightSide {
        width: 90% !important;
        float: none !important;

        margin: 0 auto !important;
    }

    .settingsWrapper .subtitle {
        width: 100%;
    }
}

@media screen and (max-width: 840px) {
    .verifyAccountWrap {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .verifyAccountWrap .activeSessions div {
        padding-left: 20px;
        padding-right: 0px !important;
    }

    .uploadButton input {
        margin-right: 10px;
    }

    .proofofIDScreen {
        height: auto;
    }
}


@media screen and (max-width: 800px) {
    .settingsPage .contentPage .nextDetailsInput {
        padding-bottom: 40px;
    }

    .settingsPage .contentPage .nextDetailsInput .inputGroups .pull-left {
        width: 100%;
        float: none;
    }

    .settingsPage .contentPage .nextDetailsInput .inputGroups .pull-right {
        width: 100%;
        float: none;
        margin-left: -5px;
    }

    .settingsPage .contentPage .nextDetailsInput .inputGroups .pull-right input {
        width: 100%;
    }

    .verifyAccountWrap .activeSessions div {
        width: 100% !important;
    }

    .settingsHeader nav {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .settingsHeader nav a {
        padding: 10px;
    }

    .settingsHeader nav a.pull-right {
        float: none;
    }

    .documentRightSide__inputWrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .settingsPage .contentPage {
        padding: 20px;
    }

    .settingsWrapper {
        padding: 40px;
    }

    .auth-modal {
        height: 102vh;
    }
}

@media screen and (max-width: 680px) {
    .auth-modal {
        height: 105.5vh;
    }
}

@media screen and (max-width: 665px) {

    .Preferences .documentRightSide select {
        width: 100% !important;
    }

    .postalSelect {
        width: 100% !important;
    }


}

@media screen and (max-width: 620px) {
    .settingsPage .contentPage {
        padding-left: 0px;
        padding-right: 0px;
    }

    .settingsPage .contentPage .inputGroups select, .settingsPage .contentPage .inputGroups input {
        width: 100% !important;
        margin-bottom: 10px;
    }

    .settingsPage .contentPage .inputGroups .pull-left {
        width: 100% !important;
    }

    .settingsPage .contentPage .inputGroups .pull-right {
        width: 100% !important;
        margin-top: 10px;
    }

    .changePasswordContainer .uploadButton {
        margin-top: 20px;
        float: left;
    }

    .validingStatusWrap {
        padding-left: 20px;
        padding-right: 20px;
    }

    .validingStatusWrap .image img {
        widows: 150 !important;
    }

    .documentRightSide select {
        width: 100%;
    }

    .documentRightSide input {
        width: 100%;
    }

    .twoFactor1 {
        float: none !important;
        width: 100% !important;
    }

    .twoFactor2 {
        float: none !important;
        width: 100% !important;
        margin-top: 40px;
    }

    .iamaccrediationchoice {
        margin-left: -20px;

    }

    .accrediationWrapper {
        width: 100% !important;
        float: none !important;
        padding: 0 !important;
        margin: 0 auto !important;
        padding-right: 0px !important;
        margin-left: -20px !important;
    }

    .accrediationWrapper2 {
        width: 100% !important;
        margin: 0 auto !important;
        float: none !important;
    }

    .settingsHeader nav {
        padding-left: 10px;
    }

    .settingsHeader nav a {
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .settingsWrapper .subtitle {
        width: 200px;
    }

    .twoFactorAuth, .twoFactorAuth .twoFactor2 {
        flex-direction: column;
    }

    .changePasswordContainer .uploadButton {
        margin-left: 10px;
    }

    .state__block {
        flex-direction: column;
    }

    .auth-modal {
        height: 117.5vh;
    }
}

@media screen and (max-width: 540px) {
    .accountActivityTableSettings__filters {
        display: flex;
        flex-direction: column;
    }
}


@media screen and (max-width: 520px) {
    .settingsHeader nav {
        justify-content: flex-start !important;
    }

    .helpUsFormField {
        width: 100% !important;
    }

    .stateSelect, .inputGroups input, .input50 input {
        margin-left: 0 !important;
    }

    .helpUsFormWrapperDescription, .uploadPhotoStatusBlock__documentsList li {
        font-size: 12px;
        width: 100%;
    }

    .lockedWrapper {
        padding: 0;
    }

    .lockedWrapper .lockedTitle {
        font-size: 25px;
    }

    .lockedWrapper .locekdDescr {
        font-size: 13px;
    }

    .phoneNumber {
        display: flex;
        flex-direction: column;
    }

    .phoneNumber__number-block {
        display: flex;
        flex-direction: column;
        width: 70%;
        text-align: center;
        margin: 0 auto;
    }

    .phoneNumb, .verified, .primaryPhone {
        margin-left: 0;
        margin-top: 10px;
    }

    .uploadButton__block {
        width: 70%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    .uploadButton input {
        width: 100%;
    }

    .phoneNumber__uploadBtn--white {
        margin-top: 10px;
    }

    .changePasswordContainer {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: 0 auto;
    }

    .changePasswordContainer p {
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
    }

    .changePasswordContainer p a {
        margin: 20px auto 0;
    }

    .mobile-title {
        text-align: center;
    }

    .auth-modal {
        height: 126.5vh;
    }
}

@media screen and (max-width: 520px) {
    .auth-modal {
        height: 130.5vh;
    }

    .accountActivityTableSettings__pagination {
        flex-direction: column;
    }

    .bttmFooterPaginate {
        width: max-content;
        margin-top: 10px;
    }
}

@media screen and (max-width: 430px) {
    .auth-modal {
        height: 132vh;
    }
}

@media screen and (max-width: 415px) {
    .auth-modal {
        height: 134.5vh;
    }
}

@media screen and (max-width: 390px) {
    .settingsWrapper {
        padding-left: 40px;
    }

    .addNewPhoneWrapper {
        padding: 30px 0;
    }

    .billingDetailsWrap {
        padding-left: 25px;
    }

    .verifcationWrapper .navigationTop {
        left: -19px;
    }

    .billing-title {
        display: flex;
        flex-direction: column;
        padding-top: 2px;
    }

    .steps23c {
        font-size: 13px;
    }

    .step-one {
        position: absolute;
        top: 34px;
        right: 0;
    }

    .step-two, .step-three {
        margin-top: 0;
        position: absolute;
        top: 28px;
        right: 0;
    }

    .phoneNumber__uploadBtn--white input {
        padding: 8px;
    }
}

@media screen and (max-width: 350px) {
    .auth-modal {
        height: 137.5vh;
    }
}


