@import "src/assets/style/colors_fonts"
.wallyHome_wrap
  background-image: url("/static/media/background-grey.2f82a862.png") !important
  background-size: 20%
  background-repeat: repeat
  padding: 0 5%


.pwallet_p
  max-width: 100% !important
  width: 100% !important
  text-align: center !important
.wallyHome_container
  padding: 20px 0 20px 0
  color: black
  @include flex-center-sb
  max-width: 1200px
  width: 100%
  margin: 0 auto
  overflow: auto
  height: calc(100vh - 240px)

.wallyHome_block-flex
  display: flex
  width: 80%
  //padding: 50px 100px
  justify-content: space-around
  flex-direction: row

.wallyHome_block-flex-column
  display: flex
  flex-direction: column
  align-items: center
  button
    width: 80%
    padding: 10px 10px !important
  h4
    font-size: 14px
    font-weight: bold
    color: black
    margin: 30px 0 -2px 0



.wallyHome_block
  color: black
  @include flex-all-center
  overflow: auto
  flex-direction: column
  background-color: white
  border-radius: 10px
  height: 100%
  width: 100%
  margin: 0 auto

  p
    max-width: 90%
  img
    width: 100px
    height: 100px
    margin-bottom: 30px
  .blue-btn
    margin-top: 20px
    margin-bottom: -20px
    max-width: 170px !important

.main_wallet_page
  justify-content: flex-start
  padding-top: 30px

@media screen and  (min-width: 1200px)
  .wallyHome_wrap
    background-size: 20% !important

@media screen and  (min-width: 500px)
  .wallyHome_wrap
    background-size: 35%


@media screen and  (max-width: 500px)
  .wallyHome_wrap
    padding: 0

  .wallyHome_container
    overflow: hidden
    width: 100%
  .wallyHome_block-flex
    flex-wrap: wrap !important
    .wallyHome_block-flex-column
      margin: 30px 0
  //   &:last-child
  //padding-bottom: 60px

  .wallyHome_block
    height: 100%
    width: 100%
    //background-color: red
    overflow-y: auto
    //margin: 150px 0 300px 0
    overflow-x: hidden
    padding: 30px 0

    //padding: 450px 50px 330px  50px
    img
      padding-top: 150px
    h2
      max-width: 70%

@media screen and  (max-width: 400px)
  .wallyHome_block
    img
      padding-top: 300px






//password
.pswd-input__block
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 20px


.pswd-input__block label, .terms-of-use p
  color: #5b5b5b


.pswd-input__block input
  width: 300px
  margin-top: 9px
  border: 2px solid #BBC0C5
  height: 48px
  padding: 0 16px
  display: flex
  align-items: center
  border-radius: 6px
  color: rgba(0, 0, 0, 0.87)
  position: relative
  font-size: 1rem
  font-family: "Roboto", "Helvetica", "Arial", sans-serif
  line-height: 1.1875em


.pswd-input__block input:focus
  border: 1px solid #0563d0


.error input
  border: 2px solid red


.terms-of-use
  display: flex
  margin-top: 20px


.terms-of-use input
  height: 34px
  width: 34px


.terms-of-use p
  padding-top: 5px
  margin-left: 10px
  font-size: 14px


.terms-of-use a
  color: #2f9ae0bf


.password_check_container
  display: flex


.password_check_block-green
  width: 71px
  height: 33px
  background-color: limegreen
  display: flex
  justify-content: center
  align-items: center
  border-radius: 5px
  margin: 0 5px 0 0
  color: white
  font-size: 11px



.password_check_block-red
  color: white
  width: 71px
  height: 33px
  font-size: 11px
  background-color: red
  display: flex
  justify-content: center
  align-items: center
  border-radius: 5px
  margin: 0 5px 0 0






.backupPhrase__wrapper
  //width: 740px
  display: flex
  align-items: center
  margin: 0 auto
  color: black
  padding: 0 10%
  height: 100%
  overflow-y: hidden
  overflow-x: hidden


.backupPhrase__wrapper header
  margin-bottom: 40px


.backupPhrase__content-wrapper
  display: flex



.backupPhrase__content-wrapper p
  font-size: 14px
  line-height: 1.3


.backupPhrase__content-left
  //flex-shrink: 0.4
  p
    word-break: break-word
    max-width: 100% !important


.backupPhrase__content-right
  flex-shrink: 0.8
  margin-top: 58px
  margin-left: 20px


.backupPhrase__words-wrapper
  color: #4d4d4d
  box-sizing: border-box
  position: relative
  display: flex
  justify-content: center
  border: 1px solid #CDCDCD
  border-radius: 6px
  margin-top: 36px
  max-width: 350px
  padding: 20px
  min-height: 120px
  font-size: 18px
  text-align: center


.backupPhrase__words-lock
  position: absolute
  top: 0
  bottom: 0
  height: 100%
  width: 100%
  background-color: rgba(65, 121, 196, 0.95)
  filter: blur(10%)
  //background-color: red
  display: flex
  flex-flow: column nowrap
  align-items: center
  justify-content: center
  padding: 18px 0 43px 0
  cursor: pointer

  color: white
  border-radius: 6px


.backupPhrase__words-lock p
  font-size: 14px
//margin-top: 10px
//letter-spacing: -0.5px


.downloadCodeLink
  color: #2f9ae0bf
  cursor: pointer


.downloadCodeLink:hover
  color: #0563d0


@media screen and  (max-width: 800px)
  .backupPhrase__content-wrapper
    flex-wrap: wrap
  .backupPhrase__content-right
    flex-shrink: 1 !important
    margin-top: 58px

  //margin-left: 20px
  .backupPhrase__wrapper

    overflow-y: auto
    overflow-x: hidden



.confirmPhrase__wrapper
    width: 95%
    max-width: 650px
    margin: 0 auto
    color: black

.confirmPhrase__wrapper header
  margin-bottom: 30px

.confirmPhrase__wrapper h1
  font-size: 34px

.confirmPhrase__field
  direction: ltr
  display: flex
  flex-flow: row wrap
  min-height: 161px
  max-width: 586px
  border: 1px solid #CDCDCD
  border-radius: 6px
  background-color: #fff
  margin: 20px 0 10px
  padding: 12px

.confirmPhrase__word-grid
  display: flex
  flex-wrap: wrap
  padding: 0 14px
  margin-bottom: 10px

.confirmPhrase__word, .confirmPhrase__word-active
  color: #037DD6
  flex-flow: row nowrap
  align-items: center
  justify-content: center
  padding: 8px 18px
  width: 128px
  height: 41px
  text-align: center
  border-radius: 4px
  cursor: pointer
  margin: 6px
  background: #fff
  border: 2px solid #B0D7F2
  font-size: 16px


.confirmPhrase__word-active
  color: white
  background: #0563d0
  border-color: #0563d0


.confirmPhrase__word:hover
  border-color: #037DD6


.backButton
  background: none
  border: none
  cursor: pointer
  padding: 5px 10px
  font-size: 16px

.backButton:hover
  color: #0563d0

.wallet-btn
  margin-left: 0 !important


.wally-select
  margin-top: 30px
  padding: 10px
  cursor: pointer
  border: 1px solid rgba(0, 0, 0, 0.1)
  color: rgba(0, 0, 0, 0.8)
  border-radius: 3px

.import-wallet-input
  border: 1px solid #e0e6ed
  width: 90%
  //min-height: 100px
  padding: 10px
  margin: 40px
  border-radius: 5px


.import-wallet-ta
  border: 1px solid #e0e6ed
  width: 90%
  min-height: 100px
  padding: 10px
  margin: 40px
  border-radius: 5px

.wallet_psw_back
  height: 20px !important
  width: 30px !important
  position: absolute
  top: 10px
  left: 10px
  cursor: pointer


.unlock_wallet_ing
  transition-duration: 2s

.copy-btn 
  background-color: #0064cd
  border-radius: 3px
  margin-top: 10px !important
  color: white !important
  padding: 0 10px 0 10px
  border: none
  outline: none
  cursor: pointer