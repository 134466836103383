.myTicketsListContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 20px;
}

.myTicketsListItemInfo {
    display: flex;
    align-items: center;
}

.myTicketsListItemInfo__title {
    font-size: 15px !important;
    width: 70px !important;
}

.myTicketsList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.myTokensList {
    list-style: none;
    padding: 5px;
    margin: 0;
    /*background-color: #00ad7c;*/
    display: flex;
    align-items: center;
    min-height: 80px;
    /*padding: 10px 25px 10px;*/
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    position: relative;
    cursor: pointer;
    justify-content: space-between;
}

/*.myTokensList .myTickets_flex{*/
/*    width: 60px !important;*/
/*}*/
.myTokensList .control-buttons__block {
    width: 165px !important;
    /*background-color: #0c48c8;*/
}

.myTokensList button {
    /*width: 60px !important;*/
}

.myTicketsListContainer__header {
    /*color: rgb(6, 103, 208);*/
    cursor: pointer;
    padding: 20px 20px 18px;
    border-bottom: 1px solid rgb(218, 225, 233);
}

.myTicketsListItem {
    display: flex;
    align-items: center;
    min-height: 80px;
    padding: 10px 25px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    position: relative;
    cursor: pointer;
    justify-content: space-between;
}

.myTicketsListItem--active {
    position: relative;
}

.myTicketsListItem::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 1px;
    width: 0;
    background-color: #0162d0;
    transition: width .4s ease;
}

.myTicketsListItem:hover::before {
    width: 100%;
}

.myTicketsListItem--active > .myTicketsListItemInfo > div {
    color: #0162d0;
}

.myTicketsListItem--active::before {
    width: 100%;
}

.myTickets_flex {
    display: flex;
    justify-content: space-between;
}

.myTicketsListItem__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    margin-right: 15px;
    max-height: 45px;
    /*background-color: #0162d0;*/
    border: 2px solid #0162d0;
    border-radius: 50%;
}

.myTicketsListItem__logo_wout_border {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    max-height: 45px;
    /*background-color: #0162d0;*/
    /*border: 2px solid #0162d0;*/
    border-radius: 50%;
}

.myTokens_pairs__right img {
    width: 30px;
    height: 30px;
    border: 1px solid #0064b4;
    border-radius: 30px;
    margin-right: 5px;
}

.fnbd__logo img {
    border-radius: 45px;
    width: 45px !important;
    /*height: 55px;*/
    max-width: 45px !important;
    max-height: 42px !important;
}

.tickets-round {
    min-width: 45px !important;
    max-height: 45px !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;

}


.myTicketsListItem__logo img {
    max-width: 35px;
    max-height: 35px;
}

.myTicketsListItem__logo_wout_border img {
    max-width: 47px;
    max-height: 47px;
}

.myTicketsListItemInfo__title,
.myTicketsListItemInfo__value {
    color: rgb(78, 92, 110);
    letter-spacing: 0.5px;
}

.tickets-round_descr {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.myTicketsListItemInfo__title {
    font-size: 17px;
    width: 60px;
}

.myTokens_pairs__right {
    width: 20%;
    /*background-color: red;*/
    /*min-width: 100px;*/
    /*background-color: #0b2c7e;*/
}

.myTokens_pairs__arrow {
    width: 30%;
    /*background-color: red;*/
}

.myTicketsListItemInfo__value {
    font-size: 12px;
    display: flex;
    align-items: center;
    /*background-color: #0a6ffd;*/
    justify-content: center;
    margin-left: 10px;
    flex-direction: column;
    width: 80px;
}


.myTicketsListItemInfo__royalty_block {
    font-size: 12px;
    display: flex;
    align-items: center;
    /*background-color: #0a6ffd;*/
    justify-content: flex-end;
    margin-left: 30px;
    flex-direction: column;
    width: 80px;
}

.myTicketsListItemInfo__royalty_block button {
    width: 69px;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #0162d0;
    font-size: 12px;
    margin: 3px;
    padding: 0;
    cursor: pointer;
}

.myTicketsListItemInfo__value .myTicketsListItemInfo__balance {
    display: none;
}

.myTicketsListItemInfo__value .myTicketsListItemInfo__supply {
    color: blue;
}

.myTicketsListItemInfo__value .myTicketsListItemInfo__presell {
    /*margin-left: 30px;*/
    color: red;
}

.myTicketsListContainer > .pagination {
    margin: auto auto 20px;
}

.control-buttons__block {
    display: flex;
    flex-wrap: wrap;
    min-width: 150px;
    max-width: 150px;
}

.control-buttons__block button {
    width: 69px;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #0162d0;
    font-size: 12px;
    margin: 3px;
    padding: 0;
    cursor: pointer;
}

.control-buttons__block button:active, .control-buttons__block button:focus {
    outline: none;
}

.myTicketsListItemInfo__value__controls-block {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

.myTicketsListItemInfo__value__controls-block button {
    width: 69px;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #0162d0;
    font-size: 12px;
    margin: 3px;
    padding: 0;
    cursor: pointer;
}

@media screen and (max-width: 1490px) {
    .myTicketsListItem {
        flex-wrap: wrap;
    }

    .control-buttons__block {
        margin-top: 15px;
    }
}
