.dropDownListWrapper {
    overflow-y: visible;
    position: relative;
    padding-bottom: 50px;
    transition: max-height 2s ease-in-out;
}

.dropDownList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 10px 0 0 30px;
    margin: 0;
}

.dropDownList__item {
    /*display: flex;*/
    position: relative;
    /*padding-left: 20px;*/
    /*padding-right: 5px;*/
    /*width: 50%;*/
    font-size: 14px;
    color: gray;
    /*margin-bottom: 30px;*/
}

.dropDownList__item span {
    /*position: absolute;*/
    /*left: 35px;*/
    /*top: 50%;*/
    transform: translateY(-50%);
    /*width: 100%;*/
    /*max-width: 300px;*/
    line-height: 1.5;
    /*height: 50px;*/
    /*padding-left: 10px;*/
    /*padding-top: 4px;*/
}

.dropDownList__item span img {
   /*width: 40px;*/
   height: 40px;
    padding-right: 20px;
    /*position: absolute;*/
    /*left: -33px;*/
}

.dropDownListInfo__title {
    font-size: 16px;
    margin: 20px 0;
    padding-left: 30px;
}

.dropDownList__item--active {
    color: #0162d0;
}

.dropDownListWrapper__showBtn {
    border: none;
    cursor: pointer;
    color: #0162d0;
    font-weight: 600;
    padding: 0;
    position: absolute;
    margin: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    text-align: left;
    outline: none !important;
}
