.reserveLetters__input input::-webkit-outer-spin-button,
.reserveLetters__input input::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.reserveLetters__input input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.section-input-wrap .reserveLetters__input input:not([type="radio"]) {
    display: inline-block;
    width: 100%;
    max-width: 20px;
    background-color: transparent;
    border-radius: 0;
    padding: 0 5px;
    border-color: #8195b0;
}

.reserveLetters {
    color: #8195b0;
    font-size: 15px;
    margin-top: 5px;
}

.reserveLetters__input {
    display: inline-block;
    position: relative;
    margin: 0 5px;
}

.reserveLetters__input::before,
.reserveLetters__input::after {
    position: absolute;
    content: '';
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 11px);
    height: 1px;
    background-color: #fff;
}

.reserveLetters__input::before {
    top: 0;
}

.reserveLetters__input::after {
    bottom: 0;
}

.portfolioSquareItem {
    position: relative;
    z-index: 3;
    /*background-color: red;*/
    /*border: 3px solid black;*/
    height: 100%;
    padding: 35px 5px 35px 5px;
}
.portfolioItem_li {
    /*background-color: green !important;*/
    padding: 0 !important;
}
.portfolioSquareItem--isReadyToSendToOtherWallets {
    position: absolute;
    right: 5px;
    bottom: 5px;
    width: 100%;
    max-width: 22px;
    padding: 5px;
    background-color: #0162d0;
    border-radius: 50%;
}

.portfolioSquareItem_addbag-img {
    width: 30px;
    height: 30px;
    background-image: url("../../../assets/images/cart/add-to-cart.png");
    position: absolute;
    background-size: 80%;
    /*background-size: 100%;*/
    background-position:2px;
    background-repeat: no-repeat;
    bottom: 10px;
    right: 10px;
    padding: 10px;
    border: 1px solid #0162d0;
    background-color: transparent;
    border-radius: 20px;
    cursor: pointer;
    z-index: 3;
    transition-duration: .4s;
}


.portfolioSquareItem_bin-img {
    width: 30px;
    height: 30px;
    background-image: url("../../../assets/images/rubbish-bin.svg");
    position: absolute;
    background-size: 60%;
    /*background-size: 100%;*/
    background-position:5.5px;
    background-repeat: no-repeat;
    bottom: 10px;
    right: 10px;
    padding: 5px;
    border: 1px solid #0162d0;
    background-color: transparent;
    border-radius: 20px;
    cursor: pointer;
    z-index: 10;
    transition-duration: .4s;
}


.portfolioSquareItem_addbag-img:hover {
    transition-duration: .4s;
    background-color: rgba(238, 236, 234, 0.76) !important;
}

.portfolioSquareItem_bin-img:hover {
    transition-duration: .4s;
    background-color: rgba(238, 236, 234, 0.76) !important;
}

.portfolioSquareItem--isReadyToSendToOtherWallets img {
    max-width: 100%;
    display: block;
}


.creator_label-incorrect {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.creator_label-attach {
    background-color: #FFF2F2;
}

.uploadItems-wrapper {
    display: flex;
    border: 1px solid #e0e6ed;
    border-radius: 4px;
    font-size: 13px;
    margin-bottom: 10px;
}

.uploadInvestItems {
    max-width: 46%;
}

.uploadItems-wrapper label {
    align-items: start;
    margin: 0 auto;
    order: 2;
    display: flex;
    cursor: pointer;
    width: auto;
}

.uploadItems-wrapper img {
    max-width: 70px;
    /*margin-right: 20px;*/
}

.section-input-wrap__names-list {
    font-size: 13px;
}

@media screen and (max-width: 720px) {
    .uploadInvestItems {
        max-width: 100%;
    }

    .uploadAuctionItems img, .uploadInvestItems img {
        margin: 0 auto;
    }
}


.ipfs-input {
    display: block !important;
}

#file {
    /*display: flex !important;*/
}
