/* media queries */

@media screen and (max-width: 1340px) and (min-width: 1100px) {
    .exchangeWrap .exchangeDetails__container .amountofFnb {
        font-size: 25px;
    }
}

@media screen and (max-width: 1100px) {
    .exchangeWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .exchangeWrap .ExchangeLeftSide,
    .exchangeWrap .exchnageDetails {
        width: 100% !important;
        max-width: 700px;
        padding: 20px 20px 0 20px !important;
        margin-top: 0;
    }
}

@media screen and (max-width: 520px) {
    .exchangeWrap .amountToSend .amountSelect {
        padding-left: 0;
        padding-right: 2px;
    }

    .exchangeWrap .exchangeDetails__container .amountofFnb {
        font-size: 20px;
    }
}