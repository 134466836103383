.supportContainer {
	padding: 30px;
	padding-bottom: 50px;
}

.supportTitle {
	text-align: center;
	font-weight: 300;
	text-align: center;
	font-size: 30px;
	letter-spacing: 0.4px;
	padding-top: 30px;
} 

.supportDescription {
	text-align: center;
	color: gray;
	letter-spacing: 0.5px;
}

.supportContainer textarea , .supportSelect {
	width: 100%;
    border: 1px solid rgba(0,0,0,0.09);
    outline: none;
    border-radius: 3px;
    padding: 10px;
    font-size: 15px;
    -webkit-appearance: none;
    letter-spacing: 1px;
    resize: none;
    margin-top: 50px;
}
.supportContainer textarea {
	margin-top: 10px;
}
.supportSelect {
	text-align: center !important;
	 appearance: auto !important;
	 margin-top: 10px;
}
.supportContainer a {
	font-size: 15px;
	padding-top: 15px;
	padding-bottom: 15px;

}

.supportContainer a.disabled {
	background-color: #FF5260;
}
.supportImage {
	text-align: center;
	padding-top: 50px;
}
.supportImage i {
	font-size: 70px;
	color: #1fc481;
}
.successSupportContainer {
	padding-bottom: 100px;
}

.successSupportContainer .supportDescription {
	width: 400px;
	margin: 0 auto;

}



.usernameField {
	color: gray;
	font-size: 11px;
	text-transform: uppercase;
}
.usernameField b {
	color: black;
}

