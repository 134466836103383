.projectReview {
    display: flex;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    flex-direction: column;
}

.creator-main-wrapper {
    padding-top: 50px;
    padding-left: 8%;
    padding-right: 8%;
    min-height: calc(100% - 201px);
}


.section-main {
    margin: 0 auto 40px;
    background: white;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);

}

.collaborator-wrap {
    border-radius: 5px;
    position: relative;
}

.add-social-platform.pull-right {
    background-color: #0162d0;
    margin-top: 20px;
}


.section-title {
    font-size: 30px;
    font-weight: 800;
    padding-bottom: 20px;
    color: rgb(0, 0, 0);
}

.section-sub-title {
    font-size: 11px;
    /*text-transform: uppercase;*/
    /*letter-spacing: 1px;*/
    font-weight: 800;
    color: #8195b0;
    position: relative;
}

.creator-team-collab {
    display: flex;
    margin-top: 20px;
}

.creator-team-collab p {
    color: #1c2b4a;
    /*color: #007bff;*/
    cursor: pointer;
}

.section-left-side, .section-right-side {
    width: 50%;
    height: 100%;
    display: inline-block;
    padding-top: 10px;
    padding-right: 25px;
    vertical-align: top;
}


.section-left-side.no-border {
    border: none;
}

.section-input-wrap input {
    width: 100%;
}


.section-input-wrap input[type='file'] {
    display: none !important;
}

.section-right-side {
    border: none;
    padding-right: 0;
    padding-left: 25px;
}

.section-input-wrap {
    text-align: left;
    margin-bottom: 20px;

}
.custom-fields-container {
    background-color: rgba(248, 248, 248, 0.8);
    padding: 1px 10px 10px 10px;
    border-radius: 5px;
}

/*.s-input-title {*/
/*    margin-left: 10px;*/
/*}*/
.creator-popup-block {
    padding: 50px;
}

.creator-popup-block p {
    margin: 35px 0 20px 0;
}

.s-input-title {
    display: flex;
    flex-wrap: wrap;
    font-size: 11px;
    margin-bottom: 17px;
    color: #1c2b4a;
    font-weight: 800;
    margin-top: 15px;
    /*text-transform: uppercase;*/
    align-items: baseline;
}

.s-input-title label {
    margin-bottom: 0;
}


.character-count {
    color: #292e82;
    text-align: right;
    font-size: 10px;
    vertical-align: middle;
    padding-bottom: 4px;
    font-weight: 800;
    padding-left: 5px !important;
    order: 2;
}

.section-input-wrap input:not([type="radio"]), .section-input-wrap textarea, .section-input-wrap select {
    width: 100%;
    border: 1px solid #e0e6ed;
    font-size: 15px;
    padding: 12px 12px 10px 12px;
    outline: none;
    font-weight: 400;
    color: #000;
    border-radius: 4px;
    transition: 0.2s all;
    display: block;
    background-color: white;
    resize: none;
    -webkit-appearance: none;
}

.section-input-wrap input:focus, .section-input-wrap textarea:focus, .section-input-wrap select:focus {
    border: 1px solid #0362d0;
}

.section-input-wrap.two input {
    width: calc(50% - 25px);
    display: inline-block;
    margin-right: 50px;
    margin-bottom: 10px;
}

.section-input-wrap.two input:last-child {
    margin-right: 0;
}

.gap {
    margin-top: 43px;
}

.align-socialMedia {
    margin-top: 10px;
}

.required-star {
    color: #f03f59;
    text-align: right;
    font-size: 10px;
    vertical-align: middle;
    padding-bottom: 4px;
    margin-left: 5px;
}


.attachment-box, .additional-attach-download {
    width: 128px;
    height: 128px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.01);
    position: relative;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    user-select: none;
}

.additional-attach-download {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 600;
    padding: 0 30px
}


.attachment-box img, .additional-attach-download img {
    width: 25px;
    display: block;
    text-align: center;
    margin: 0 auto;
}

.additional-attach-download img {
    margin-top: 35px;
    width: 26px;

}

.attachment-box .attachment-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: 600;
    margin-top: -5px;
    width: 70%;
    letter-spacing: 0.4px;

}

.attach-max-size {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
    font-size: 8px;
    text-transform: uppercase;
}

.promotional-banners-attach {
    padding-top: 10px;
}


.promotional-banners-attach .attachment-box, .additional-attach-download {
    width: 200px;
}


.amountInput {
    width: 100%;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    outline: none;
    border-radius: 3px;
    padding-left: 14px;
    padding-right: 15px;
    font-size: 20px;
    -webkit-appearance: none;
    letter-spacing: 1px;
    float: left;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}

.amountInput::placeholder {
    color: lightgrey;
}

.amountSelect {
    border: 1px solid rgba(0, 0, 0, 0.09);
    -webkit-appearance: none;
    width: 20%;
    height: 60px;
    float: left;
    text-align: right;
    border-radius: 2px;
    outline: none;
    padding-left: 10px;
    background-color: white;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: gray;
    font-weight: 900;
    padding-right: 30px;
    font-size: 16px;
    letter-spacing: 1px;
}

.amountSelect option {
    -webkit-appearance: none;
    text-align: right;

}

.logo-banner {
    padding: 0;
}

.youtube-thumbnail-wrap, .attached-box-upload {
    width: 200px;
    height: 150px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    display: inline-block;
    position: relative;
}

.attached-box-upload {
    width: 128px;
    height: 128px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
}

.youtube-thumbnail-wrap img, .attached-box-upload img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.delete-attach-box {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 20px;
    height: 20px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
    padding-top: 4px;
}

.delete-attach-box svg {
    width: 12px;
    height: 12px;
    fill: white;
    display: block;
    margin: 0 auto;
}

.licenseSect {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 800;
}

.tag-input-wrap {
    position: relative;
}

.tag-input-wrap input {
    margin-left: 0 !important;
}

.tags-all-dropdown {
    width: 100%;
    background: white;
    max-height: 200px;
    overflow: auto;
    z-index: 5;
    position: absolute;
    border: 1px solid #0362d0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: -2px;
    opacity: 0;
    visibility: hidden;

}

.tags-all-dropdown.visible {
    opacity: 1;
    visibility: visible;
}

.tags-all-dropdown ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.tags-all-dropdown ul li {
    padding: 10px;
    border-bottom: 1px solid #e0e6ed;
    font-size: 13px;
    font-weight: 600;
    transition: 0.3s all;
    cursor: pointer;
}

.tags-all-dropdown ul li:hover {
    background: rgba(224, 230, 237, 0.3);
}

.chosen-tags-wrap {
    user-select: none;
}

.chosen-tags-wrap span {
    padding: 6px 13px;
    background: #0a6ffd;
    margin-right: 5px;
    border-radius: 30px;
    display: inline-block;
    color: white;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.3s all;

}

.chosen-tags-wrap span:hover, .collaborators-all-wrap span:hover {
    background: #f03f59;
    color: white;
}


.s-input-descr {
    color: gray;
    font-size: 12px;
}

.licenses-p {
    font-color: #0162d0;
    text-decoration: #0162d0;
    margin: 0 0 0 4px;
    cursor: pointer;
}

.finalBttn {
    padding: 12px 20px 10px !important;
    border-radius: 30px;
    background: #0162d0;
    font-size: 12px !important;
    line-height: 1;
    white-space: nowrap;
    border: none;
    text-align: center;
    letter-spacing: 0.3px;
    float: left;
    color: #fff !important;
    cursor: pointer;
    z-index: 123123;
}

.licenseSect {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 800;
}

.finalBttn.red {
    background: #f03f59;
}

.saveExitBttn {
    background: white;
    color: black !important;
    border: 1px solid #0162d0;
}

.license {
    float: right;
}

.section-input-wrap .light {
    color: #6c757d;
}

.pdf-title {
    text-align: center;
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.add-social-platform {
    padding: 12px 20px 10px !important;
    border-radius: 3px;
    background: #0162d0;
    font-size: 12px !important;
    line-height: 1;
    white-space: nowrap;
    border: none;
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #fff !important;
    cursor: pointer;
}

.social-tags-wrap span {
    background: #0a6ffd;
    margin-right: 5px;
    border-radius: 3px;
    display: inline-block;
    color: white;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    cursor: pointer;
    width: 140px;
    height: auto;
    transition: 0.3s all;
    text-align: center;
    padding: 20px 10px;
    position: relative;
}

.social-tags-wrap span p {
    font-size: 10px;
    text-transform: none;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.social-tags-wrap span i {
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
}

.add-collab-bttn {
    width: 40px;
    height: 40px;
    background: #e5e6e8;
    border-radius: 30px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    padding-top: 10px;
    margin-bottom: 10px;
    margin-right: 5px;
    margin-top: 5px;
}

.add-collab-bttn svg {
    transform: rotate(-45deg);
}

.add-collab-bttn.close {
    display: none;
}

.collaborators-all-wrap span {
    padding: 6px 13px;
    background: #f3f3f3;
    margin-right: 5px;
    border-radius: 30px;
    display: inline-block;
    vertical-align: middle;
    color: black;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.3s all;
}

.collaborator-wrap .delete-attach-box {
    background: #e5e6e8;
}

.collaborator-wrap .delete-attach-box svg {
    fill: black;
}


.tooltip-global-wrap {
    /*display: inline-block;*/
    /*vertical-align: top;*/
    z-index: 1;
    padding-left: 5px;
    position: relative;
}

.tooltip-global-wrap .tooltip-inner-content {
    /*height: 22px;*/
    display: inline-block;
    z-index: 10;
    cursor: pointer;
    margin-right: 5px;
}

.tooltip-global-wrap .tooltip-inner-content .tooltip-message-right {
    background: #1d2d3d;
    position: absolute;
    bottom: 32px;
    transform: translate(-50%, 0);
    left: 50%;
    border-radius: 4px;
    /*padding: 5px 12px;*/
    width: 200px;
    height: 150px;
    color: white;
    transition: 0.3s all;
    visibility: hidden;
    opacity: 0;
}

.tooltip-global-wrap .tooltip-inner-content .tooltip-message {
    background: #1d2d3d;
    position: absolute;
    bottom: 32px;
    transform: translate(-50%, 0);
    left: 50%;
    border-radius: 4px;
    padding: 5px 12px;
    width: 303px;
    color: white;
    transition: 0.3s all;
    visibility: hidden;
    opacity: 0;
}

.tooltip-global-wrap .tooltip-inner-content .tooltip-message p {
    font-size: 11px;
    user-select: none;
    display: block;
    letter-spacing: 0.5px;
    width: 200px;
    text-transform: none;
    font-weight: 600;
}

.tooltip-global-wrap .tooltip-inner-content .tooltip-message:after, .tooltip-global-wrap .tooltip-inner-content .tooltip-message:before {
    left: 50%;
    top: 99%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    transform: translate(-50%, 0);
    position: absolute;
    pointer-events: none;
}

.tooltip-global-wrap .tooltip-inner-content .tooltip-message:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #1d2d3d;
    border-width: 7px;
    margin-top: 1px;
}

.tooltip-global-wrap .tooltip-inner-content:hover .tooltip-message {
    visibility: visible;
    opacity: 1;
}

.tooltip-global-wrap .tooltip-inner-content.bottom .tooltip-message {
    top: 26px !important;
    bottom: auto !important;
}

.tooltip-global-wrap .tooltip-inner-content.bottom .tooltip-message:after, .tooltip-global-wrap .tooltip-inner-content.bottom .tooltip-message:before {
    left: 50%;
    top: -15px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    transform: translate(-50%, 0);
    position: absolute;
    pointer-events: none;
}

.tooltip-global-wrap .tooltip-inner-content.bottom .tooltip-message:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #1d2d3d;
    border-width: 7px;
    margin-top: 1px;
}


.additionalDataWrapper {
    display: flex;
    flex-wrap: wrap;
}

.additionalData__select {
    width: calc(50% - 25px);
    margin-bottom: 0;
}

.additionalData__select:nth-child(2n) {
    margin-right: 50px;
}

.additionalData__title {
    width: 100%;
}

.unlim-license__block, .limited-license__block {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.unlim-license__block {
    margin-bottom: 0;
}

.unlim-radio__block {
    width: 100%;
}

.unlim-license__block input, .radioBtnForUnlimited input, .limited-license__block input {
    min-width: 20px;
    max-width: 20px;
    margin: 0 10px 0 0;
}

.unlim-license__block input, .limited-license__block input {
    margin-top: 40px;
}


.radioBtnForUnlimited {
    display: flex;
    align-items: center;
}

.radioBtnForUnlimited span {
    color: gray;
    font-size: 12px;
}

.radioBtnForUnlimited a {
    font-size: 12px;
    width: 200px;
    /*background-color: #0162d0;*/
    /*color: #fff;*/
    color: #0162d0;
    /*padding: 3px 11px;*/
    /*border-radius: 20px;*/
    text-decoration: none;
    line-height: normal;
    margin-left: auto;
}

.chooseQuantityOfTotalOwnership {
    width: 50%;
    padding-right: 25px;
}

.attachment-wrapper {
    display: flex;
    flex-flow: row wrap;
}

.openLogoMaker-btn {
    padding: 0 10px;
    border: 1px solid #2875c7;
    border-radius: 3px;
    cursor: pointer;
    color: #FFFFFF;
    background-color: #0162d0;
    font-size: 11px;
    margin: 5px 0 5px;
    max-width: 100px;
}

.btn-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

input.fieldIsNotValid,
select.fieldIsNotValid,
div.fieldIsNotValid {
    border: 1px solid #EDDFDF !important;
    background-color: #FFF2F2 !important;
}

p.fieldIsNotValid {
    border-bottom: 1px solid #b30017;
    padding-bottom: 5px;
}

.financingGoalFieldWrapper {
    position: relative;
}

.financingGoalFieldWrapper input,
.financingGoalFieldWrapper select {
    height: auto;
    font-size: 15px;
    padding: 12px;
    outline: none;
    font-weight: 400;
    color: #000;
    border-radius: 4px;
    transition: 0.2s all;
    display: block;
}

.financingGoalFieldWrapper select {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 61px;
    font-size: 11px;
    height: 48px !important;
    border-left: 1px solid white;
}

.add__block {
    width: 100%;
    padding-right: 0;

}

.row {
    flex-wrap: nowrap;
    margin: 20px 0 0 0 !important;
}


.withArrow {
    background: url('../../../assets/images/dropdownBlack.png') 97% no-repeat;
    background-size: 15px;
    cursor: pointer;
}

.create-wallet__button {
    width: 91px;
    text-decoration: none !important;
    font-size: 11px;
    color: #0162d0 !important;
    border: 1px solid #0162d0;
    border-radius: 3px;
    text-align: center;
    padding: 10px;
    background-color: white;
    margin-left: 10px;
}

.wallet-password__input input {
    margin-left: 0;
}

.terms-accept__block {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.terms-accept__block input {
    margin-right: 10px;
}

.validation-warning {
    color: red;
    font-size: 12px;
}

.wallets-dropdown {
    width: 100%
}

.auctionItem-warning {
    color: red;
    text-transform: uppercase;
}

.warning-message, .download-message {
    color: red;
    font-size: 12px;
    margin-bottom: 10px;
}

.download-message {
    color: black;
}

.save-alert {
    position: absolute;
    right: 140px;
    top: 8px;
    color: green;
    font-size: 14px;
}

.upload_block_wrapper {
    text-align: center;
}

@media screen and (min-width: 1300px) {
    .creator-main-wrapper {
        padding-left: 15%;
        padding-right: 15%;
    }
}

@media screen and (max-width: 900px) {
    .creator-main-wrapper {
        padding-left: 3%;
        padding-right: 3%;
    }
}

@media screen and (max-width: 800px) {
    .section-left-side,
    .section-right-side,
    .additionalData__select {
        width: 100%;
        margin: 0 !important;
        padding: 0 !important;
    }

    .section-input-wrap, .collaborator-wrap {
        display: flex;
        margin: 0;
        flex-direction: column;
        justify-content: center;
    }

    .section-input-wrap.two input {
        width: 100%;
    }

    .section-input-wrap.section-input-wrap {
        padding: 0 !important;
    }

    .section-input-wrap.small-wrap {
        max-width: 100%;
    }

    .license-input {
        margin-bottom: 15px;
    }

    .financingGoalFieldWrapper.small-wrap-financing {
        max-width: 100%;
    }

    .financingGoalFieldWrapper input {
        width: 100%;
    }

    .section-input-wrap.chooseQuantityOfTotalOwnership {
        width: 100%;
    }


    .section-main {
        padding-left: 15px;
        padding-right: 15px;
    }

    .additionalData__select:nth-child(2n) {
        margin-right: 0;
    }

    .unlim-license__block, .limited-license__block {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .unlim-license__block {
        margin-bottom: 0;
    }

    .unlim-radio__block {
        width: 100%;
    }

    .unlim-license__block input, .radioBtnForUnlimited input, .limited-license__block input {
        min-width: 20px;
        max-width: 20px;
        margin: 0 10px 0 0;
    }

    .unlim-license__block input, .limited-license__block input {
        margin-top: 40px;
    }

    input.license-radio-input {
        margin-left: 27px;
    }
}

@media screen and (max-width: 720px) {
    .section-input-wrap label {
        width: 100% !important;
    }

    .section-input-wrap.two input {
        width: 100%;
        margin-bottom: 15px;
    }

    .finalBttn {
        right: 20px;
        left: 20px;
        display: inline-block;
        margin: 0 auto;
        bottom: 10px;
    }

    .social-add-input {
        width: 100% !important;
        margin-right: 0 !important;
    }

    .social-add-input + a {
        display: block !important;
        margin-top: 3px !important;

    }
}

@media screen and (max-width: 640px) {
    .wallet__input-item {
        flex-direction: column;
    }

    .wallets-dropdown, .tag-input-wrap, .tag-input-wrap input {
        width: 100% !important;
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 520px) {
    .wallets-input {
        width: 100% !important;
    }

    .wallets-dropdown {
        left: 0;
    }

}

.submit-container {
    display: flex;
    flex-direction: column !important;
    overflow: auto;
}

.submit_avatar {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    border-radius: 50px;
    margin: 20px auto;
    object-fit: cover;
}

.submit-wrap {
    display: flex !important;
    flex-direction: column !important;
    padding: 20px 40px !important;
    overflow: hidden;
}

.submit-wrap h2 {
    text-align: center;
}

.subnit_flex {
    display: flex;
    width: 100% !important;
    flex-wrap: wrap;
    margin-top: 10px;
    max-height: 400px;
}

.subnit_btns {
    margin: 0 auto;
    display: flex;
    width: 70% !important;
}

.subnit_btns
.popup_tokens-btn {
    border-radius: 30px;
}

.submit_banners {
    width: 30%;
    height: 45%;
    margin: 0 1%;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, .2);
    object-fit: cover;
}

.delete-image-btn {
    margin-left: 10px;
    font-size: 20px;
    margin-top: -4px;
    cursor: pointer;
}


.submit-container__header {
    display: flex;
    align-items: center;
}

.submit-container__general-info {
    display: flex;
    flex-direction: column;
    line-height: 30px;
}

.submit-container__item-wrapper {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.submit-container__item-wrapper__title {
    font-size: 18px;
    font-weight: 600;
    margin-right: 20px;
}

.submit-container__item-wrapper__value {
    font-size: 15px;
}
