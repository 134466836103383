.confirmPrincipalOrCollaboratorPage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
}

.confirmPrincipalOrCollaboratorPage .pageContent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
    flex: 1 0 auto;
}

.confirmPrincipalOrCollaboratorPage .pageContent .image-spinner-frame {
    height: auto;
}

.confirmPrincipalOrCollaboratorPage__confirmInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmPrincipalOrCollaboratorPage__confirmInfo span {
    font-size: 68px;
    color: #0162d0;
}

.confirmPrincipalOrCollaboratorPage .pageContent + div {
    flex: 0 0 auto;
}