.view {
    background-color: rgba(0, 0, 0, 0.85);
  }
  .container {
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-transform: translate3d(0, 0, 0);
    background-color: #2d2d2d;
  }
  .modal-table {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .modal-cell {
    padding: 0;
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
  }
  .modal-content {
    background-color: transparent;
    height: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    margin-left: auto;
    margin-right: auto;
  }
  .modal-close {
    position: absolute !important;
    right: 0px !important;
    top: 0px !important;
    padding: 16px 28px 8px !important;
  }
  .modal-close button {
    padding: 0px;
    margin: 0px;
    cursor: pointer !important;
    background-color: black !important;
    color: buttontext !important;
    display: block !important;
    border-width: 0px !important;
    border-style: initial !important;
    border-color: initial !important;
    border-image: initial !important;
  }
  .slider {
    display: table !important;
    width: 100% !important;
    height: 100% !important;
  }
  .slider .slider-gap {
    display: table-row !important;
  }
  .slider .slider-gap div {
    padding: 66px 15px 20px !important;
  }
  .slider .content {
    display: table-row !important;
    height: 100% !important;
  }
  .slider .content .list {
    position: relative !important;
  }
  .slider .content .list .previous {
    cursor: pointer !important;
    position: absolute !important;
    top: 0px !important;
    height: 100% !important;
    z-index: 3 !important;
    font-size: 7px !important;
    width: 40px !important;
    left: 0px !important;
    background: none !important;
    border-width: 0px !important;
    border-style: initial !important;
    border-color: initial !important;
    border-image: initial !important;
  }
  .slider .content .list .previous:focus {
    outline: none;
  }
  @media (min-width: 1128px) {
    .slider .content .list .previous {
      width: 75px !important;
      font-size: small !important;
    }
  }
  .slider .content .list .next {
    cursor: pointer !important;
    position: absolute !important;
    top: 0px !important;
    height: 100% !important;
    z-index: 3 !important;
    font-size: 7px !important;
    width: 40px !important;
    right: 0px !important;
    background: none !important;
    border-width: 0px !important;
    border-style: initial !important;
    border-color: initial !important;
    border-image: initial !important;
  }
  @media (min-width: 1128px) {
    .slider .content .list .next {
      width: 75px !important;
      font-size: small !important;
    }
  }
  .slider .content .list .next:focus {
    outline: none;
  }
  .slider .content .list .view-image {
    width: 100% !important;
    max-width: 105vh !important;
    margin: 0px auto !important;
  }
  .slider .content .list .view-image .single-image {
    position: relative !important;
    width: 100% !important;
    height: 0px !important;
    padding-bottom: 67% !important;
  }
  .slider .content .list .view-image .single-image .show-image {
    background-size: contain;
    height: 100vh;
    background-repeat: no-repeat;
  }
  .slider .content .list .view-image .single-image .description {
    bottom: 2%;
    position: absolute;
    width: 100%;
    font-size: 12px;
    color: white;
  }
  